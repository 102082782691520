.card-one {
    border: none;
    border-radius: 1.5rem;
    padding: 1.5rem;
    justify-content: space-between;
    align-items: center;
    background-color: $white-smoke;
    cursor: pointer;

    .card-img-top {
        width: auto;
        max-width: 100%;
        height: 12.8rem;
    }

    &:hover {
        background-color: $powder-blue;
        box-shadow: 0px 0px 6px $ocean-blue-color;
    }

    &.active {
        background-color: $powder-blue;
        box-shadow: 0px 0px 6px $ocean-blue-color;
    }

    .card-body {
        padding: 0;

        .card-title {
            @include fonts($body-text-level-one, 2.4rem, $vampire-black, $OpenSans-SemiBold);

            @include screen-sm {
                @include fonts($body-text-level-x-sm-one, null, null, null);
            }

            margin-top: 1.5rem;
            text-align: center;
            margin-bottom: 0;

        }
    }
}