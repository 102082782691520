.choose-party-candidate {
  padding: 0 $padding-xl;
  margin-bottom: 8rem;

  @include screen-lg {
    padding: 0 $padding-md;
  }

  @include screen-sm {
    padding: 0 $padding-xs;
  }

  // @include screen-sm {
  //   padding: 3rem $padding-xs;
  // }

  .first-div {
    padding-top: 3rem;
    padding-bottom: 1rem;
    position: sticky;
    top: 9.1rem;
    z-index: 997;
    background-color: $white-color;

    @include screen-md {
      top: 7.1rem;
    }
    @include screen-sm {
      position: unset;
      padding-top: 1.5rem;
    }

    .hero-h5 {
      display: flex;

      // @include screen-mdx {
      //   .backButton {
      //     margin-top: 0.5rem;
      //   }
      // }
      h5 {
        font-size: $header-level-two;
        font-family: $OpenSans-Bold;
        color: $vampire-black;

        span {
          color: $shuttle-gray;
        }

        @include screen-sm {
          font-size: $header-level-x-sm;
        }
      }

      p {
        font-size: $detail-header-level-zero;
        color: $shuttle-gray;
        display: flex;
        align-items: center;
        margin-top: 1rem;

        @include screen-mdx {
          display: block;
          white-space: wrap;

          .map-icon {
            margin-bottom: 0.5rem;
          }
        }

        @include screen-sm {
          font-size: $detail-header-level-sm-zero;
        }

        .location-i {
          color: $ocean-blue-color;
          font-size: 2rem;
          cursor: pointer;

          @include screen-sm {
            font-size: 1.8rem;
          }
        }
      }
    }

    .solidBlueBtn {
      padding: 1.3rem 2.3rem;
      width: fit-content;
    }
  }
  @include screen-mdx {
    .button-wrapper {
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      gap: 2rem;
    }
  }

  .layOut-one-container {
    margin-top: 3rem;
    border: 1px solid $ocean-blue-color;
    border-radius: 2rem;
    padding: 2rem 2rem 2rem 0rem;

    @include screen-sm {
      margin-top: 2rem;
      padding: 1.5rem 1.5rem 1.5rem 0rem;
    }

    .orange-span {
      background-color: $Carrot-Orange;
      width: 0.9rem;
      height: 5.2rem;
      border-top-right-radius: 0.9rem;
      border-bottom-right-radius: 0.9rem;
    }

    .mcLogo {
      margin-left: 2.5rem;
      width: 3.5rem;
      height: 3.5rem;
      border: 1px solid transparent;

      @include screen-mdx {
        margin-left: 1.5rem;
      }

      .img-fluid {
        display: block;
        border-radius: 50%;
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    h5 {
      @include fonts(
        $detail-header-level-one,
        2.7rem,
        $vampire-black,
        $OpenSans-SemiBold
      );

      @include screen-sm {
        @include fonts($detail-header-level-sm-one, null, null, null);
      }
    }

    .playBtn {
      margin-left: 4.4rem;
      margin-top: 0.5rem;

      @include screen-mdx {
        margin-left: 1.5rem;
      }

      @include fonts(
        $header-level-two,
        3rem,
        $Carrot-Orange,
        $OpenSans-SemiBold
      );

      @include screen-sm {
        @include fonts($header-level-x-sm, null, null, null);
      }
    }
  }

  .arrowBtn {
    margin-left: 1rem;

    @include fonts(
      $detail-header-level-one,
      0.6rem,
      $white-color,
      $OpenSans-Extra-Bold
    );
  }

  .party-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
    }

    img {
      margin-right: 10px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    h5 {
      margin: 0;
      flex-grow: 1;
      text-align: left;
    }

    .blank-button {
      width: 8rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      background-color: $white-smoke;
      border: none;

      &:hover,
      &:active,
      &.btn:first-child:active {
        background-color: $white-smoke;
        border: none;
      }

      &.btn:first-child:active:focus-visible {
        box-shadow: none;
      }

      &.selected {
        background-color: $lush-green;
      }
    }
  }

  .selectThree {
    margin-top: 2rem;

    @include fonts(
      $detail-header-level-one,
      2.7rem,
      $coral-red,
      $OpenSans-Regular
    );

    @include screen-mdx {
      margin-top: 0;
    }
    @include screen-sm {
      @include fonts($detail-header-level-sm-one, null, null, null);
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .blur-background {
    filter: opacity(100%);
    pointer-events: none;
    user-select: none;
  }
}

@include screen-lg {
  .choose-party-parliament {
    padding: 0 $padding-md;
  }
}

@include screen-md {
  .choose-party-parliament {
    padding: 0 $padding-md;
  }
}

@include screen-sm {
  .choose-party-parliament {
    padding: 0 $padding-xs;

    .solidBlueBtn {
      padding: 1rem 1.5rem;
    }

    .border-container {
      padding: 1rem;
    }
  }
  .cDetails {
    padding-left: 2rem;
  }
}
