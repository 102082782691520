.eResults {
  background-color: $white-smoke;

  .mcLogo {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 5.6rem;
    height: 5.6rem;
    border: 1px solid transparent;

    .img-fluid {
      display: block;
      border-radius: 50%;
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .ttlVotesPerc {
    border: 1px solid $green-cyan;
    background-color: $green-cyan;
    color: $white-color;
    border-radius: 5px;
    padding: 1rem 2.5rem;
  }

  .PastPresidentElection2 {
    padding: 0 $padding-xl;
    background-color: $white-color;
    padding-top: 2rem;

    @include screen-md {
      padding: 0 $padding-md;
      padding-top: 1rem;
    }

    @include screen-xs {
      padding: 0 $padding-xs;
      padding-top: 1rem;
    }

    .activeSwitch {
      display: flex !important;
      justify-content: flex-end !important;

      .form-check-input {
        max-width: 4.9rem;
        width: 100%;
        min-height: 2.4rem;

        &:focus {
          box-shadow: unset !important;
          border-color: #0e76a8;
        }

        &:checked {
          background-color: #0e76a8;
          border-color: #0e76a8;
        }
      }

      .form-check-label {
        margin-left: 1.2rem;
        @include fonts($body-text-level-two, 2.7rem, $vampire-black, null);

        @include screen-sm {
          @include fonts($body-text-level-x-sm-two, null, null, null);
        }
      }
    }

    .svsrp-voting-blk {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      gap: 20px;
      width: 100%;

      @include screen-mdx {
        grid-template-columns: auto auto auto;
      }

      @include screen-sm {
        grid-template-columns: auto auto;
      }

      .right-border {
        border-right: 1px solid $whisper;

        &:last-child {
          border-right: none;
        }

        @include screen-mdx {
          &:nth-child(3) {
            border-right: none;
          }
        }

        @include screen-sm {
          &:nth-child(2) {
            border-right: none;
          }

          &:nth-child(3) {
            border-right: 1px solid $whisper;
          }

          &:nth-child(4) {
            border-right: none;
          }
        }
      }

      .sqr-box0 {
        @include square-box($caribbean-green);
        background-color: #ececec;
      }

      .sqr-box1 {
        @include square-box($ocean-blue-color);
        background-color: #ececec;
      }

      .sqr-box2 {
        @include square-box($bright-turquoise);
        background-color: #ececec;
      }

      .sqr-box3 {
        @include square-box($bittersweet);
        background-color: #ececec;
      }

      .sqr-box4 {
        @include square-box($electric-purple);
        background-color: #ececec;
      }

      .registered-e-div {
        .provincial-vote {
          font-size: $detail-header-level-one;
          font-family: $OpenSans-Bold;
          color: $vampire-black;

          @include screen-sm {
            font-size: $body-text-level-x-sm-two;
          }
        }

        .registered-electors {
          font-size: $body-text-level-two;
          color: $shuttle-gray;

          @include screen-sm {
            font-size: $body-text-level-x-sm-two;
          }
        }
      }
    }

    .ppe2-chart-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;

      .ppe2-chartsubBlk {
        border: 1px solid #efefef;
        border-radius: 1.5rem;

        &:first-child {
          width: 50%;
          margin-right: 1.5rem;
        }

        &:last-child {
          width: 50%;
          margin-left: 1.5rem;
        }

      }

      .highChart-img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.5rem;
      }
    }


  }

  .voteChange,
  .voteChange1 {
    color: $white-color;
    border-radius: 5px;
    padding: 1rem 0.5rem;
    width: 50%;

    &:focus-visible {
      outline: none;
    }
  }

  .voteChange {
    border: 1px solid $blue-color;
    background-color: $blue-color;
  }

  .voteChange1 {
    border: 1px solid $bittersweet;
    background-color: $bittersweet;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}