.footer-main {
  background-color: $ocean-blue-color;
  // position: fixed;
  // bottom: 0;
  // width: 100%;
  // z-index: 999;
  // @include screen-xlg {
  //   .footer-main {
  //     transform: translateX(-50%);
  //   }
  // }
  .row1 {
    padding: 25px $padding-xl;

    @include screen-lg {
      padding: 25px $padding-md;
    }
    @include screen-sm {
      padding: 20px $padding-xs;
    }
  }
  .footer-p {
    font-size: $body-text-level-three;
    font-family: $OpenSans-Regular;
    color: $white-color;
    width: 70%;
    @include screen-md {
      width: 80%;
    }
    @include screen-sm {
      width: 100%;
      font-size: $body-text-level-x-sm-three;
    }
  }
  .list-group-item {
    font-size: $detail-header-level-one;
    font-family: $OpenSans-Regular;
    padding: 0;
    padding-bottom: 1rem;
    color: $white-color;
    background-color: transparent;
    border: none;
    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }
  }
  .footer-election {
    font-family: $OpenSans-SemiBold;
  }
  .stay-connect {
    font-size: $detail-header-level-one;
    font-family: $OpenSans-SemiBold;
    color: $white-color;
    margin-bottom: 1.2rem;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }
  }
  .facebook {
    color: $white-color;
    font-size: 2.5rem;
    cursor: pointer;
  }
  .footer-Dis {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 45px;
    font-size: $detail-header-level-one;
    color: $white-color;
    background-color: $dark-blue;
    padding: 20px 0rem 20px $padding-xl;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }

    a {
      font-size: $detail-header-level-one;
      color: $white-color;

      @include screen-sm {
        font-size: $detail-header-level-sm-one;
      }
    }

    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // background-color: $dark-blue;
    // font-size: $detail-header-level-one;
    // color: $white-color;
    // padding: 20px 4rem 20px $padding-xl;

    @include screen-lg {
      padding: 20px $padding-md;
    }
    @include screen-md {
      grid-template-columns: auto auto auto auto;
      gap: 15px;
    }
    @include screen-sm {
      grid-template-columns: auto auto;
      gap: 15px;
      padding: 15px 0rem 15px $padding-xs;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .disclaimer {
    font-size: $body-text-level-two;
    font-family: $OpenSans-SemiBold;
    color: $white-color;

    @include screen-sm {
      font-size: $body-text-level-x-sm-two;
    }
  }
}
