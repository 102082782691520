.manage-presidential-election-main {
    .blueborderbtn {
        padding: 1.3rem 2rem;
    }

    .minputSearch {
        max-width: 33.6rem;
        padding: 1rem;
        font-size: 1.6rem;
        border: 1px solid $Gray44;
        color: $shuttle-gray;
        margin-right: 1rem;

        &:focus {
            box-shadow: none;
        }
    }

    .cselectpageSize {
        font-size: $detail-header-level-one;
        width: fit-content;
        margin-left: 2rem;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }

        .css-13cymwt-control {
            border-color: #707070;
            max-width: 97px;
            border-radius: 5px;

            .css-1dimb5e-singleValue {
                max-width: 97px;
            }
        }

        .css-t3ipsp-control {
            box-shadow: none;
            border-color: #707070;
            max-width: 97px;

            &:hover {
                border-color: #707070;
            }
        }

        .css-1u9des2-indicatorSeparator {
            width: 0;
        }

        .css-1nmdiq5-menu {
            max-width: 97px;
            display: block;
        }
    }
    .cselectpageSize1 {
        font-size: $detail-header-level-one;
        margin-left: 2rem;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }

        .css-13cymwt-control {
            border-color: #707070;
            max-width: 180px;
            border-radius: 5px;

            .css-1dimb5e-singleValue {
                max-width: 180px;
            }
        }

        .css-t3ipsp-control {
            box-shadow: none;
            border-color: #707070;
            max-width: 180px;

            &:hover {
                border-color: #707070;
            }
        }

        .css-1u9des2-indicatorSeparator {
            width: 0;
        }

        .css-1nmdiq5-menu {
            max-width: 180px;
            display: block;
        }
    }
    .cselectpageSize2 {
        font-size: $detail-header-level-one;
        margin-left: 2rem;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }

        .css-13cymwt-control {
            border-color: #707070;
            max-width: 180px;
            border-radius: 5px;

            .css-1dimb5e-singleValue {
                max-width: 180px;
            }
        }

        .css-t3ipsp-control {
            box-shadow: none;
            border-color: #707070;
            max-width: 180px;

            &:hover {
                border-color: #707070;
            }
        }

        .css-1u9des2-indicatorSeparator {
            width: 0;
        }

        .css-1nmdiq5-menu {
            max-width: 180px;
            display: block;
        }
    }

    .sequritiesPartyAction {
        position: relative;

        .dropdown-toggle {
            background-color: $white-color;
            color: $vampire-black;
            border: 1px solid $vampire-black;
            padding: 1.3rem 3rem;

            &::after {
                content: unset;
            }
        }

        .dropdown-menu {
            background-color: $white-color;
            border: 1px solid $vampire-black;
            border-radius: 0.5rem;
            // left: unset !important;
            // right: 0 !important;
            width: 100%;

            .dropdown-item {
                width: 100%;
                text-align: start;
                padding: 0.75rem 1.5rem;
                border-radius: inherit;

                &:hover {
                    background-color: $white-smoke;
                }
            }
        }

        .cselectpageSize {
            font-size: $detail-header-level-one;
            width: fit-content;
            margin-left: 2rem;

            @include screen-sm {
                font-size: $detail-header-level-sm-one;
            }

            .css-13cymwt-control {
                border-color: #707070;
                max-width: 97px;
                border-radius: 5px;

                .css-1dimb5e-singleValue {
                    max-width: 97px;
                }
            }

            .css-t3ipsp-control {
                box-shadow: none;
                border-color: #707070;
                max-width: 97px;

                &:hover {
                    border-color: #707070;
                }
            }

            .css-1u9des2-indicatorSeparator {
                width: 0;
            }

            .css-1nmdiq5-menu {
                max-width: 97px;
                display: block;
            }
        }
    }

    .solidsquarebluebtn {
        padding: 1rem 3.5rem;
    }
}