.manageTable {
  margin-top: 2rem;

  vertical-align: middle;

  @include screen-md {
    width: max-content;
  }
  .notFoundError {
    width: 100%;
    text-align: center;

    .notFoundErrorTxt {
      font-size: $header-level-two;
      color: $ocean-blue-color;
    }
  }

  &:not(caption) {
    * {
      * {
        border-bottom: none;
        text-align: center;
        font-size: $detail-header-level-one;
        background-color: unset;
        color: unset;
        padding: unset;

        @include screen-sm {
          font-size: $detail-header-level-sm-one;
        }
      }
    }
  }

  thead {
    background-color: $white-smoke !important;
    font-family: $OpenSans-Regular;
    color: $shuttle-gray !important;

    td {
      padding: 1rem 0.5rem;
    }
  }

  tbody {
    td {
      padding: 2.7rem 0.5rem;
    }
  }
}
