.aDashbordMain {
    .aDashbordMainSection {
        background-color: $white-color;
        width: 100%;
        // height: 300px;
        padding: 3.2rem $padding-xl;

        @include screen-md {
            padding: 1.2rem $padding-md;
        }

        @include screen-sm {
            padding: 1.2rem $padding-xs;
        }

        &:first-child {
            background-color: $white-smoke;

            .newsblock {


                .announcement {
                    margin-top: 2rem;
                    background-color: $diamond-blue;
                    font-size: $detail-header-level-one;
                    line-height: 2.7rem;
                    color: $ocean-blue-color;
                    padding: 1.1rem 2.2rem;
                    border-radius: 0.5rem;
                    position: relative;

                    @include screen-sm {
                        font-size: $detail-header-level-sm-one;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        background-color: $ocean-blue-color;
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                        width: 0.7rem;
                        height: 100%;
                    }

                    .election-name {
                        font-family: $OpenSans-Bold;
                    }

                    .electionCountry {
                        font-family: $OpenSans-Bold;
                    }

                    .electionStartDate {
                        font-family: $OpenSans-Bold;
                    }

                    .electionEndDate {
                        font-family: $OpenSans-Bold;
                    }
                }
            }
        }

        .headingbar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .headingtxt {
                font-size: $header-level-two;
                color: $vampire-black;
                font-family: $OpenSans-Bold;

                @include screen-sm {
                    font-size: $header-level-x-sm;
                }
            }

            .cselectpageSize {
                font-size: $detail-header-level-one;
                width: fit-content;
                margin-left: 2rem;

                @include screen-sm {
                    font-size: $detail-header-level-sm-one;
                }

                .css-13cymwt-control {
                    border-color: transparent;
                    max-width: 97px;
                    border-radius: 5px;

                    .css-1dimb5e-singleValue {
                        max-width: 97px;
                    }
                }

                .css-t3ipsp-control {
                    box-shadow: none;
                    border-color: transparent;
                    max-width: 97px;

                    &:hover {
                        border-color: #707070;
                    }
                }

                .css-1u9des2-indicatorSeparator {
                    width: 0;
                }

                .css-1nmdiq5-menu {
                    max-width: 97px;
                    display: block;
                }
            }
        }

        .newsviewbtnblock {
            margin-top: 2rem;

            .viewallbtn {
                font-size: $detail-header-level-one;
                text-decoration: underline;
                color: $ocean-blue-color;
                cursor: pointer;
            }
        }

        .totalContainer {
            margin-top: 3rem;

            .totalCol {
                .totalBlks {
                    background-color: $white-color;
                    border-radius: 0.5rem;
                    padding: 1.5rem 1.5rem 4.2rem 1.5rem;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block;
                        background-color: $ocean-blue-color;
                        width: 100%;
                        height: 0.4rem;
                        border-bottom-left-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }

                    .totalBlksHead {
                        font-size: $detail-header-level-one;
                        color: $granite-gray-color;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }

                    .totalBlksPara {
                        font-size: 4.2rem;
                        color: $vampire-black;
                        line-height: 5.7rem;
                        font-family: $OpenSans-Bold;
                        margin-top: 1rem;
                    }
                }

                &:nth-child(even) {
                    .totalBlks {
                        &::after {
                            background-color: $Safety-Orange;
                        }
                    }
                }
            }
        }
    }

    .aDashbordMainSection {
        &:nth-child(2) {
            .NewRegisterRow {
                margin-bottom: 2.3rem;

                .NewRegisterColom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .NewRegisterColomHeading {
                        font-size: $detail-header-level-one;
                        font-family: $OpenSans-Bold;
                    }
                }
            }

            .NewRegisterTableColum {
                box-shadow: 0px 0px 20px #00000029;
                border-radius: 0.5rem;

                .table-responsive {
                    padding: 0rem 4rem 2rem 4rem;
                    width: 100%;
                    overflow-x: auto;

                    .manageTable {
                        margin-top: 1rem;
                        vertical-align: middle;

                        thead {
                            background-color: transparent !important;
                        }

                        tbody {
                            td {
                                padding-top: 0.7rem;
                                padding-bottom: 0.7rem;

                                .check {
                                    width: 1.6rem;
                                    height: 1.6rem;
                                }

                                .cancel {
                                    width: 1.6rem;
                                    height: 1.6rem;
                                }

                                &:nth-child(2) {
                                    font-family: $OpenSans-SemiBold;
                                }
                            }
                        }

                        &:not(caption) {
                            * {
                                * {
                                    font-size: $body-text-level-two;

                                    @include screen-sm {
                                        font-size: $body-text-level-x-sm-two;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            .NewRegisterRow {
                margin-bottom: 2.3rem;

                .NewRegisterColom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .NewRegisterColomHeading {
                        font-size: $detail-header-level-one;
                        font-family: $OpenSans-Bold;
                    }
                }
            }

            .SubmittedVotesTableColum {
                box-shadow: 0px 0px 20px #00000029;
                border-radius: 0.5rem;

                .SubmittedVotesTableHeading {
                    font-size: $detail-header-level-one;
                    font-family: $OpenSans-Bold;
                    line-height: 2.7rem;
                    padding: 2rem;
                    border-bottom: 1px solid $white-smoke;
                }
            }

            .table-responsive {
                padding: 0rem 2rem 2rem 2rem;
                width: 100%;
                overflow-x: auto;

                .manageTable {
                    margin-top: 1rem;
                    vertical-align: middle;

                    thead {
                        background-color: transparent !important;


                    }

                    tbody {
                        td {
                            padding-top: 0.7rem;
                            padding-bottom: 0.7rem;


                            .check {
                                width: 1.6rem;
                                height: 1.6rem;
                            }

                            .cancel {
                                width: 1.6rem;
                                height: 1.6rem;
                            }

                            &:nth-child(2) {
                                font-family: $OpenSans-SemiBold;
                            }
                        }
                    }

                    &:not(caption) {
                        * {
                            * {
                                font-size: $body-text-level-two;

                                @include screen-sm {
                                    font-size: $body-text-level-x-sm-two;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}