.pReports {
  .erCard {
    padding: 0 $padding-xl;
    margin-top: 3rem;
    margin-bottom: 3rem;
    @include screen-md {
      padding: 0 $padding-md;
    }
    @include screen-sm {
      padding: 0 $padding-xs;
    }
  }
  .pReports-header {
    background-color: $white-smoke;
    padding: 3rem $padding-xl;

    @include screen-md {
      padding: 3rem $padding-md;
    }
    @include screen-sm {
      padding: 2rem $padding-xs;
    }
    .section-heading {
      font-size: $header-level-two;
      font-family: $OpenSans-Bold;
      color: $vampire-black;
      @include screen-md {
        padding-bottom: 1rem;
      }
      @include screen-sm {
        font-size: $header-level-x-sm;
      }
    }
    .cselectpageSize {
      font-size: $detail-header-level-one;
      width: 100%;

      @include screen-sm {
        font-size: $detail-header-level-sm-one;
      }

      .css-1fdsijx-ValueContainer {
        // padding: 1.4rem ;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
      }

      .css-13cymwt-control {
        border-color: #707070;

        border-radius: 5px;

        // .css-1dimb5e-singleValue {
        // }
      }

      .css-t3ipsp-control {
        box-shadow: none;
        border-color: #707070;

        &:hover {
          border-color: #707070;
        }
      }

      .css-1u9des2-indicatorSeparator {
        width: 0;
      }

      .css-1nmdiq5-menu {
        width: 400px;
      }
    }
  }
  .notFoundError {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include screen-sm {
      font-size: $header-level-x-sm;
    }

    .notFoundErrorTxt {
      font-size: $header-level-two;
      color: $ocean-blue-color;
      @include screen-sm {
        font-size: $header-level-x-sm;
      }
    }
  }
}
