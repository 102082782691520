.chebx {
    display: flex;
    justify-content: flex-start;
    align-items: end;


    .form-check-input {
        width: 2.4rem;
        height: 2.4rem;
        border: 1px solid $granite-gray-color;
        &:focus{
            box-shadow: unset;
        }
        &:checked {
            background-color: $granite-gray-color;
        }
    }

    .form-check-label {
        display: unset;
        margin-left: 1.3rem;
        font-size: $detail-header-level-one;
        color: $granite-gray-color;
        line-height: 2.4rem;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }
    }
}