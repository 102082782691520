.borderBlueBtn {
    font-size: $body-text-level-two;
    color: $ocean-blue-color;
    border: 1px solid $ocean-blue-color;
    background-color: $white-color;
    line-height: 2.2rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;

    @include screen-sm {
        font-size: $body-text-level-x-sm-two;
    }

    &:hover {
        border: 1px solid #0c5a7d;
        background-color: #0c5a7d;
        color: $white-color;
    }

    &:active {
        border: 1px solid #0c5a7d !important;
        background-color: #0c5a7d !important;
        color: $white-color;
    }
}