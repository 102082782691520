.parties-main {
  .container-parties {
    .row-parties {
      background-color: $alabaster-color;

      &:first-child {
        padding: 3rem $padding-xl;

        @include screen-lg {
          padding: 3rem $padding-md;
        }

        @include screen-md {
          // flex-direction: column;
        }

        @include screen-sm {
          padding: 3rem $padding-xs;
        }

        .col-parties {
          &:first-child {
            .parties-heading {
              @include fonts(
                $display-font-one,
                7.1rem,
                $vampire-black,
                $OpenSans-Extra-Bold
              );

              @include screen-sm {
                @include fonts(
                  $display-font-x-sm,
                  5.6rem,
                  $vampire-black,
                  $OpenSans-Extra-Bold
                );
                margin-bottom: 3.5rem;
              }
            }
          }

          &:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .searchbtnform {
              .minputSearch {
                max-width: 33.6rem !important;
                padding: 1rem;
                font-size: 1.6rem;
                border: 1px solid $Gray44;
                color: $shuttle-gray;
                margin-right: 1rem;

                &:focus {
                  box-shadow: none;
                }
              }

              .solidsquarebluebtn {
                padding: 1rem 3.5rem;
              }
            }
          }
        }
      }
    }
  }

  .cselectpageSize2 {
    font-size: $detail-header-level-one;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }

    .css-13cymwt-control {
      border-color: #707070;

      border-radius: 2.5rem;

    //   .css-1dimb5e-singleValue {
    //   }
    }

    .css-t3ipsp-control {
      box-shadow: none;
      border-color: #707070;
      border-radius: 2.5rem;

      &:hover {
        border-color: #707070;
      }
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }

    .css-1nmdiq5-menu {
      display: block;
    }
  }

  .main-layOut-one .layOut-one-container:last-child {
    margin-top: unset;
  }
}
