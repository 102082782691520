.section-reportsPage {
  padding: 3rem $padding-xl;
  background-color: $white-color;

  @include screen-md {
    padding: 2rem $padding-md;
  }
  @include screen-sm {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @include screen-xs {
    padding: 1rem $padding-xs;
  }

  .section-heading {
    @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);
    text-align: center;
    margin-bottom: 3rem;

    @include screen-sm {
      margin-bottom: 2rem;
      @include fonts($header-level-x-sm, null, null, null);
    }
  }

  .section-block {
    display: flex;
    justify-content: space-between;

    @include screen-md {
      flex-direction: column;
    }

    .section-aside {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include screen-md {
        width: 100%;
        margin-bottom: 1rem;
      }

      .sa-imgblk {
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        background-color: $Old-Lace;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .aside-heading {
        @include fonts(
          $detail-header-level-zero,
          3.3rem,
          $vampire-black,
          $OpenSans-Bold
        );
        text-align: center;
        margin-top: 2rem;

        @include screen-sm {
          @include fonts($detail-header-level-sm-zero, null, null, null);
        }
      }

      .aside-para {
        @include fonts(
          $detail-header-level-one,
          3.4rem,
          $granite-gray-color,
          null
        );
        text-align: center;

        @include screen-sm {
          @include fonts($detail-header-level-sm-zero, null, null, null);
        }
      }
    }
  }
}
