.addPolingDivisions {
    background-color: $white-smoke;

    .addbock {
        padding: 0rem;
    }

    .asl-success {
        font-size: 1.6rem;
    }

    .cselectpageSizeLable {
        font-size: $detail-header-level-one;
        color: $vampire-black;

    }


    .cselectpageSize {
        font-size: $detail-header-level-one;
        width: 100%;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }

        .css-1fdsijx-ValueContainer {
            // padding: 1.4rem ;
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
        }

        .css-13cymwt-control {
            border-color: #707070;

            border-radius: 5px;

            .css-1dimb5e-singleValue {}
        }

        .css-t3ipsp-control {
            box-shadow: none;
            border-color: #707070;


            &:hover {
                border-color: #707070;
            }
        }

        .css-1u9des2-indicatorSeparator {
            width: 0;
        }

        .css-1nmdiq5-menu {
            width: 400px;
        }
    }
}