.datepickercustom {
    width: 100%;
    height: 5.2rem;

    .react-date-picker__wrapper {
        border-radius: 0.5rem;
        border: 1px solid $shuttle-gray;
        // border-radius: 0.5rem;

        .react-date-picker__inputGroup {
            font-size: $body-text-level-one;
            padding: 0 0.5rem;

            .react-date-picker__inputGroup__input {
                outline: none;
                color: $vampire-black;
                &:invalid {
                    background: unset;
                }
            }
        }

        .react-date-picker__clear-button {
            display: none !important;
        }

        .react-date-picker__button {
            display: none !important;
        }

    }

    .react-date-picker__calendar {

        .react-calendar {
            border: 1px solid $shuttle-gray !important;
            border-radius: 0.5rem !important;
        }

        .react-calendar__navigation {
            font-size: $detail-header-level-one;
            font-family: $OpenSans-SemiBold;
        }

        .react-calendar__month-view__weekdays {
            font-size: $body-text-level-three;
            font-family: $OpenSans-SemiBold;

            .react-calendar__month-view__weekdays__weekday {
                abbr[title] {
                    text-decoration: unset;
                }
            }
        }

        .react-calendar__month-view__days {
            font-size: $body-text-level-three;
            font-family: $OpenSans-SemiBold;

            .react-calendar__month-view__weekdays__weekday {
                abbr[title] {
                    text-decoration: unset;
                }
            }

            .react-calendar__tile--now {
                border-radius: 0.5rem;
                background: $shuttle-gray;
                color: white;

                &:enabled {
                    &:focus {}

                }
            }

            .react-calendar__tile--active {
                border-radius: 0.5rem;
                background: $ocean-blue-color;
            }
        }

        .react-calendar__year-view__months {
            font-size: $body-text-level-three;
            font-family: $OpenSans-SemiBold;
        }

        .react-calendar__decade-view {
            font-size: $body-text-level-three;
            font-family: $OpenSans-SemiBold;

            .react-calendar__tile--now {
                border-radius: 0.5rem;
                background: $shuttle-gray;
                color: white;
            }
        }

        .react-calendar__century-view {
            font-size: $body-text-level-three;
            font-family: $OpenSans-SemiBold;
        }


    }

    .react-date-picker__calendar-button__icon {
        stroke: #05050566;

        &:hover {
            stroke: $ocean-blue-color;
        }
    }

    .react-date-picker__clear-button__icon {
        stroke: #05050566;

        &:hover {
            stroke: $ocean-blue-color;
        }
    }
}