.redsolidbtn {
    border: 1px solid $red-color;
    background-color: $red-color;
    font-size: $body-text-level-two;
    padding: 1.1rem 2rem;

    &:hover {
        background-color: $red-color-hover;
        border: 1px solid $red-color-hover;
    }

    &:focus {
        background-color: $red-color-hover;
        border: 1px solid $red-color-hover;
    }

    &:active {
        background-color: $red-color-hover !important;
        border: 1px solid $red-color-hover !important;
    }
}