@import "./fonts";
@import "./utilities/variables";
@import "./utilities/colors";
@import "./utilities/mediaQueries";
@import "./components/newLocationModal";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    // font-size: 62.5%;
    font-size: 50.5%;

    @include screen-xl {
        font-size: 50.5%;
    }

    @include screen-lg {
        font-size: 50.5%;
    }

    @include screen-md {
        font-size: 62.5%;
    }
}

body {
    font-family: $OpenSans-Regular;

    .modal-backdrop {
        background-color: #05050533;
    }
}

p {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

.App {
    max-width: 1920px !important;
    width: 100% !important;
    margin: 0 auto;
}

// CustomTooltip.scss


@import "./utilities/mixins";
@import "./components/components";
@import "./pages/user/user";
@import "./pages/admin/admin";
@import "./pages/pageNotFound";