.election-card {
    border: none;
    padding: 2rem 3.3rem;
    margin-bottom: 3rem;

    .card-body {
        padding: 0;
        padding-left: 2rem;

        .card-title-blk {
            padding-bottom: 2rem;
            border-bottom: 0.1rem solid $white-smoke;

            .card-title {
                @include fonts($sub-header-level-one, 3.8rem, $vampire-black, $OpenSans-SemiBold);

                @include screen-sm {
                    @include fonts($sub-header-level-x-sm-one, null, null, null);
                }
            }

            .card-text {
                @include fonts($detail-header-level-one, 3.2rem, $granite-gray-color, null);

                @include screen-sm {
                    @include fonts($detail-header-level-sm-one, null, null, null);
                }
            }
        }

        .card-election-dates {
            display: flex;
            padding-top: 2rem;
            padding-bottom: 2rem;

            .card-aside {
                width: calc(100% / 3);

                .aside-heading {
                    @include fonts($detail-header-level-one, 3.2rem, $spanish-gray, null);

                    @include screen-sm {
                        @include fonts($detail-header-level-sm-one, null, null, null);
                    }
                }

                .aside-date {
                    display: flex;
                    align-items: center;

                    @include fonts($detail-header-level-two, 3rem, $vampire-black, null);

                    @include screen-sm {
                        @include fonts($detail-header-level-two, null, null, null);
                    }

                    .aside-icon {
                        display: flex;
                        align-items: center;
                        font-size: $body-text-level-one;
                        margin-right: 1rem;
                        color: $Safety-Orange;

                        @include screen-sm {
                            font-size: $body-text-level-x-sm-one;
                        }
                    }
                }
            }
        }

        .card-poll-dates {
            display: flex;
            justify-content: space-between;
            background-color: $alice-blue;
            padding: 2rem;
            border-radius: 1.5rem;

            .card-aside {
                width: calc(100% / 3);

                &:last-child {
                    text-align: end;
                }

                .aside-heading {
                    @include fonts($detail-header-level-one, 3.2rem, $spanish-gray, null);

                    @include screen-sm {
                        @include fonts($detail-header-level-sm-one, null, null, null);
                    }
                }

                .aside-date {
                    @include fonts($detail-header-level-two, 3rem, $vampire-black, null);

                    @include screen-sm {
                        @include fonts($detail-header-level-two, null, null, null);
                    }
                }
            }

            .orangesolidBtn {
                padding: 1.5rem 4.1rem;
            }
        }


    }

}