.orangesolidBtn {
  padding: 1.5rem 5.4rem;
  border-radius: 2.9rem;
  background: linear-gradient(90deg, $Carrot-Orange 0%, $Dark-Tangerine 100%);
  border: unset;
  @include fonts($body-text-level-two, 2.2rem, $white-color, null);

  &:hover {
    background: linear-gradient(
      180deg,
      $Carrot-Orange 0%,
      $Dark-Tangerine 100%
    );
  }
}
