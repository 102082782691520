// .section-landing {
//   &:nth-child(2) {
//     .container-fluid {
//       padding: 4.9rem 3rem;

//       @include screen-lg {
//         padding: 0 2rem;
//       }

//       @include screen-sm {
//         padding: 2.5 1rem;
//       }
//     }

//     .row {
//       .col-md-3 {
//         &:nth-child(2) {
//           @include screen-sm {
//             margin-top: 1rem;
//           }
//         }
//       }
//     }

//     .userBlock {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       min-height: 100%;
//       align-content: stretch;

//       .userAside {
//         &:first-child {
//           display: flex;
//           justify-content: space-between;
//           padding: 1.9rem 2rem;
//           background-color: $Safety-Orange;
//           border-radius: 1rem;

//           .userTxtBlk {
//             .userNum {
//               @include fonts(
//                 $header-level-two,
//                 4.3rem,
//                 $white-color,
//                 $OpenSans-Bold
//               );
//               margin-bottom: 0.2rem;

//               @include screen-sm {
//                 @include fonts($header-level-x-sm, null, null, null);
//               }
//             }

//             .userTxt {
//               @include fonts($body-text-level-two, 2.2rem, $white-color, null);

//               @include screen-sm {
//                 @include fonts($body-text-level-x-sm-two, null, null, null);
//               }
//             }
//           }
//         }

//         &:last-child {
//           margin-top: 3rem;
//           position: relative;

//           @include screen-lg {
//             margin-top: 2rem;
//           }

//           @include screen-sm {
//             margin-top: 1rem;
//           }

//           .userside-img {
//             width: 100%;
//             object-fit: scale-down;
//           }
//         }
//       }
//     }

//     .section-aside {
//       .aside-title {
//         @include fonts(
//           $header-level-two,
//           4.3rem,
//           $vampire-black,
//           $OpenSans-Bold
//         );
//         margin-bottom: 1rem;

//         @include screen-sm {
//           @include fonts($header-level-x-sm, null, null, null);
//         }
//       }

//       .aside-para {
//         @include fonts($body-text-level-two, 2.8rem, $granite-gray-color, null);
//         margin-bottom: 1rem;

//         @include screen-sm {
//           @include fonts($body-text-level-x-sm-two, null, null, null);
//         }
//       }

//       .aside-list {
//         list-style-type: none;
//         margin-top: 3rem;
//         padding-left: 0;

//         .aside-listItems {
//           @include fonts(
//             $body-text-level-two,
//             2.8rem,
//             $granite-gray-color,
//             null
//           );
//           margin-bottom: 2rem;

//           @include screen-sm {
//             @include fonts($body-text-level-x-sm-two, null, null, null);
//           }

//           .listitemsIcons {
//             color: #0e76a8;
//             font-size: 2.2rem;
//             margin-right: 1rem;
//           }
//         }
//       }
//     }
//   }
// }
.section-landing {
  &:nth-child(2) {
    display: flex;
    padding: 4.9rem $padding-xl;

    @include screen-lg {
      padding: 4.9rem $padding-md;
    }

    @include screen-md {
      flex-direction: column;
      padding: 2.9rem $padding-md;
    }

    @include screen-sm {
      padding: 2.5rem 1.5rem;
    }

    .section-aside {
      width: 50%;

      @include screen-md {
        width: 100%;
      }

      &:first-child {
        .aside-lable {
          background-color: #cfe4ee;
          padding: 1.1rem 0.8rem;
          padding-right: 1.5rem;
          border-radius: 0.5rem;
          margin-bottom: 2rem;

          svg {
            width: 3.8rem;
            height: 3.3rem;
            margin-right: 0.8rem;
          }

          @include fonts(
            $detail-header-level-one,
            3.4rem,
            $vampire-black,
            $OpenSans-Bold
          );

          @include screen-sm {
            @include fonts(
              $detail-header-level-sm-one,
              null,
              null,
              $OpenSans-Bold
            );
          }
        }

        .aside-heading {
          @include fonts(
            $header-level-two,
            4.3rem,
            $vampire-black,
            $OpenSans-Bold
          );
          margin-top: 2rem;

          @include screen-lg {
            width: 95%;
          }
          @include screen-md {
            width: 100%;
            margin-bottom: 2rem;
          }
          @include screen-sm {
            @include fonts($header-level-x-sm, null, null, null);
          }
        }

        .float-end {
          @include screen-md {
            display: none;
          }
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .aside-para {
          @include screen-lg {
            width: 95%;
          }
          @include fonts(
            $body-text-level-one,
            3rem,
            $granite-gray-color,
            $OpenSans-SemiBold
          );
          white-space: pre-wrap;
          margin-bottom: 4rem;

          @include screen-sm {
            @include fonts($body-text-level-x-sm-one, null, null, null);
          }
        }

        .orangesolidBtn {
          padding: 1.5rem 3rem;
          margin: 0 auto;
          margin-top: 3.5rem;
          @include screen-lg {
            margin-top: 5.5rem;
          }
          @include screen-mdx {
            margin-top: 2.5rem;
          }
          @include screen-md {
            margin-top: 0rem;
          }
        }
      }
    }
  }
}
