.winner-main {
    padding-top: 3.5rem;

    .winner-container {
        padding: 0 $padding-xl;

        @include screen-lg {
            padding: 0 $padding-md;
        }

        @include screen-sm {
            padding: 0 $padding-xs;
        }

        .winner-row {
            margin-bottom: 3rem;

            &:first-child {
                .winner-col {
                    .winner-heading {
                        @include fonts($header-level-two,
                            4.3rem,
                            $vampire-black,
                            $OpenSans-Bold );

                        @include screen-sm {
                            @include fonts($header-level-x-sm, null, null, null);
                        }

                        span {
                            color: #65676b;
                        }
                    }

                    .winner-location {
                        @include fonts($sub-header-level-two,
                            3.3rem,
                            $granite-gray-color,
                            null);

                        @include screen-sm {
                            @include fonts($sub-header-level-x-sm-two, null, null, null);
                        }

                        .location-i {
                            color: $ocean-blue-color;
                            font-size: 2rem;
                            cursor: pointer;
                        }
                    }
                }
            }

            &:nth-child(2) {
                .winner-col {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    &:first-child {
                        justify-content: flex-start;

                        .winner-heading {
                            @include fonts($header-level-two,
                                4.3rem,
                                $vampire-black,
                                $OpenSans-Bold );

                            @include screen-sm {
                                @include fonts($header-level-x-sm, null, null, null);
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                gap: 5.7rem;

                .winner-col {
                    .winner-card {
                        padding: 2rem;
                        box-shadow: 0px 0px 6px #00000029;
                        border-radius: 1rem;
                        border: unset;
                        flex-direction: row;
                        align-items: center;

                        .winner-blok-des {
                            width: 12rem;
                            height: 12rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .winner-blok-design {
                                width: 9rem;
                                height: 10rem;
                                background-color: #0e76a826;
                                clip-path: polygon(50% 0%,
                                        100% 25%,
                                        100% 75%,
                                        50% 100%,
                                        0% 75%,
                                        0% 25%);
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .winner-imgBlk {
                                    width: 6.8rem;
                                    height: 6.8rem;
                                    border-radius: 50%;

                                    .winner-img {
                                        border-radius: inherit;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }

                        .winner-votes {
                            @include fonts($sub-header-level-three,
                                3.6rem,
                                $vampire-black,
                                $OpenSans-Bold );

                            @include screen-sm {
                                @include fonts($sub-header-level-x-sm-three, null, null, null);
                            }

                            .winner-votesTxt {
                                @include fonts($detail-header-level-one,
                                    2.7rem,
                                    $granite-gray-color,
                                    $OpenSans-Regular );

                                @include screen-sm {
                                    @include fonts($detail-header-level-sm-one, null, null, null);
                                }
                            }
                        }

                        .winner-name {
                            @include fonts($detail-header-level-one,
                                2.7rem,
                                $granite-gray-color,
                                null);

                            @include screen-sm {
                                @include fonts($detail-header-level-sm-one, null, null, null);
                            }

                            .winner-arw {
                                color: #ff8000;
                            }
                        }
                    }

                    &:nth-child(2) {
                        .winner-blok-design {
                            background-color: #f07e0c26 !important;
                        }
                    }

                    &:nth-child(3) {
                        .winner-imgBlk {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .winner-blok-design {
                            background-color: #1e58f833 !important;
                        }

                        .trophy {
                            font-size: 3.2rem;
                            color: #1e58f8;
                        }
                    }
                }
            }
        }

        &:nth-child(2) {
            padding: 0 $padding-xl;

            @include screen-lg {
                padding: 0 $padding-md;
            }

            @include screen-sm {
                padding: 0 $padding-xs;
            }

            .winner-row {

                box-shadow: 0px 0px 10px #0000001a;


            }
        }
    }

    .winner-candiate {
        padding: 0 $padding-xl;

        @include screen-lg {
            padding: 0 $padding-md;
        }

        @include screen-sm {
            padding: 0 $padding-xs;
        }


        .winner-candiate-box {

            box-shadow: 0px 0px 10px #0000001A;

            .provincial-details {
                display: flex;
                padding: 3rem 3rem;

                .sqr-box0 {
                    @include square-box($caribbean-green);
                    background-color: #ececec;
                }

                .sqr-box1 {
                    @include square-box($ocean-blue-color);
                    background-color: #ececec;
                }

                .sqr-box2 {
                    @include square-box($bright-turquoise);
                    background-color: #ececec;
                }

                .sqr-box3 {
                    @include square-box($bittersweet);
                    background-color: #ececec;
                }

                .sqr-box4 {
                    @include square-box($electric-purple);
                    background-color: #ececec;
                }

                .right-border {
                    padding: 0 3rem;
                    border-right: 1px solid $whisper;

                    &:first-child {
                        padding-left: unset;
                    }

                    &:last-child {
                        border-right: unset;
                    }

                    .registered-e-div {
                        .provincial-vote {
                            @include fonts($detail-header-level-one,
                                2.7rem,
                                $vampire-black,
                                $OpenSans-SemiBold );

                            @include screen-sm {
                                @include fonts($detail-header-level-sm-one, null, null, null);
                            }
                        }

                        .registered-electors {
                            @include fonts($body-text-level-two,
                                2.2rem,
                                $granite-gray-color,
                                null);

                            @include screen-sm {
                                @include fonts($body-text-level-x-sm-two, null, null, null);
                            }
                        }
                    }
                }
            }

            .table-responsive {
                .manageTable {
                    thead {
                        background-color: unset !important;
                        border-top: 1px solid #E4E4E4;
                        border-bottom: 1px solid #E4E4E4;

                        tr {
                            td {
                                @include fonts($body-text-level-one,
                                    2.2rem,
                                    $vampire-black,
                                    $OpenSans-SemiBold);

                                @include screen-sm {
                                    @include fonts($body-text-level-x-sm-one, null, null, null);
                                }
                            }
                        }

                    }
                }
            }
        }

    }

    .winner-btn-bx {
        padding: 0 $padding-xl;

        @include screen-lg {
            padding: 0 $padding-md;
        }

        @include screen-sm {
            padding: 0 $padding-xs;
        }

        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 5rem;
        margin-bottom: 7.4rem;

        .borderBlueBtn {
            padding: 1.8rem 2.8rem;
            border-radius: 3.2rem;
            @include fonts($detail-header-level-one,
                2.7rem,
                $ocean-blue-color,
                null);

            @include screen-sm {
                @include fonts($detail-header-level-sm-one, null, null, null);
            }

            &:hover {
                color: $white-color !important;
            }
        }
    }
}