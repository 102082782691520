.htmlEditiorRow {
    .htmlEditiorLable {
        font-size: $detail-header-level-one;
        color: $vampire-black;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }
    }

    .wrapperClassName {
        .rdw-editor-toolbar {
            border: 1px solid $granite-gray-color;
            border-radius: 0.5rem;
        }

        .public-DraftStyleDefault-block {
            margin: .375rem .75rem;
            min-height: 20.5rem;
        }

        .editorClassName {
            border: 1px solid $granite-gray-color;
            border-radius: 0.5rem;
            font-size: $body-text-level-one;
            color: $vampire-black;
        }

        @include screen-sm {
            font-size: $body-text-level-x-sm-one;
        }
    }

    .htmlEditiorCol {
        .tox-tinymce {
            border: 1px solid $granite-gray-color;
            border-radius: 0.5rem;
        }
    }

    .htmlEditiorCol1 {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

}