.about-us-page {
  @import "~slick-carousel/slick/slick.css";
  @import "~slick-carousel/slick/slick-theme.css";

  .section-landing {
    &:last-child {
      background-color: $Old-Lace;

      padding: 4.9rem $padding-xl;

      @include screen-lg {
        padding: 4.9rem $padding-md;
      }
      @include screen-md {
        padding: 2.9rem $padding-md;
      }
      @include screen-sm {
        padding: 2.5rem $padding-xs;
      }

      // .section-heading {
      //   @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);
      //   text-align: center;

      //   @include screen-sm {
      //     @include fonts($header-level-x-sm, null, null, null);
      //   }
      // }

      .ourApprochHead {
        display: flex;

        @include screen-md {
          flex-direction: column;
        }
        .section-aside {
          width: 50%;

          @include screen-md {
            width: 100%;
          }

          &:first-child {
            .aside-lable {
              background-color: #cfe4ee;
              padding: 1.1rem 0.8rem;
              padding-right: 1.5rem;
              border-radius: 0.5rem;
              margin-bottom: 2rem;

              svg {
                width: 3.8rem;
                height: 3.3rem;
                margin-right: 0.8rem;
              }

              @include fonts(
                $detail-header-level-one,
                3.4rem,
                $vampire-black,
                $OpenSans-Bold
              );

              @include screen-sm {
                @include fonts(
                  $detail-header-level-sm-one,
                  null,
                  null,
                  $OpenSans-Bold
                );
              }
            }

            .aside-heading {
              @include fonts(
                $header-level-two,
                4.3rem,
                $vampire-black,
                $OpenSans-Bold
              );
              margin-top: 2rem;

              @include screen-lg {
                width: 95%;
              }
              @include screen-md {
                width: 100%;
                margin-bottom: 2rem;
              }

              @include screen-sm {
                @include fonts($header-level-x-sm, null, null, null);
              }
            }

            .float-end {
              @include screen-md {
                display: none;
              }
            }
          }

          &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            .aside-para {
              @include fonts(
                $body-text-level-one,
                3rem,
                $granite-gray-color,
                $OpenSans-SemiBold
              );
              margin-bottom: 4rem;

              @include screen-lg {
                width: 95%;
                margin-bottom: 1.2rem;
              }

              @include screen-sm {
                @include fonts($body-text-level-x-sm-one, null, null, null);
              }
            }

            .orangesolidBtn {
              padding: 1.5rem 3rem;
              margin: 4rem auto;

              @include screen-mdx {
                margin-top: 1rem;
              }
            }
          }
        }
      }

      .section-container {
        .section-col {
          padding: 2rem;

          .col-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            border: none;
            background-color: white;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            border-radius: 2rem;
            overflow: hidden;
            min-height: 360px;
            max-height: 100%;
            &:hover {
              transform: translateY(-4px);
              box-shadow: 7px 7px 55px #c1c7d0bf;
              // border-radius: 1rem;
            }

            .card-body {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 2rem;

              .date {
                @include fonts(
                  $body-text-level-three,
                  2.7rem,
                  $granite-gray-color,
                  $OpenSans-SemiBold
                );

                @include screen-sm {
                  @include fonts($body-text-level-x-sm-three, null, null, null);
                }
                background-color: $Old-Lace;
                padding: 0.1rem 1rem;
                border-radius: 0.5rem;
              }

              .card-title {
                @include fonts(
                  $detail-header-level-one,
                  3.3rem,
                  $vampire-black,
                  $OpenSans-Bold
                );

                @include screen-sm {
                  @include fonts($detail-header-level-sm-one, null, null, null);
                }
              }

              .card-text {
                @include fonts(
                  $body-text-level-two,
                  2.7rem,
                  $granite-gray-color,
                  null
                );
                margin-bottom: 2rem;
                max-height: 8rem;
                overflow: hidden;

                @include screen-sm {
                  @include fonts($body-text-level-x-sm-two, null, null, null);
                }
              }

              .card-cName {
                @include fonts(
                  $body-text-level-two,
                  2.7rem,
                  $Carrot-Orange,
                  $OpenSans-SemiBold
                );

                @include screen-sm {
                  @include fonts($body-text-level-x-sm-two, null, null, null);
                }
                background-color: $Old-Lace;
                padding: 0.1rem 1rem;
                border-radius: 0.5rem;
              }
            }

            .card-img-top {
              width: 100%;
              height: 200px;
              object-fit: cover;
              border-radius: 2rem;
            }

            .transparentbtn {
              // align-self: flex-start;
              color: $vampire-black;
              // text-decoration: none;

              &:hover {
                background-color: unset;
                text-decoration: underline;
              }
            }
          }
        }
      }
      .slick-dots li button:before {
        color: $Carrot-Orange;
      }
    }
  }
}
