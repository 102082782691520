.emailUpdate {

    .modal-content {
        border-radius: 2.7rem;
        padding: 2.2rem;
        max-width: 45.9rem;
        margin-left: auto;
        margin-right: auto;

        .modal-header {

            border-bottom: unset;

            .ueheading {
                margin-left: auto;
                margin-right: auto;
                @include fonts($body-text-level-one,
                    2.7rem,
                    $black-color,
                    $OpenSans-SemiBold);

                @include screen-sm {
                    @include fonts($body-text-level-x-sm-one, null, null, null);
                }
            }

        }

        .modal-body {
            .form-floating {
                .verifyEmail {
                    position: absolute;
                    top: 30%;
                    right: 2rem;
                    font-size: 2rem;
                    color: $green-cyan;
                }
            }

            .form-floating>label {
                padding: 1rem 1.8rem;
                color: $ocean-blue-color;
                font-size: $body-text-level-two;

                @include screen-sm {
                    font-size: $body-text-level-x-sm-two;
                }
            }

            .form-floating {
                .form-control {
                    padding: 1rem 2rem;
                    padding-top: 0;
                    padding-bottom: 0;
                    box-shadow: none;
                    border-radius: 26px;
                    height: calc(4.5rem + calc(var(--bs-border-width) * 2));
                    min-height: calc(5.2rem + calc(var(--bs-border-width) * 2));

                    display: flex;
                    align-items: center;
                    border-color: #0e76a8;
                    font-size: $body-text-level-two;

                    &:focus {
                        border-color: #0e76a8;
                    }

                    @include screen-sm {
                        font-size: $body-text-level-x-sm-two;
                    }
                }

                .form-control:not(:placeholder-shown)~label,
                >.form-control:focus~label {
                    transform: scale(0.85) translateY(-1.8rem) translateX(1.4rem) !important;
                    color: $ocean-blue-color;
                }
            }


        }

        .modal-footer {

            border-top: unset;
            display: flex;
            flex-direction: column;
            align-items: center;

            .solidBlueBtn {
                padding: 1.2rem 4.8rem;
            }
        }
    }
}