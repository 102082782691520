.Hero-Section {
  padding: 0 $padding-xl;
  margin-top: 3rem;

  @include screen-lg {
    padding: 0 $padding-md;
  }

  @include screen-sm {
    padding: 0 $padding-xs;
  }

  .backG {
    background-color: $ocean-blue-color;
    width: 0.85%;
    border-radius: 8px 0 0 8px;
  }

  .hero-disc {
    font-size: $body-text-level-two;
    color: $vampire-black;
    font-family: $OpenSans-Regular;
    background-color: $diamond-blue;
    padding: 1rem;
    border-radius: 0 8px 8px 0;
    width: 100%;

    span {
      font-family: $OpenSans-SemiBold;
    }
  }

  .hero-h5 {
    h5 {
      font-size: $header-level-two;
      font-family: $OpenSans-Bold;
      color: $vampire-black;

      span {
        color: $shuttle-gray;
      }
    }

    p {
      font-size: $detail-header-level-zero;
      color: $shuttle-gray;
      display: flex;
      align-items: center;
      margin-top: 1rem;

      @include screen-mdx {
        display: block;
        // text-wrap: pretty;
        white-space: wrap;

        .map-icon {
          margin-bottom: 0.5rem;
        }
      }

      .location-i {
        color: $ocean-blue-color;
        font-size: 2rem;
        cursor: pointer;
      }
    }
  }

  .hero-btns {
    // width: 31%;
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .hero-redBtn {
    margin-left: 2rem !important;
  }
}
