.our-commitment {
  display: flex;
  padding: 4.9rem $padding-xl;

  @include screen-lg {
    padding: 4.9rem $padding-md;
  }

  @include screen-md {
    flex-direction: column;
    padding: 2.9rem $padding-md;
  }

  @include screen-sm {
    padding: 2.5rem 1.5rem;
  }

  .section-aside {
    width: 50%;

    @include screen-md {
      width: 100%;
    }

    &:first-child {
      .aside-lable {
        background-color: #cfe4ee;
        padding: 1.1rem 0.8rem;
        padding-right: 1.5rem;
        border-radius: 0.5rem;
        margin-bottom: 2rem;

        svg {
          width: 3.8rem;
          height: 3.3rem;
          margin-right: 0.8rem;
        }

        @include fonts(
          $detail-header-level-one,
          3.4rem,
          $vampire-black,
          $OpenSans-Bold
        );

        @include screen-sm {
          @include fonts(
            $detail-header-level-sm-one,
            null,
            null,
            $OpenSans-Bold
          );
        }
      }

      .aside-heading {
        @include fonts(
          $header-level-two,
          4.3rem,
          $vampire-black,
          $OpenSans-Bold
        );
        margin-top: 2rem;

        @include screen-lg {
          width: 95%;
        }
        @include screen-md {
          width: 100%;
          margin-bottom: 2rem;
        }
        @include screen-sm {
          @include fonts($header-level-x-sm, null, null, null);
        }
      }

      .float-end {
        @include screen-md {
          display: none;
        }
      }
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .aside-para {
        @include screen-lg {
          width: 95%;
        }
        @include fonts(
          $body-text-level-one,
          3rem,
          $granite-gray-color,
          $OpenSans-SemiBold
        );
        white-space: pre-wrap;
        margin-bottom: 4rem;

        @include screen-sm {
          @include fonts($body-text-level-x-sm-one, null, null, null);
        }
      }

      .orangesolidBtn {
        padding: 1.5rem 3rem;
        margin: 0 auto;
        margin-top: 3.5rem;
        @include screen-lg {
          margin-top: 5.5rem;
        }
        @include screen-mdx {
          margin-top: 2.5rem;
        }
        @include screen-md {
          margin-top: 0rem;
        }
      }
    }
  }
}
