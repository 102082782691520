.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  //   .progress-bar {
  //     transform: rotateY(150deg) !important;
  //   }
  .progress-percentage {
    position: absolute;
    top: 3.2rem;
    color: $vampire-black;
    font-family: $OpenSans-SemiBold;
    font-size: $detail-header-level-one;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }
  }
}
