.custom-select-label {
  position: relative;
  display: inline-block;

  .customS-label {
    position: absolute;
    top: -23%;
    left: 8%;
    font-size: $body-text-level-x-sm-three;
    color: $ocean-blue-color;
    background-color: $white-color;
    padding: 3px 5px;
  }

  .custom-select-trigger {
    position: relative;
    display: block;
    min-width: 400px;
    height: 50px;
    padding: 11px 32px 11px 20px;
    font-size: $body-text-level-two;
    color: $vampire-black;
    background: $white-color;
    border-radius: 26px;
    border: 1px solid $ocean-blue-color;
    cursor: pointer;

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      top: 50%;
      right: 20px;
      margin-top: -3px;
      border-bottom: 1px solid $vampire-black;
      border-right: 1px solid $vampire-black;
      transform: rotate(45deg) translateY(-50%);
      transition: all 0.4s ease-in-out;
      transform-origin: 50% 0;
    }

    @include screen-lg {
      padding: 10.5px 28px 10.5px 15px;
    }

    @include screen-mdx {
      padding: 9px 28px 9px 15px;
    }

    @include screen-md {
      padding: 6px 28px 6px 15px;
    }

    @include screen-sm {
      min-width: 450px;
      font-size: $body-text-level-x-sm-three;
      padding: 15px 28px 10px 15px;
      margin-bottom: 2rem;

      &:after {
        width: 6.5px;
        height: 6.5px;
      }
    }

    @include screen-xs {
      min-width: 350px;
    }
  }

  .custom-options {
    position: absolute;
    display: block;
    z-index: 6;
    top: 75%;
    left: 0;
    right: 0;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;

    margin: 15px 0;
    border: 1px solid $white-smoke;
    border-radius: 10px;
    background: $white-gray;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px);

    .custom-option {
      position: relative;
      display: block;
      padding-top: 8px;
      padding-left: 1.5rem;
      padding-bottom: 8px;
      border-radius: 10px;
      font-size: $body-text-level-three;
      color: $granite-gray-color;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      width: 100%;
      text-wrap: wrap;

      &:hover,
      &.selection {
        background: $white-smoke;
        border-radius: 0px;

        &:hover:first-child,
        &.selection:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:hover:last-child,
        &.selection:last-child {
          border-radius: 0px 0px 10px 10px;
        }
      }

      @include screen-sm {
        font-size: $body-text-level-x-sm-three;
      }
    }
  }

  &.opened .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0);
  }
}