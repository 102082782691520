.main-registreAsVoter {
    min-height: 100vh;

    .registreAsVoter-container {
        .registreAsVoter-row {
            padding: 3rem $padding-xl;

            &:first-child {
                @include screen-lg {
                    padding: 3rem $padding-md !important;
                }

                @include screen-md {
                    // flex-direction: column;
                }

                @include screen-sm {
                    padding: 3rem $padding-xs !important;
                }

                .registreAsVoter-col {
                    .registreAsVoter-heading {
                        @include fonts($header-level-two,
                            4.3rem,
                            $vampire-black,
                            $OpenSans-Bold );

                        @include screen-sm {
                            @include fonts($header-level-x-sm, null, null, null);
                        }
                    }

                    .registreAsVoter-location {
                        @include fonts($sub-header-level-two,
                            3.3rem,
                            $granite-gray-color,
                            null);

                        @include screen-sm {
                            @include fonts($sub-header-level-x-sm-two, null, null, null);
                        }

                        .location-i {
                            color: $ocean-blue-color;
                            font-size: 2rem;
                            cursor: pointer;
                        }

                        @keyframes blink {
                            50% {
                                opacity: .8;
                                transform: scale(1.3);
                            }
                        }

                        .blink {
                            position: relative;
                            animation: blink 1.5s infinite;
                        }
                    }

                    &:last-child {
                        display: flex;
                        justify-content: end;
                        align-items: center;
                    }
                }
            }

            &:nth-child(2) {
                padding-top: 2.8rem;
                padding-left: 1.7rem;
                margin: 0rem $padding-xl;
                border-radius: 1.3rem;
                background: transparent linear-gradient(97deg, #f4f9fd 0%, #fdfbf1 100%) 0% 0% no-repeat padding-box;

                .registreAsVoter-col {

                    &:first-child,
                    &:nth-child(2),
                    &:nth-child(3) {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;

                        .image-block {
                            width: 8.2rem;
                            height: 8.2rem;
                            border-radius: 50%;
                            background-color: $white-smoke;
                            border: 1px dashed $light-gray;
                            margin-bottom: 1rem;

                            .badge {
                                width: 5rem;
                                height: 5rem;
                                background-color: $Carrot-Orange ;
                                border: 0.3rem solid $ghost-white;
                                top: 15% !important;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0.9rem;
                                @include fonts($detail-header-level-one,
                                    2.7rem,
                                    $ghost-white,
                                    null);

                                @include screen-sm {
                                    @include fonts($detail-header-level-sm-one, null, null, null);
                                }
                            }

                            .cimage,
                            .imgBlk {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;

                            }
                        }

                        .image-lable {
                            @include fonts($body-text-level-two,
                                3.3rem,
                                $granite-gray-color,
                                null);

                            @include screen-sm {
                                @include fonts($body-text-level-x-sm-two, null, null, null);
                            }
                        }
                    }

                    &:last-child {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .location-message {
                    @include fonts($detail-header-level-zero,
                        3.3rem,
                        $red-color,
                        null);

                    @include screen-sm {
                        @include fonts($detail-header-level-sm-zero, null, null, null);
                    }
                }
            }

            &:last-child {

                .registreAsVoter-col {
                    .table-responsive {
                        border: 1px solid $white-smoke;
                        border-radius: 1rem;
                        overflow-x: unset;
                        // min-height: 35rem;

                        .manageTable {

                            thead {
                                background-color: $white-color !important;
                            }

                            tbody {
                                tr {
                                    td {

                                        &:nth-child(3),
                                        &:first-child {
                                            .regiserCandidate-figur {
                                                width: 5rem;
                                                height: 5rem;
                                                border-radius: 50%;
                                                margin: auto auto;

                                                .regiserCandidate-img {
                                                    width: 100%;
                                                    height: 100%;
                                                    border-radius: inherit;
                                                    object-fit: cover;
                                                }
                                            }

                                            // text-align: center;
                                        }
                                    }
                                }
                            }



                        }
                    }
                }
            }
        }
    }

    .registerVoteAction {
        .btn {
            &:hover {
                color: unset;
            }
        }

        .dropdown-toggle {
            background-color: $white-smoke;
            border: 1px solid $white-smoke;
            padding: 0.9rem 1.8rem;
            border-radius: 2.1rem;

            &::after {
                content: unset;
            }
        }

        .dropdown-menu {
            background-color: $white-color;
            border: 1px solid $vampire-black;
            border-radius: 0.5rem;

            left: 0 !important;
            // right: 0 !important;

            .dropdown-item {
                width: 100%;
                text-align: start;
                padding: 0.75rem 1.5rem;
                border-radius: inherit;

                &:hover {
                    background-color: $white-smoke;
                }
            }
        }
    }

    // .custom-select {
    //     max-width: 21.6rem;
    //     width: 100%;

    //     .custom-select-trigger {
    //         @include fonts($body-text-level-one, 2.4rem, $vampire-black, null);

    //         @include screen-sm {
    //             @include fonts($body-text-level-x-sm-one, null, null, null);
    //         }

    //         background-color: $white-smoke;
    //         width: 100%;
    //         min-width: unset;
    //         height: unset;
    //         border: none;
    //         // padding: 0;
    //     }

    //     .custom-options {
    //         background-color: $white-color;
    //         box-shadow: 0px 3px 6px #00000029;

    //         .custom-option {
    //             padding-left: 1.6rem;
    //             text-align: left;
    //             @include fonts($body-text-level-one, 2.4rem, $vampire-black, null);

    //             @include screen-sm {
    //                 @include fonts($body-text-level-x-sm-one, null, null, null);
    //             }
    //         }
    //     }

    // }

    .cselectpageSize {
        font-size: $detail-header-level-one;
        width: 19.6rem;
        background-color: #EFEFEF;
        border-radius: 2.1rem;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }

        .css-13cymwt-control {
            max-width: 19.6rem;
            border-radius: 5px;
            border: none !important;
            padding: 0.9rem !important;

            .css-1dimb5e-singleValue {
                max-width: 19.6rem;
            }
        }

        .css-t3ipsp-control {
            box-shadow: none;
            border: none;
            max-width: 19.6rem;
            padding: 0.9rem !important;

            &:hover {
                border: none !important;
            }

            &:active {
                border: none;
            }

            &:focus {
                border: none;
            }
        }

        .css-1u9des2-indicatorSeparator {
            width: 0;
        }

        .css-1nmdiq5-menu {
            max-width: 19.6rem;
            display: block;
            background-color: white;


        }
    }
}