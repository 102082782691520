// .tf-Fieldset {
//   border: 1px solid $silver-color;
//   border-radius: 2.9rem;
//   padding: 1rem 1rem 0.5rem 1rem;
//   position: relative;
//   display: block;
//   margin-top: 1.5rem;

//   &:hover,
//   &:active,
//   &:focus,
//   &:focus-visible {
//     border: 1px solid $ocean-blue-color !important;
//   }

//   .tf-Legend {
//     position: absolute;
//     width: fit-content;
//     top: -1rem;
//     left: 2.5rem;
//     background: white;
//     margin-bottom: 0;
//     padding: 0 0.5rem;
//     font-size: 1.4rem;
//     transition: all 0.2s ease;
//     color: $ocean-blue-color;

//     &.filled,
//     .tf-Select:focus + & {
//       top: -0.8rem;
//       left: 1rem;
//       font-size: 1rem;
//       color: $ocean-blue-color;
//     }

//     @include screen-sm {
//       font-size: 1.2rem;
//     }

//     .form-label {
//       margin-bottom: 0;
//     }
//   }

//   .tf-Select {
//     width: 100%;
//     border-radius: 2.9rem;
//     padding: 0.5rem 0.75rem 1.5rem 0.75rem;
//     border: none;
//     font-size: $body-text-level-two;
//     color: $vampire-black;
//     background-color: transparent;

//     @include screen-sm {
//       font-size: $body-text-level-x-sm-two;
//     }

//     &::placeholder {
//       color: $vampire-black;
//     }

//     &:hover,
//     &:focus {
//       box-shadow: none;
//     }

//     &:focus + .tf-Legend,
//     &.filled + .tf-Legend {
//       top: -0.8rem;
//       left: 1rem;
//       font-size: 1rem;
//       color: $ocean-blue-color;
//     }

//     option {
//       text-align: center; 
//     }
//   }
// }

.tf-Fieldset {
  border: 1px solid $silver-color;
  border-radius: 2.9rem;
  padding: 1rem 1rem 0.5rem 1rem;
  position: relative;
  margin-top: 1.5rem;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border: 1px solid $ocean-blue-color !important;
  }

  .tf-Legend {
    position: absolute;
    width: fit-content;
    top: -1rem;
    left: 2.5rem;
    background: white;
    padding: 0 0.5rem;
    font-size: 1.4rem;
    transition: all 0.2s ease;
    color: $ocean-blue-color;

    &.filled,
    .custom-select:focus + & {
      top: -0.8rem;
      left: 1rem;
      font-size: 1rem;
      color: $ocean-blue-color;
    }

    @include screen-sm {
      font-size: 1.2rem;
    }
  }
}

.custom-select-container {
  position: relative;
  width: 100%;

  .custom-select {
    width: 100%;
    padding: 0.75rem;
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;

    .selected-option {
      font-size: $body-text-level-two;
      color: $vampire-black;
      text-align: center;
    }

    .options {
      display: none;
      position: absolute;
      top: 130%;
      left: 0;
      width: 102%;
      border: 1px solid $silver-color;
      border-radius: 5px;
      background-color: white;
      z-index: 1;

      &.open {
        display: block;
      }

      .option {
        padding: 0.75rem;
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: $ocean-blue-color;
          color: white;
        }
      }
    }
  }
}



