.pastpresidentialElection {
  .manageHeadTitle {
    padding: 0 $padding-xl;
    background-color: $white-color;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include screen-md {
      padding: 0 $padding-md;
    }
    @include screen-sm {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    @include screen-xs {
      padding: 1rem $padding-xs;
    }
    h2 {
      font-size: $header-level-two;
      font-family: $OpenSans-Bold;
      color: $vampire-black;
      padding-left: 0.5rem;
      @include screen-sm {
        padding-left: 0;
      }
    }
  }
  .ppe-selects {
    padding: 0 $padding-xl;
    background-color: $white-smoke;

    @include screen-md {
      padding: 0 $padding-md;
    }
    @include screen-xs {
      padding: 0 $padding-xs;
    }
    .elections-row {
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 0.5rem;
      @include screen-sm {
        padding-left: 0;
      }
    }
    .elections-col {
      @include screen-sm {
        padding-left: 0;
      }
      h2 {
        font-size: $detail-header-level-one;
        font-family: $OpenSans-Regular;
        color: $vampire-black;
      }
    }
    .cselectpageSize {
      font-size: $detail-header-level-one;
      width: 100%;

      @include screen-sm {
        font-size: $detail-header-level-sm-one;
      }

      .css-13cymwt-control {
        border-color: #707070;
        max-width: 100%;
        border-radius: 5px;

        .css-1dimb5e-singleValue {
          max-width: 100%;
        }
      }

      .css-t3ipsp-control {
        box-shadow: none;
        border-color: #707070;
        max-width: 100%;

        &:hover {
          border-color: #707070;
        }
      }

      .css-1u9des2-indicatorSeparator {
        width: 0;
      }

      .css-1nmdiq5-menu {
        max-width: 100%;
        display: block;
      }
    }
  }
  .leadingIn {
    font-size: $detail-header-level-one;
    font-family: $OpenSans-SemiBold;
    color: $Carrot-Orange;
  }
  .svsrp-wcv-Blk {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include screen-mdx {
      margin-top: 1.5rem;
    }
    @include screen-sm {
      display: block;
      justify-content: start;
      align-items: center;
    }

    .svsrp-wcv-subBlk {
      display: flex;
      align-items: center;

      &:last-child {
        margin-left: 4rem;
        @include screen-sm {
          margin-left: 0;
          padding-top: 1rem;
        }

        .mpLogo {
          margin-left: auto;
          margin-right: auto;
          margin-top: auto;
          margin-bottom: auto;
          width: 5.6rem;
          height: 5.6rem;
          @include screen-sm {
            margin: 0;
          }

          .mpLogoImg {
            width: inherit;
            height: inherit;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }

      .svsrp-trophy {
        width: 5.9rem;
        height: 5.9rem;
        border-radius: 50%;
        background-color: #1e58f833;
        display: flex;
        justify-content: center;
        align-items: center;

        .trophy {
          font-size: 3.319rem;
          color: #1e58f8;
        }
      }

      .svsrp-details {
        margin-left: 1.5rem;

        .svsrp-deta-head {
          @include fonts(
            $detail-header-level-one,
            2.7rem,
            $vampire-black,
            $OpenSans-SemiBold
          );

          @include screen-sm {
            @include fonts($detail-header-level-sm-one, null, null, null);
          }

          .svsrp-arw {
            color: #ff8000;
            margin-left: 0.8rem;
          }
        }

        .svsrp-deta-votes {
          @include fonts(
            $body-text-level-two,
            2.7rem,
            $granite-gray-color,
            null
          );

          @include screen-sm {
            @include fonts($body-text-level-x-sm-two, null, null, null);
          }
        }
      }
    }
  }
}
