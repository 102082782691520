// ocean blue color Border Start
@mixin ocean-blue-border() {
  border: 1px solid $ocean-blue-color;
  border-radius: 3rem;

  @include screen-sm {
    border-radius: 1rem;
  }
}

// ocean blue color Border End

@mixin auth-heading() {
  font-size: $header-level-two;
  font-family: $OpenSans-SemiBold;
  line-height: 4.3rem;
  color: $black-color;

  @include screen-sm {
    font-size: $header-level-x-sm;
  }
}

@mixin auth-para() {
  font-size: $body-text-level-two;
  line-height: 2.2rem;
  color: $black-color;

  @include screen-sm {
    font-size: $body-text-level-x-sm-two;
  }
}

@mixin shortButton-styles($bg-color, $text-color) {
  background: $bg-color;
  color: $text-color;
  border-radius: 29px;
  border: 1px solid $text-color;
  padding: 1.2rem 4.8rem;
  font-size: $body-text-level-two;
  font-family: $OpenSans-Regular;
  transition: background-color 0.3s ease;
}

@mixin button-styles($bg-color, $text-color) {
  background: $bg-color;
  color: $text-color;
  border-radius: 27px;
  border: none;
  padding: 14px 19px;
  font-size: $body-text-level-two;
  font-family: $OpenSans-Regular;
  transition: background-color 0.3s ease;
}

@mixin square-box($border-color) {
  width: 20px;
  height: 20px;
  background-color: $white-smoke-2;
  border-radius: 4px;
  border: 5px solid $border-color;
  margin-right: 1rem;
  margin-top: 2px;
  @include screen-sm {
    width: 16px;
    height: 16px;
    border: 3.5px solid $border-color;
    margin-top: 3px;
  }
}

// font mixsins start
@mixin fonts($font-size, $line-height, $color, $font-family) {
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
  font-family: $font-family;
}

// font mixsins end

@mixin backgroundVote($bg-color) {
  padding: 7px 10px;
  background-color: $bg-color;
  color: $white-color;
  border-radius: 4px;
  font-size: $body-text-level-two;
  font-family: $OpenSans-Regular;
}
