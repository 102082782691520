.manageBlock-five {
  padding: 2rem 3rem;
  background-color: $white-color;

  @include screen-sm {
    padding: 1rem 1rem;
  }

  .mangeHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid $white-smoke;

    @include screen-sm {
      padding-bottom: 1rem;
      flex-direction: column;
      align-items: flex-start;

      .blueborderbtn {
        margin-top: 1rem;
      }
    }

    .manageHeadTitle {
      font-family: $OpenSans-Bold;
      font-size: $header-level-two;
      flex: 1;

      @include screen-sm {
        font-size: $header-level-x-sm;
      }
    }

    .linkborderbtn {
      margin-left: 1.5rem;
    }
  }

  .mangeHeadss {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid $white-smoke;

    @include screen-sm {
      margin-top: 1rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .mangeSl {
      font-size: $detail-header-level-one;
      color: $shuttle-gray;

      @include screen-sm {
        font-size: $detail-header-level-sm-one;
      }
    }

    .searchbtnform {
      width: 60%;
    }
  }

  .minputSearch {
    max-width: 33.6rem;
    padding: 1rem;
    font-size: 1.6rem;
    border: 1px solid $Gray44;
    color: $shuttle-gray;
    margin-right: 1rem;

    &:focus {
      box-shadow: none;
    }
  }
}
