.choose-party-parliament {
  padding: 0 $padding-xl;
  margin-bottom: 8rem;

  @include screen-lg {
    padding: 0 $padding-md;
  }

  @include screen-sm {
    padding: 0 $padding-xs;
  }

  @include screen-sm {
    padding: 3rem $padding-xs;
  }

  .first-div {
    padding-top: 3rem;
    padding-bottom: 1rem;
    position: sticky;
    top: 9.1rem;
    z-index: 999;
    background-color: $white-color;

    .hero-h5 {
      display: flex;
      h5 {
        font-size: $header-level-two;
        font-family: $OpenSans-Bold;
        color: $vampire-black;

        span {
          color: $shuttle-gray;
        }
      }

      p {
        font-size: $detail-header-level-zero;
        color: $shuttle-gray;
        display: flex;
        align-items: center;
        margin-top: 1rem;

        @include screen-mdx {
          display: block;
          white-space: wrap;

          .map-icon {
            margin-bottom: 0.5rem;
          }
        }

        .location-i {
          color: $ocean-blue-color;
          font-size: 2rem;
          cursor: pointer;
        }
      }
    }

    .solidBlueBtn {
      padding: 1.3rem 2.3rem;
      width: fit-content;
    }
  }
  .layOut-one-container {
    margin-top: 3rem;

    .border-container {
      border: 1px solid $ocean-blue-color;
      border-radius: 2rem;
      padding: 2rem;

      .layOut-one-row {
        display: flex;
        flex-wrap: wrap;
      }

      .layOut-one-col {
        padding: 1.5rem 1rem;
      }
    }
  }

  .arrowBtn {
    margin-left: 1rem;
    @include fonts(
      $detail-header-level-one,
      0.6rem,
      $white-color,
      $OpenSans-Extra-Bold
    );
  }
}

@include screen-lg {
  .choose-party-parliament {
    padding: 0 $padding-md;
  }
}

@include screen-md {
  .choose-party-parliament {
    padding: 0 $padding-md;
  }
}

@include screen-sm {
  .choose-party-parliament {
    padding: 0 $padding-xs;

    .solidBlueBtn {
      padding: 1rem 1.5rem;
    }

    .border-container {
      padding: 1rem;
    }
  }
}
