.registerMain {
    background-color: $white-color;
    padding: 3rem;
    max-width: 425px;
    border-radius: 30px;
    border: 1px solid $ocean-blue-color;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .w-100 {
        margin-bottom: 3.1rem;
    }

    .lLinkBlk {
        font-size: $body-text-level-two;
        color: $shuttle-gray;
        line-height: 22px;
        margin-top: 5.9rem;
    }

    .lLink {
        margin-top: 2.5rem;
    }
}


.register {
    width: 100%;

    .form-floating>label {
        padding: 1rem 1.8rem;
        color: $ocean-blue-color;
        font-size: $body-text-level-two;

        @include screen-sm {
            font-size: $body-text-level-x-sm-two;
        }
    }

    .form-floating {
        .form-control {
            padding: 1rem;
            padding-top: 0;
            padding-bottom: 0;
            box-shadow: none;
            border-radius: 26px;
            height: calc(4.5rem + calc(var(--bs-border-width)* 2));
            min-height: calc(4.5rem + calc(var(--bs-border-width)* 2));
            margin-bottom: 1.5rem;
            display: flex;
            align-items: center;
            border-color: #0e76a8;
            font-size: $body-text-level-two;

            &:focus {
                border-color: #0e76a8;
            }

            @include screen-sm {
                font-size: $body-text-level-x-sm-two;
            }

        }

        .form-control:not(:placeholder-shown)~label,
        >.form-control:focus~label {
            transform: scale(.85) translateY(-1.8rem) translateX(1.4rem) !important;
            color: $ocean-blue-color;
        }
    }

    .form-control.is-invalid::after {
        content: "Invalid input";
        /* Tooltip text */
        position: absolute;
        top: 100%;
        /* Position the tooltip below the input */
        left: 0;
        padding: 0.5em;
        background-color: #dc3545;
        /* Tooltip background color */
        color: white;
        /* Tooltip text color */
        border-radius: 0.25rem;
        z-index: 1;
        /* Ensure tooltip appears above other elements */
        font-size: 0.875rem;
        /* Adjust font size as needed */
        white-space: nowrap;
        /* Prevent tooltip from wrapping */
        display: none;
        /* Initially hide the tooltip */
    }

    .form-control.is-invalid:focus::after {
        display: block;
        /* Show the tooltip when input is focused */
    }
}

.rLinkBlk {
    font-size: $body-text-level-two;
    color: $shuttle-gray;
    line-height: 22px;
    margin-top: 5.9rem;

    @include screen-sm {
        font-size: $body-text-level-x-sm-two;
    }

    .gLink {
        text-decoration: none;
        font-family: $OpenSans-SemiBold;
        color: $black-color;

        font-size: $body-text-level-two;

        @include screen-sm {
            font-size: $body-text-level-x-sm-two;
        }

        &:hover {
            color: $ocean-blue-color;
        }
    }
}

.was-validated .form-control:invalid:focus {
    box-shadow: none;
}

.form-control.is-valid:focus {
    box-shadow: none;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    box-shadow: none;
}