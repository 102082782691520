.aSecurity {
  background-color: $white-smoke;
  .mcLogo {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 5.6rem;
    height: 5.6rem;
    border: 1px solid transparent;

    .img-fluid {
      display: block;
      border-radius: 50%;
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .ttlrcvdVotes {
    border: 1px solid $Gray44;
    border-radius: 3px;
    padding: 1rem 4rem;
  }

  .ttlVotesPerc {
    border: 1px solid $green-cyan;
    background-color: $green-cyan;
    color: $white-color;
    border-radius: 5px;
    padding: 1rem 2.5rem;
  }

  .asl-success {
    font-size: 1.6rem;
  }
  .saveAndcancel {
    padding: 0 $padding-xl;
    padding-top: 5rem;
    padding-bottom: 3.6rem;

    @include screen-md {
      padding: 0 $padding-md;
      padding-top: 4rem;
      padding-bottom: 3rem;
    }
    @include screen-xs {
      padding: 0 $padding-xs;
      padding-top: 3rem;
      padding-bottom: 2rem;
    }
  }
  .PastPresidentElection2 {
    padding: 0 $padding-xl;
    background-color: $white-color;

    @include screen-md {
      padding: 0 $padding-md;
    }
    @include screen-xs {
      padding: 0 $padding-xs;
    }

    .textForm {
      .textformlabel {
        font-size: $detail-header-level-one;
        color: $vampire-black;
      }

      .textformtext {
        font-size: $body-text-level-one;
        color: $vampire-black;
        padding: 1.4rem 1rem;
        border: 1px solid $granite-gray-color;
        border-radius: 5px;
        box-shadow: none;

        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          color: $dark-gray;
        }
      }
    }
  }
  .voteChange,
  .voteChange1 {
    color: $white-color;
    border-radius: 5px;
    padding: 1rem 0.5rem;
    width: 50%;
    &:focus-visible {
      outline: none;
    }
  }
  .voteChange {
    border: 1px solid $blue-color;
    background-color: $blue-color;
  }
  .voteChange1 {
    border: 1px solid $bittersweet;
    background-color: $bittersweet;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
