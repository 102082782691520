.parlament-alliance-main {
    .parlament-alliance-container {
        .parlament-alliance-row {
            padding: 3rem $padding-xl;

            @include screen-lg {
                padding: 3rem $padding-md;
            }

            @include screen-md {
                // flex-direction: column;
            }

            @include screen-sm {
                padding: 3rem $padding-xs;
            }

            .parlament-alliance-col {
                .parlament-alliance-title {
                    @include fonts($sub-header-level-two,
                        2.7rem,
                        $vampire-black,
                        $OpenSans-SemiBold );

                    @include screen-sm {
                        @include fonts($sub-header-level-x-sm-two, null, null, null);
                    }
                }
            }

            &:nth-child(2) {
                background-color: $light-gray1;

                .parlament-alliance-col {
                    .parlament-ex-voting-blk {
                        display: grid;
                        grid-template-columns: auto auto auto auto auto auto auto;
                        gap: 20px;
                        width: 100%;

                        @include screen-mdx {
                            grid-template-columns: auto auto auto;
                        }

                        @include screen-sm {
                            grid-template-columns: auto auto;
                        }

                        .right-border {
                            border-right: 1px solid $whisper;

                            &:last-child {
                                border-right: none;
                            }

                            @include screen-mdx {
                                &:nth-child(3) {
                                    border-right: none;
                                }
                            }

                            @include screen-sm {
                                &:nth-child(2) {
                                    border-right: none;
                                }

                                &:nth-child(3) {
                                    border-right: 1px solid $whisper;
                                }

                                &:nth-child(4) {
                                    border-right: none;
                                }
                            }
                        }

                        .sqr-box0 {
                            @include square-box($caribbean-green);
                            background-color: #ececec;
                        }

                        .sqr-box1 {
                            @include square-box($ocean-blue-color);
                            background-color: #ececec;
                        }

                        .sqr-box2 {
                            @include square-box($bright-turquoise);
                            background-color: #ececec;
                        }

                        .sqr-box3 {
                            @include square-box($bittersweet);
                            background-color: #ececec;
                        }

                        .sqr-box4 {
                            @include square-box($electric-purple);
                            background-color: #ececec;
                        }

                        .sqr-box5 {
                            @include square-box($slate-blue);
                            background-color: #ececec;
                        }

                        .sqr-box6 {
                            @include square-box($medium_pink);
                            background-color: #ececec;
                        }

                        .registered-e-div {
                            .provincial-vote {
                                font-size: $detail-header-level-one;
                                font-family: $OpenSans-Bold;
                                color: $vampire-black;

                                @include screen-sm {
                                    font-size: $body-text-level-x-sm-two;
                                }
                            }

                            .registered-electors {
                                font-size: $body-text-level-two;
                                color: $shuttle-gray;

                                @include screen-sm {
                                    font-size: $body-text-level-x-sm-two;
                                }
                            }
                        }
                    }
                }

            }

            &:nth-child(3) {

                .parliament_alliance {
                    .parl_all_head_blk {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1.5rem;

                        .pahb_heading {
                            @include fonts($header-level-two,
                                4.3rem,
                                $vampire-black,
                                $OpenSans-Bold );

                            @include screen-sm {
                                @include fonts($header-level-x-sm, null, null, null);
                            }
                        }

                        .parl_all_head_sub_blk {}

                        .parlament-ex-voting-blk {
                            display: grid;
                            grid-template-columns: auto auto;
                            gap: 20px;


                            @include screen-mdx {
                                grid-template-columns: auto auto auto;
                            }

                            @include screen-sm {
                                grid-template-columns: auto auto;
                            }

                            .right-border {
                                border-right: 1px solid $whisper;

                                &:last-child {
                                    border-right: none;
                                }

                                @include screen-mdx {
                                    &:nth-child(3) {
                                        border-right: none;
                                    }
                                }

                                @include screen-sm {
                                    &:nth-child(2) {
                                        border-right: none;
                                    }

                                    &:nth-child(3) {
                                        border-right: 1px solid $whisper;
                                    }

                                    &:nth-child(4) {
                                        border-right: none;
                                    }
                                }
                            }

                            .sqr-box0 {
                                @include square-box($caribbean-green);
                                background-color: #ececec;
                                margin-top: 0;
                            }

                            .sqr-box1 {
                                @include square-box($ocean-blue-color);
                                background-color: #ececec;
                                margin-top: 0;
                            }



                            .registered-e-div {
                                display: flex;
                                align-items: center;

                                .provincial-vote {
                                    font-size: $detail-header-level-one;
                                    font-family: $OpenSans-Bold;
                                    color: $vampire-black;

                                    @include screen-sm {
                                        font-size: $body-text-level-x-sm-two;
                                    }
                                }

                                .registered-electors {
                                    font-size: $body-text-level-two;
                                    color: $shuttle-gray;
                                    margin-left: 1.5rem;

                                    @include screen-sm {
                                        font-size: $body-text-level-x-sm-two;
                                    }
                                }
                            }
                        }
                    }

                    .ao-table-blk {
                        border: 1px solid $ocean-blue-color;
                        border-radius: 1rem !important;
                        max-height: 51rem;
                        min-height: 51rem;
                        overflow-y: auto;


                        .ao-table {
                            border-radius: inherit;
                            width: 100%;

                            thead {
                                border-radius: inherit;
                                position: sticky;
                                top: 0;
                                z-index: 2;

                                tr {
                                    background-color: $white-smoke !important;
                                    border-radius: inherit;

                                    td {
                                        padding-top: 1.8rem !important;
                                        padding-bottom: 1.8rem !important;
                                        @include fonts($detail-header-level-one,
                                            3rem,
                                            $vampire-black,
                                            null);

                                        @include screen-sm {
                                            @include fonts($detail-header-level-sm-one,
                                                null,
                                                null,
                                                null);
                                        }

                                        &:first-child {
                                            width: 50%;
                                            border-top-left-radius: inherit;
                                            padding-left: 1.5rem;
                                        }

                                        &:last-child {
                                            border-top-right-radius: inherit;
                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        padding-top: 1.5rem;
                                        padding-bottom: 1.5rem;

                                        .mpLogo {
                                            margin: 0;
                                            margin-right: 0.5rem;
                                            width: 3.8rem;
                                            height: 3.8rem;

                                            @include screen-sm {
                                                margin: 0;
                                            }

                                            .mpLogoImg {
                                                width: inherit;
                                                height: inherit;
                                                object-fit: cover;
                                                border-radius: 50%;
                                            }
                                        }

                                        &:first-child {
                                            padding-left: 1.5rem;
                                        }

                                        @include fonts($detail-header-level-two,
                                            3rem,
                                            $vampire-black,
                                            null);

                                        @include screen-sm {
                                            @include fonts($detail-header-level-sm-two,
                                                null,
                                                null,
                                                null);
                                        }

                                        &:last-child {
                                            .transparentbtn {
                                                &:hover {
                                                    background-color: unset;
                                                    color: $vampire-black;
                                                }

                                                svg {
                                                    @include fonts($sub-header-level-two,
                                                        3rem,
                                                        $vampire-black,
                                                        null);

                                                    @include screen-sm {
                                                        @include fonts($sub-header-level-x-sm-two,
                                                            null,
                                                            null,
                                                            null);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .table-borderless> :not(caption)>*>* {
                            background-color: unset;
                        }
                    }

                    .cursor-pointer {
                        cursor: pointer;
                    }
                }

                .parlament-alliance-col {
                    &:nth-child(2) {
                        .parliament_alliance {
                            .ao-table-blk {}
                        }
                    }
                }
            }

            &:nth-child(4) {
                .parlament-alliance-col {
                    margin-bottom: 3rem;

                    // .combination-table-blk {
                    //     border: 1px solid $ocean-blue-color;
                    //     border-radius: 1rem !important;

                    //     .combination-table {
                    //         border-radius: inherit;

                    //         thead {
                    //             border-radius: inherit;

                    //             tr {
                    //                 background-color: $white-smoke !important;
                    //                 border-radius: inherit;

                    //                 td {
                    //                     padding-top: 1.8rem !important;
                    //                     padding-bottom: 1.8rem !important;
                    //                     @include fonts($detail-header-level-one,
                    //                         3rem,
                    //                         $vampire-black,
                    //                         null);

                    //                     @include screen-sm {
                    //                         @include fonts($detail-header-level-sm-one,
                    //                             null,
                    //                             null,
                    //                             null);
                    //                     }

                    //                     &:first-child {
                    //                         width: 30%;
                    //                         border-top-left-radius: inherit;
                    //                         padding-left: 1.5rem;
                    //                     }

                    //                     &:last-child {
                    //                         border-top-right-radius: inherit;
                    //                     }
                    //                 }
                    //             }
                    //         }

                    //         tbody {
                    //             tr {
                    //                 td {
                    //                     padding-top: 1.5rem;
                    //                     padding-bottom: 1.5rem;

                    //                     .mpLogo {
                    //                         margin: 0;
                    //                         margin-right: 0.5rem;
                    //                         width: 3.8rem;
                    //                         height: 3.8rem;

                    //                         @include screen-sm {
                    //                             margin: 0;
                    //                         }

                    //                         .mpLogoImg {
                    //                             width: inherit;
                    //                             height: inherit;
                    //                             object-fit: cover;
                    //                             border-radius: 50%;
                    //                         }
                    //                     }

                    //                     &:first-child {
                    //                         padding-left: 1.5rem;
                    //                     }

                    //                     @include fonts($detail-header-level-two,
                    //                         3rem,
                    //                         $vampire-black,
                    //                         null);

                    //                     @include screen-sm {
                    //                         @include fonts($detail-header-level-sm-two,
                    //                             null,
                    //                             null,
                    //                             null);
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }

                    //     .table-borderless> :not(caption)>*>* {
                    //         background-color: unset;
                    //     }
                    // }

                    &:last-child {
                        margin-bottom: 0rem;
                    }
                }
            }
        }
    }
}