.plusbtn {
    background-color: transparent;
    border: 1px solid $black-color;
    color: $black-color;
    font-size: $body-text-level-one;

    @include screen-sm {
        font-size: $body-text-level-x-sm-one;
    }

    &:hover {
        background-color: $black-color;
        color: $white-color;
        border: 1px solid $black-color;
    }

    &:active {
        background-color: $black-color !important;
        color: $white-color !important;
        border: 1px solid $black-color !important;
    }


}