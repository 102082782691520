.section-landing {
  &:nth-child(9) {
    padding: 4.9rem $padding-xl;

    @include screen-lg {
      padding: 4.9rem $padding-md;
    }

    @include screen-md {
      padding: 2.9rem $padding-md;
    }

    @include screen-sm {
      padding: 2.5rem 1.5rem;
    }

    .ourApprochHead {
      display: flex;

      @include screen-md {
        flex-direction: column;
      }

      .section-aside {
        width: 50%;

        @include screen-md {
          width: 100%;
        }

        &:first-child {
          .aside-lable {
            background-color: #cfe4ee;
            padding: 1.1rem 0.8rem;
            padding-right: 1.5rem;
            border-radius: 0.5rem;
            margin-bottom: 2rem;

            svg {
              width: 3.8rem;
              height: 3.3rem;
              margin-right: 0.8rem;
            }

            @include fonts(
              $detail-header-level-one,
              3.4rem,
              $vampire-black,
              $OpenSans-Bold
            );

            @include screen-sm {
              @include fonts(
                $detail-header-level-sm-one,
                null,
                null,
                $OpenSans-Bold
              );
            }
          }

          .aside-heading {
            @include fonts(
              $header-level-two,
              4.3rem,
              $vampire-black,
              $OpenSans-Bold
            );
            margin-top: 2rem;
            @include screen-lg {
              width: 95%;
            }
            @include screen-md {
              width: 100%;
              margin-bottom: 2rem;
            }
            @include screen-sm {
              @include fonts($header-level-x-sm, null, null, null);
            }
          }

          .float-end {
            @include screen-md {
              display: none;
            }
          }
        }

        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .aside-para {
            @include fonts(
              $body-text-level-one,
              3rem,
              $granite-gray-color,
              $OpenSans-SemiBold
            );
            margin-bottom: 4rem;

            @include screen-lg {
              width: 95%;
              margin-bottom: 1.5rem;
            }

            @include screen-sm {
              @include fonts($body-text-level-x-sm-one, null, null, null);
            }
          }

          .orangesolidBtn {
            padding: 1.5rem 3rem;
            margin: 4rem auto;

            @include screen-mdx {
              margin-top: 1rem;
            }
          }
        }
      }
    }

    // .section-heading {
    //     @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);
    //     text-align: center;

    //     @include screen-sm {
    //         @include fonts($header-level-x-sm, null, null, null);
    //     }
    // }

    // .section-para {
    //     width: 65%;
    //     @include fonts($detail-header-level-one, 2.8rem, $granite-gray-color, null);
    //     margin: 2rem auto;
    //     text-align: center;

    //     @include screen-sm {
    //         @include fonts($detail-header-level-sm-one, null, null, null);
    //         width: 100%;
    //     }
    // }

    // .sectionblk {
    //   margin-top: 10rem;
    //   display: flex;
    //   justify-content: space-between;

    //   @include screen-sm {
    //     flex-direction: column;
    //     // width: 100%;
    //   }

    //   .blk-aside {
    //     width: calc(100% / 3);
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;

    //     @include screen-sm {
    //       // flex-direction: column;
    //       width: 100%;
    //     }

    //     .ba-imgblk {
    //       width: 7.2rem;
    //       height: 7.2rem;
    //       border-radius: 50%;
    //       background-color: $Old-Lace;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       margin-bottom: 2rem;

    //       .ba-icon {
    //         width: 3.2rem;
    //         height: 3.2rem;
    //       }
    //     }

    //     .ba-heading {
    //       @include fonts(
    //         $detail-header-level-one,
    //         2.7rem,
    //         $vampire-black,
    //         $OpenSans-Bold
    //       );
    //       // text-align: center;
    //       // margin-top: 5rem;

    //       @include screen-sm {
    //         // margin-top: 2.5rem;
    //         @include fonts($detail-header-level-sm-one, null, null, null);
    //       }
    //     }

    //     .ba-para {
    //       width: 70%;
    //       @include fonts(
    //         $detail-header-level-one,
    //         3.4rem,
    //         $granite-gray-color,
    //         null
    //       );
    //       margin-top: 2rem;
    //       text-align: center;

    //       @include screen-sm {
    //         @include fonts($detail-header-level-sm-one, null, null, null);
    //         width: 100%;
    //       }
    //     }
    //   }
    // }

    .section-simulator {
      display: grid;
      grid-template-columns: auto auto;
      gap: 2rem;
      //   padding: 4.9rem $padding-xl;

      //   @include screen-lg {
      //     padding: 4.9rem $padding-md;
      //   }

      @include screen-md {
        grid-template-columns: auto;
      }

      //   @include screen-sm {
      //     padding: 2.5rem 1.5rem;
      //   }

      .section-vmBlk {
        //   width: 50%;
        background-color: $white-smoke;
        padding: 2.3rem 3rem;
        border-radius: 1rem;

        @include screen-md {
          // width: 100%;
          padding: 1.15rem 1.5rem;
        }

        .vmblk-heading {
          position: relative;
          @include fonts(
            $header-level-two,
            4.3rem,
            $vampire-black,
            $OpenSans-Bold
          );
          //   padding-bottom: 1rem;
          //   margin-bottom: 2rem;

          @include screen-sm {
            @include fonts($header-level-x-sm, null, null, null);
          }

          //   &::after {
          //     content: "";
          //     position: absolute;
          //     left: 1.2rem;
          //     bottom: 0;
          //     width: 4rem;
          //     height: 0.2rem;
          //     background-color: $Safety-Orange;
          //     border: 0.2rem solid $Safety-Orange;
          //   }
        }

        .vmblk-para {
          @include fonts(
            $body-text-level-one,
            3rem,
            $granite-gray-color,
            $OpenSans-SemiBold
          );
          margin-bottom: 0.2rem;

          @include screen-sm {
            @include fonts($body-text-level-x-sm-one, null, null, null);
          }
        }

        &:first-child {
          // margin-right: 2rem;

          @include screen-md {
            margin-right: 0rem;
            margin-bottom: 1rem;
          }
        }

        &:last-child {
          // margin-left: 2rem;

          @include screen-md {
            margin-left: 0rem;
          }
        }

        &:hover {
          background-color: $ocean-blue-color;

          .vmblk-heading {
            color: $white-color;

            &::after {
              background-color: $white-color;
              border: 0.2rem solid $white-color;
            }
          }

          .vmblk-para {
            color: $white-color;
          }
        }
      }
    }
  }
}
