.aboutus-herosection {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;

  @include screen-mdx {
    height: 30vh;
  }
  @include screen-mdx {
    height: 35vh;
  }

  .section-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  h5 {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
    color: $ghost-white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: $header-level-three;
    font-family: $OpenSans-SemiBold;

    @include screen-sm {
      font-size: $header-level-x-sm;
    }
  }
}
