.orangeborderBtn {
    padding: 1.5rem 2.9rem;
    border-radius: 2.9rem;
    border: 1px solid $Carrot-Orange;
    @include fonts($body-text-level-two, 2.2rem, $white-color , null);
    background-color: unset;
    color: $Carrot-Orange;

    &:hover {
        background: linear-gradient(180deg, $Carrot-Orange 0%, $Dark-Tangerine 100%);
        color: $white-color;
        border: 1px solid $Carrot-Orange;
    }

    &:active {
        background: linear-gradient(180deg, $Carrot-Orange 0%, $Dark-Tangerine 100%);
        color: $white-color;
        border: 1px solid $Carrot-Orange !important;
    }
}