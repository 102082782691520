.cmultiselect {
    li {
        &:hover {
            background: $white-smoke !important;
            color: $vampire-black !important;
        }
    }

    .searchWrapper {
        .chip {
            background-color: $granite-gray-color;
        }

        .searchBox {
            font-size: $body-text-level-one;
            color: #05050566;

            @include screen-sm {
                font-size: $body-text-level-x-sm-one;
            }
        }
    }

    .optionListContainer {
        // display: block;

        .optionContainer {
            .highlightOption {
                background: unset;
                color: $vampire-black;
            }

            .option {
                font-size: $body-text-level-three;

                @include screen-sm {
                    font-size: $body-text-level-x-sm-three;
                }

                .checkbox {
                    &:checked {
                        background-color: $granite-gray-color;
                    }
                }
            }
        }
    }
}