// Color name start
$white-color: #ffffff;
$ghost-white: #f6fafb;
$black-color: hwb(0 0% 100%);
$alabaster-color: #fafafa;
$ocean-blue-color: #0e76a8;
$blue-color: #0c7ef0;
$dark-blue: #046797;
$powder-blue: #c9dee9;
$denim-color: #1976d2;
$diamond-blue: #cfe4ee;
$granite-gray-color: #65676b;
$black-color: #000000;
$vampire-black: #050505;
$jet-black: #050606;
$shuttle-gray: #65676b;
$spanish-gray: #9a9a9a;
$light-gray: #c3c3c3;
$light-gray1: #ececec;
$white-smoke: #efefef;
$white-smoke-2: #f9f9f9;
$white-gray: #fbfbfb;
$dark-gray: #a6a6a6;
$light-sky-blue: #84d3eb;
$alice-blue: #f4f9fd;
$Apricot-color: #fcd1a5;
$Carrot-Orange: #f88d1e;
$Safety-Orange: #f07e0c;
$Old-Lace: #fef2e7;
$Dark-Tangerine: #f8af1e;
$red-color: #ff0000;
$red-color-hover: #cc0000;
$coral-red: #ff6d60;
$light-orange: #fbd8b6;
$Gray44: #707070;
$green-cyan: #00ca90;
$diamond-blue: #cfe4ee;
$caribbean-green: #00ca90;
$lush-green: #29a80e;
$whisper: #e4e4e4;
$bright-turquoise: #1edbf8;
$bittersweet: #ff6d60;
$electric-purple: #cc1ef8;
$snow-color: #fcfcfc;
$silver-color: #c4c4c4;
$onahau-color: #b5e7ff;
$chinese-green: #d4edda;
$green-tea: #c3e6cb;
$slate-blue: #706fff;
$medium_pink : #ff6fce;
// Color name end