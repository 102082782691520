.linkblackbtn {
  font-size: $body-text-level-two;
  color: $white-color;
  border: 1px solid $black-color;
  background-color: $black-color;
  line-height: 2.2rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
  text-decoration: none;

  @include screen-sm {
    font-size: $body-text-level-x-sm-two;
  }

  &:hover {
    border: 1px solid $black-color;
    background-color: $white-color;
    color: $black-color;
  }

  &:active {
    border: 1px solid $black-color !important;
    background-color: $white-color !important;
    color: $black-color;
  }
}
