.LeftDropDOwm {
    .dropdown-toggle {
        &::before {
            content: unset;

        }
    }

    .btn {
        font-size: 1.8rem;
        color: $Gray44;
        background-color: unset;
        width: 3.4rem;
        height: 3.4rem;
        padding: 0rem 0.5rem 0.5rem 0.5rem;
        border-radius: 50%;
        border: 1px solid $white-smoke;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }

        &:first-child {
            &:active {
                background-color: $white-smoke;
                color: $Gray44;
                border: 1px solid $white-smoke;
            }
        }
    }
}