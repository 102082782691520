.card-four {
    border: none;
    
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .cfour-imgblk {
        max-width: 100%;
        height: 37.6rem;

        .cfour-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-title {
        text-align: center;
        padding-top: 1.1rem;
        padding-bottom: 1.1rem;
        background-color: $white-smoke;
        margin-bottom: 0;
        @include fonts($body-text-level-two,
            2.2rem,
            $ocean-blue-color,
            $OpenSans-SemiBold );

        @include screen-sm {
            @include fonts($body-text-level-x-sm-two, null, null, null);
        }
    }

    .card-body {
        background-color: $diamond-blue;

        &:last-child {
            padding: 1rem 1rem;

            .table {
                margin-bottom: unset;
                border: unset;

                tr {


                    th {
                        width: 30%;
                        @include fonts($body-text-level-two,
                            2.2rem,
                            $vampire-black,
                            $OpenSans-SemiBold );

                        @include screen-sm {
                            @include fonts($body-text-level-x-sm-two, null, null, null);
                        }

                    }

                    td {
                        width: 70%;
                        @include fonts($body-text-level-two,
                            2.2rem,
                            $vampire-black,
                            null);

                        @include screen-sm {
                            @include fonts($body-text-level-x-sm-two, null, null, null);
                        }


                    }
                }


                >:not(caption) {
                    >* {
                        >* {
                            padding: 1rem 0rem;
                            background-color: unset;
                            border-bottom-width: 0;
                        }
                    }
                }
            }

        }
    }
}