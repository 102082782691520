.main-layOut-three {
    .layOut-three-container {
        padding: 3rem $padding-xl;

        @include screen-lg {
            padding: 3rem $padding-md;
        }

        @include screen-md {
            // flex-direction: column;
        }

        @include screen-sm {
            padding: 3rem $padding-xs;
        }

        .layOut-three-row {
            .layOut-three-col {}

            &:nth-child(2) {
                margin-top: 3rem;
            }
        }
    }

}