.section-landing {
  &:nth-child(8) {
    // margin-top: 4.9rem;
    // padding: 4.9rem 0;
    padding-top: 5rem;
    // position: relative;

    @include screen-lg {
      padding: 4.9rem 0;
    }

    @include screen-md {
      padding: 2.9rem 0;
    }

    @include screen-sm {
      padding: 2.5rem 0;
      padding-top: 0rem;
    }

    .section-aside {
      .eduvoter-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #f88d1e 0%, #f8af1e 100%);
        padding-top: 2rem;
        padding-bottom: 7rem;

        @include screen-md {
          padding-bottom: 5rem;
        }

        @include screen-sm {
          padding-top: 0;
        }

        // .eduvoterimgblk {
        //   position: absolute;
        //   top: 0;
        //   left: 50%;
        //   transform: translateX(-50%);

        //   @include screen-sm {
        //     position: unset;
        //     top: unset;
        //     left: unset;
        //     transform: unset;
        //   }

        //   .userside-img {
        //     max-width: 100%;
        //   }
        // }

        .eduvoterwavyline {
          margin-top: 2rem;
          @include screen-md {
            margin-top: 1.5rem;
          }
        }

        .eduvotertitle {
          @include fonts(
            $header-level-two,
            4.3rem,
            $white-color,
            $OpenSans-Bold
          );
          text-align: center;
          margin-top: 5rem;

          @include screen-md {
            margin-top: 2.5rem;
          }

          @include screen-sm {
            @include fonts($header-level-x-sm, null, null, null);
          }
        }

        .eduvoterpara {
          width: 55%;
          margin-top: 5rem;

          @include fonts(
            $detail-header-level-one,
            2.8rem,
            $white-color,
            $OpenSans-SemiBold
          );
          margin-bottom: 1rem;

          @include screen-md {
            width: 75%;
            margin-top: 3rem;
          }

          @include screen-sm {
            width: 90%;
            @include fonts($detail-header-level-sm-one, null, null, null);
          }

          text-align: center;
        }

        .whiteBorderBtn {
          margin-top: 5rem;
          @include screen-lg {
            width: 25% !important;
          }
          @include screen-md {
            margin-top: 3rem;
          }
          @include screen-sm {
            width: 50% !important;
          }
        }
      }
    }
  }
  // &:nth-child(8) {
  //   display: flex;
  //   padding: 4.9rem $padding-xl;

  //   @include screen-lg {
  //     padding: 4.9rem $padding-md;
  //   }

  //   @include screen-md {
  //     flex-direction: column;
  //   }

  //   @include screen-sm {
  //     padding: 2.5rem 1.5rem;
  //   }

  //   .section-aside {
  //     width: 50%;

  //     @include screen-sm {
  //       width: 100%;
  //     }

  //     &:first-child {
  //       .aside-lable {
  //         background-color: #cfe4ee;
  //         padding: 1.1rem 0.8rem;
  //         padding-right: 1.5rem;
  //         border-radius: 0.5rem;
  //         margin-bottom: 2rem;

  //         svg {
  //           width: 3.8rem;
  //           height: 3.3rem;
  //           margin-right: 0.8rem;
  //         }

  //         @include fonts(
  //           $detail-header-level-one,
  //           3.4rem,
  //           $vampire-black,
  //           $OpenSans-Bold
  //         );

  //         @include screen-sm {
  //           @include fonts(
  //             $detail-header-level-sm-one,
  //             null,
  //             null,
  //             $OpenSans-Bold
  //           );
  //         }
  //       }

  //       .aside-heading {
  //         @include fonts(
  //           $header-level-two,
  //           4.3rem,
  //           $vampire-black,
  //           $OpenSans-Bold
  //         );
  //         width: 90%;
  //         margin-top: 2rem;

  //         @include screen-sm {
  //           @include fonts($header-level-x-sm, null, null, null);
  //         }
  //       }

  //       .float-end {
  //         margin-top: 1.1rem;
  //         @include screen-sm {
  //           display: none;
  //         }
  //       }
  //     }

  //     &:last-child {
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: space-between;
  //       align-items: flex-start;

  //       .aside-para {
  //         @include fonts(
  //           $body-text-level-one,
  //           3rem,
  //           $granite-gray-color,
  //           $OpenSans-SemiBold
  //         );
  //         margin-bottom: 4rem;

  //         @include screen-sm {
  //           @include fonts($body-text-level-x-sm-one, null, null, null);
  //         }
  //       }

  //       .orangesolidBtn {
  //         padding: 1.5rem 3rem;
  //         margin: 0 auto;
  //       }
  //     }
  //   }
  // }
}
