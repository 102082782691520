.main-election {
    .elections-container {
        .elections-row {
            background-color: $alabaster-color;

            &:first-child {

                padding: 3rem $padding-xl;

                @include screen-lg {
                    padding: 3rem $padding-md;
                }

                @include screen-md {
                    // flex-direction: column;
                }

                @include screen-sm {
                    padding: 3rem $padding-xs;
                }

                .elections-col {
                    &:first-child {
                        .elections-heading {
                            @include fonts($display-font-one, 7.1rem, $vampire-black, $OpenSans-Extra-Bold);

                            @include screen-sm {
                                @include fonts($display-font-x-sm, 5.6rem, $vampire-black, $OpenSans-Extra-Bold);
                                margin-bottom: 3.5rem;
                            }
                        }
                    }

                    &:nth-child(2) {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .orangesolidBtn {
                            margin-left: 1.5rem;
                            padding: 1.5rem 2.5rem;

                            .vote-icon-3 {}

                            span {
                                margin-right: 1.5rem;
                            }

                            .swipe-arrow {}
                        }
                    }

                    &:last-child {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .css-b62m3t-container {
                            height: fit-content;
                        }

                        .cselectpageSize {
                            font-size: $detail-header-level-one;
                            width: fit-content;
                            margin-left: 2rem;

                            @include screen-sm {
                                font-size: $detail-header-level-sm-one;
                            }

                            .css-13cymwt-control {
                                border-color: transparent;
                                max-width: 97px;
                                border-radius: 5px;

                                .css-1dimb5e-singleValue {
                                    max-width: 97px;
                                }
                            }

                            .css-t3ipsp-control {
                                box-shadow: none;
                                border-color: transparent;
                                max-width: 97px;

                                &:hover {
                                    border-color: #707070;
                                }
                            }

                            .css-1u9des2-indicatorSeparator {
                                width: 0;
                            }

                            .css-1nmdiq5-menu {
                                max-width: 97px;
                                display: block;
                            }
                        }
                    }

                }
            }

            ;

            &:last-child {
                .elections-col {
                    padding-left: 0;
                    padding-right: 0;

                    &:last-child {
                        .election-card {
                            margin-top: 0;
                        }
                    }
                }

            }

            ;
        }
    }

    .cselectpageSize2 {
        font-size: $detail-header-level-one;

        @include screen-sm {
            font-size: $detail-header-level-sm-one;
        }

        .css-13cymwt-control {
            border-color: #707070;

            border-radius: 2.5rem;

            .css-1dimb5e-singleValue {}
        }

        .css-t3ipsp-control {
            box-shadow: none;
            border-color: #707070;
            border-radius: 2.5rem;


            &:hover {
                border-color: #707070;
            }
        }

        .css-1u9des2-indicatorSeparator {
            width: 0;
        }

        .css-1nmdiq5-menu {

            display: block;

        }
    }

}