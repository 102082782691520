.section-landing {
  &:nth-child(6) {
    // margin-top: 4.9rem;
    // padding: 4.9rem 0;
    padding-top: 5rem;
    // position: relative;

    @include screen-lg {
      padding: 4.9rem 0;
    }

    @include screen-md {
      padding: 2.9rem 0;
    }

    @include screen-sm {
      padding: 2.5rem 0;
      padding-top: 0rem;
    }

    .section-aside {
      .eduvoter-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #f88d1e 0%, #f8af1e 100%);
        padding-top: 2rem;
        padding-bottom: 7rem;

        @include screen-md {
          padding-bottom: 5rem;
        }
        @include screen-sm {
          padding-top: 0;
        }

        // .eduvoterimgblk {
        //   position: absolute;
        //   top: 0;
        //   left: 50%;
        //   transform: translateX(-50%);

        //   @include screen-sm {
        //     position: unset;
        //     top: unset;
        //     left: unset;
        //     transform: unset;
        //   }

        //   .userside-img {
        //     max-width: 100%;
        //   }
        // }

        .eduvoterwavyline {
          margin-top: 2rem;
          @include screen-md {
            margin-top: 1.5rem;
          }
        }

        .eduvotertitle {
          @include fonts(
            $header-level-two,
            4.3rem,
            $white-color,
            $OpenSans-Bold
          );
          text-align: center;
          margin-top: 5rem;

          @include screen-md {
            margin-top: 2.5rem;
          }
          @include screen-sm {
            @include fonts($header-level-x-sm, null, null, null);
          }
        }

        .eduvoterpara {
          width: 55%;
          margin-top: 5rem;

          @include fonts(
            $detail-header-level-one,
            2.8rem,
            $white-color,
            $OpenSans-SemiBold
          );
          margin-bottom: 1rem;

          @include screen-md {
            width: 75%;
            margin-top: 3rem;
          }
          @include screen-sm {
            width: 90%;
            @include fonts($detail-header-level-sm-one, null, null, null);
          }

          text-align: center;
        }

        .whiteBorderBtn {
          margin-top: 5rem;

          @include screen-lg {
            width: 25% !important;
          }
          @include screen-md {
            margin-top: 3rem;
          }
          @include screen-sm {
            width: 50% !important;
          }
        }
      }
    }
  }
}
