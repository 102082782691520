.blueBtn {
  @include button-styles($ocean-blue-color, $white-color);
  border: 1px solid $ocean-blue-color;
  &:hover,
  &:focus,
  &:active,
  &.btn:first-child:active {
    background-color: $dark-blue;
    border: 1px solid $ocean-blue-color;
  }
}
