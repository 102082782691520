.greysolidBtn {
    @include fonts($body-text-level-one,
        2.4rem,
        $granite-gray-color,
        null);

    @include screen-sm {
        @include fonts($body-text-level-x-sm-one, null, null, null);
    }

    background-color: $white-smoke;
    padding: 1.5rem 7.7rem;
    border: 1px solid $white-smoke;
    border-radius: 2.7rem;

    &:hover {
        background-color: $spanish-gray;
        border: 1px solid $spanish-gray;
    }

    &:first-child {
        &:active {
            background-color: $spanish-gray;
            border: 1px solid $spanish-gray;
        }
    }
}