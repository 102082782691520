.parlament-ex-main {


    .parlament-ex-container {
        .parlament-ex-row {
            padding: 3rem $padding-xl;

            @include screen-lg {
                padding: 3rem $padding-md;
            }

            @include screen-md {
                // flex-direction: column;
            }

            @include screen-sm {
                padding: 3rem $padding-xs;
            }

            .parlament-ex-col {
                .parlament-ex-heading {
                    @include fonts($header-level-two,
                        4.3rem,
                        $vampire-black,
                        $OpenSans-Bold );

                    @include screen-sm {
                        @include fonts($header-level-x-sm, null, null, null);
                    }

                    .parlament-ex-heading-lbl {
                        color: $granite-gray-color;
                    }
                }

                .parlament-ex-location {
                    @include fonts($sub-header-level-two,
                        3.3rem,
                        $granite-gray-color,
                        null);

                    @include screen-sm {
                        @include fonts($sub-header-level-x-sm-two, null, null, null);
                    }

                    .location-i {
                        color: $ocean-blue-color;
                        cursor: pointer;
                    }
                }
            }

            &:nth-child(1) {
                .parlament-ex-col {
                    &:last-child {
                        .parlament-ex-subBlk {
                            display: flex;
                            align-items: center;

                            .svsrp-trophy {
                                width: 5.9rem;
                                height: 5.9rem;
                                border-radius: 50%;
                                background-color: #1e58f833;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .trophy {
                                    font-size: 3.319rem;
                                    color: #1e58f8;
                                }
                            }

                            .svsrp-details {
                                margin-left: 1.5rem;

                                .svsrp-deta-head {
                                    @include fonts($detail-header-level-one,
                                        2.7rem,
                                        $vampire-black,
                                        $OpenSans-SemiBold );

                                    @include screen-sm {
                                        @include fonts($detail-header-level-sm-one,
                                            null,
                                            null,
                                            null);
                                    }

                                    .svsrp-arw {
                                        color: #ff8000;
                                        margin-left: 0.8rem;
                                    }
                                }

                                .svsrp-deta-votes {
                                    @include fonts($body-text-level-two,
                                        2.7rem,
                                        $granite-gray-color,
                                        null);

                                    @include screen-sm {
                                        @include fonts($body-text-level-x-sm-two, null, null, null);
                                    }
                                }
                            }
                        }

                        .mpLogo {
                            margin-left: auto;

                            margin-top: auto;
                            margin-bottom: auto;
                            width: 6.7rem;
                            height: 6.7rem;

                            @include screen-sm {
                                margin: 0;
                            }

                            .mpLogoImg {
                                width: inherit;
                                height: inherit;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            &:nth-child(2) {
                // padding: unset;
                background-color: $light-gray1;

                .parlament-ex-voting-heading {
                    @include fonts($body-text-level-two,
                        4.3rem,
                        $granite-gray-color,
                        $OpenSans-SemiBold );

                    @include screen-sm {
                        @include fonts($body-text-level-x-sm-two, null, null, null);
                    }
                }

                .parlament-ex-voting-blk {
                    display: grid;
                    grid-template-columns: auto auto auto auto auto auto auto;
                    gap: 20px;
                    width: 100%;

                    @include screen-mdx {
                        grid-template-columns: auto auto auto;
                    }

                    @include screen-sm {
                        grid-template-columns: auto auto;
                    }

                    .right-border {
                        border-right: 1px solid $whisper;

                        &:last-child {
                            border-right: none;
                        }

                        @include screen-mdx {
                            &:nth-child(3) {
                                border-right: none;
                            }
                        }

                        @include screen-sm {
                            &:nth-child(2) {
                                border-right: none;
                            }

                            &:nth-child(3) {
                                border-right: 1px solid $whisper;
                            }

                            &:nth-child(4) {
                                border-right: none;
                            }
                        }
                    }

                    .sqr-box0 {
                        @include square-box($caribbean-green);
                        background-color: #ececec;
                    }

                    .sqr-box1 {
                        @include square-box($ocean-blue-color);
                        background-color: #ececec;
                    }

                    .sqr-box2 {
                        @include square-box($bright-turquoise);
                        background-color: #ececec;
                    }

                    .sqr-box3 {
                        @include square-box($bittersweet);
                        background-color: #ececec;
                    }

                    .sqr-box4 {
                        @include square-box($electric-purple);
                        background-color: #ececec;
                    }

                    .sqr-box5 {
                        @include square-box($slate-blue);
                        background-color: #ececec;
                    }

                    .sqr-box6 {
                        @include square-box($medium_pink);
                        background-color: #ececec;
                    }

                    .registered-e-div {
                        .provincial-vote {
                            font-size: $detail-header-level-one;
                            font-family: $OpenSans-Bold;
                            color: $vampire-black;

                            @include screen-sm {
                                font-size: $body-text-level-x-sm-two;
                            }
                        }

                        .registered-electors {
                            font-size: $body-text-level-two;
                            color: $shuttle-gray;

                            @include screen-sm {
                                font-size: $body-text-level-x-sm-two;
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                .parlament-ex-col {
                    margin-bottom: 3rem;

                    .parlament-ex-heading {

                        @include fonts($header-level-three,
                            2.2rem,
                            $vampire-black,
                            $OpenSans-SemiBold );

                        @include screen-sm {
                            @include fonts($header-level-x-sm, null, null, null);
                        }
                    }

                    &:nth-child(1) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .activeSwitch {
                            display: flex;
                            justify-content: flex-end;


                            .form-check-input {
                                max-width: 4.9rem;
                                width: 100%;
                                min-height: 2.4rem;
                                margin-left: 1rem;

                                &:focus {
                                    box-shadow: unset !important;
                                    border-color: #0e76a8;
                                }

                                &:checked {
                                    background-color: #0e76a8;
                                    border-color: #0e76a8;
                                }
                            }

                            .form-check-label {
                                margin-left: 1.2rem;
                                @include fonts($body-text-level-two,
                                    2.7rem,
                                    $vampire-black,
                                    null);

                                @include screen-sm {
                                    @include fonts($body-text-level-x-sm-two, null, null, null);
                                }
                            }
                        }
                    }

                    &:nth-child(2) {
                        .table-responsive {
                            border: 1px solid $ocean-blue-color;
                            border-radius: 1rem;
                            max-height: 51rem;
                            overflow-y: auto;

                            .manageTable {
                                margin-top: 0rem;
                                vertical-align: middle;
                                position: relative;
                                width: 100%;

                                &:not(caption) {
                                    * {
                                        * {
                                            @include screen-sm {}
                                        }
                                    }
                                }

                                thead {
                                    position: sticky;
                                    top: 0;
                                    z-index: 2;
                                    background-color: $diamond-blue !important;
                                    color: $vampire-black !important;
                                }

                                .mpLogo {
                                    margin-left: auto;
                                    margin-right: auto;
                                    margin-top: auto;
                                    margin-bottom: auto;
                                    width: 5.6rem;
                                    height: 5.6rem;

                                    .mpLogoImg {
                                        width: inherit;
                                        height: inherit;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }


                    }

                    &:nth-child(3) {
                        .parlament-ex-bc {
                            border: 1px solid #0e76a8;
                            border-radius: 1rem;

                            div {
                                border-radius: 1rem;
                            }
                        }

                    }

                    &:nth-child(4) {
                        .parlament-ex-bc {
                            border: 1px solid #0e76a8;
                            border-radius: 1rem;

                            div {
                                border-radius: 1rem;
                            }

                            .highChart-img {
                                width: 3.2rem;
                                height: 3.2rem;
                                border-radius: 0.5rem;
                            }
                        }

                    }

                    &:nth-child(5) {
                        .parlament-ex-bc {
                            border: 1px solid #0e76a8;
                            border-radius: 1rem;

                            div {
                                border-radius: 1rem;
                            }
                        }
                    }
                }
            }

            &:nth-child(4) {
                .parlament-ex-col {
                    text-align: right;

                    .allianceText {
                        @include fonts($detail-header-level-one,
                            2.7rem,
                            $granite-gray-color,
                            null);

                        @include screen-sm {
                            @include fonts($detail-header-level-sm-one, null, null, null);
                        }
                    }

                    .solidBlueBtn {
                        margin: 0rem 1.5rem;
                        padding: 1.8rem 3.6rem;
                        border-radius: 3.2rem;
                        @include fonts($detail-header-level-one,
                            2.7rem,
                            $white-color,
                            null);

                        @include screen-sm {
                            @include fonts($detail-header-level-sm-one, null, null, null);
                        }
                    }
                }
            }

            &:nth-child(5) {
                .parlament-ex-col {
                    .parlament-ex-heading {
                        margin-bottom: 3rem;
                        @include fonts($header-level-three,
                            2.2rem,
                            $vampire-black,
                            $OpenSans-SemiBold );

                        @include screen-sm {
                            @include fonts($header-level-x-sm, null, null, null);
                        }
                    }

                    &:nth-child(2) {
                        margin-bottom: 3rem;

                        .parlament-ex-bc {
                            border: 1px solid #0e76a8;
                            border-radius: 1rem;

                            div {
                                border-radius: 1rem;
                            }
                        }
                    }

                    .table-responsive {
                        border: 1px solid $ocean-blue-color;
                        border-radius: 1rem;
                        overflow-x: scroll;
                        max-height: 51rem;
                        overflow-y: scroll;

                        .manageTable1 {
                            width: max-content;
                            position: relative;

                            &:not(caption) {
                                * {
                                    * {
                                        border-bottom: none;
                                        text-align: center;
                                        font-size: $detail-header-level-one;
                                        background-color: unset;
                                        color: unset;
                                        padding: unset;

                                        @include screen-sm {
                                            font-size: $detail-header-level-sm-one;
                                        }
                                    }
                                }
                            }

                            thead {
                                max-height: 51rem;
                                overflow-y: scroll;
                                background-color: $diamond-blue !important;
                                color: $vampire-black !important;
                                position: sticky;
                                top: 0;
                                z-index: 2;

                                tr {
                                    th {
                                        padding: 1rem 2rem;

                                        &:first-child {
                                            text-align: left;
                                            width: fit-content !important;
                                            background-color: $diamond-blue !important;
                                        }

                                        &:last-child {
                                            width: fit-content !important;
                                            background-color: $diamond-blue !important;
                                        }
                                    }
                                }
                            }

                            tbody {
                                td {
                                    padding: 2rem 2rem;

                                    .clickCell {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        cursor: pointer;

                                        width: 3.5rem;
                                        height: 3.5rem;
                                        padding: 1rem;
                                        border-radius: 50%;
                                        margin-left: auto;
                                        margin-right: auto;

                                        &:hover {
                                            text-decoration: underline;
                                            background-color: $ocean-blue-color;
                                            color: $white-color;
                                        }
                                    }

                                    &:first-child {
                                        text-align: left;
                                        width: fit-content !important;
                                        background-color: $white-color !important;
                                    }

                                    &:last-child {
                                        width: fit-content !important;
                                        background-color: $white-color !important;
                                    }
                                }
                            }
                        }
                    }


                }

                .sticky-column-left {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                }

                .sticky-column-right {
                    position: sticky;
                    right: 0;

                    z-index: 1;
                }


            }
        }
    }
}