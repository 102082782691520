.pReports {
  .cReportsTable {
    padding: 0 $padding-xl;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include screen-md {
      padding: 0 $padding-md;
    }
    @include screen-sm {
      padding: 0 $padding-xs;
    }

    .table {
      border-collapse: collapse;
      margin-bottom: 0;

      tbody tr td {
        border: 1px solid $white-smoke;
        padding: 7px;
        font-size: $body-text-level-three;
        font-family: $OpenSans-SemiBold;
        color: $vampire-black;
        text-wrap: nowrap;
        @include screen-sm {
          font-size: $body-text-level-x-sm-three;
        }
      }
      tbody tr td h5 {
        font-size: $body-text-level-two;
        font-family: $OpenSans-Regular;
        color: $vampire-black;
        text-wrap: balance;
        @include screen-mdx {
          text-wrap: balance;
        }
        @include screen-sm {
          font-size: $body-text-level-x-sm-two;
        }
      }

      thead tr th {
        padding: 7px;
        background-color: $white-smoke;
        font-size: $body-text-level-one;
        font-family: $OpenSans-Regular;
        color: $vampire-black;

        @include screen-sm {
          font-size: $body-text-level-x-sm-one;
        }
      }

      thead tr th a {
        color: $vampire-black;
      }

      .firstTd {
        width: 15%;
        @include screen-mdx {
          width: auto;
        }
      }

      .detailCandidate {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .mcLogo {
          width: 5.5rem;
          height: 5.5rem;
          border: 1px solid transparent;

          .img-fluid {
            display: block;
            border-radius: 50%;
            width: inherit;
            height: inherit;
            object-fit: cover;
          }
        }
      }
    }
  }
  .ercTotal {
    .table {
      tbody .nTr .nText {
        border: none !important;
        --bs-table-striped-bg: $white-color !important;
        color: $ocean-blue-color;
        font-size: $body-text-level-two;
        font-family: $OpenSans-SemiBold;
        --bs-table-border-color: $white-color !important;
        --bs-table-hover-bg: $white-color !important;
        @include screen-sm {
          font-size: $body-text-level-x-sm-two;
        }
      }
      .nTr {
        border: none;
      }
      .pTextValue {
        width: 30%;
      }
    }
  }
  .winnerCell {
    background-color: $chinese-green;
    border-color: $green-tea;
  }

  .winnerCell td {
    background-color: $chinese-green !important;
  }

  .winnerCell th {
    background-color: $green-tea;
  }
}
