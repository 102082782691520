.hero-results {
  .island-header {
    margin-bottom: 1rem;
    @include fonts(
      $sub-header-level-three,
      3.6rem,
      $vampire-black,
      $OpenSans-Bold
    );

    @include screen-sm {
      @include fonts($sub-header-level-x-sm-three, null, null, null);
    }
  }
  .winner-row {
    margin-bottom: 5rem;
    // gap: 2.5rem;
    @include screen-md {
      display: grid !important;
      grid-template-columns: auto;
      gap: 1rem;
    }

    .winner-col {
      padding: 1rem !important;

      .winner-card {
        padding: 2rem;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 1rem;
        border: unset;
        flex-direction: row;
        align-items: center;

        .winner-blok-des {
          width: 12rem;
          height: 12rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .winner-blok-design {
            width: 9rem;
            height: 10rem;
            background-color: #0e76a826;
            clip-path: polygon(
              50% 0%,
              100% 25%,
              100% 75%,
              50% 100%,
              0% 75%,
              0% 25%
            );
            display: flex;
            justify-content: center;
            align-items: center;

            .winner-imgBlk {
              width: 6.8rem;
              height: 6.8rem;
              border-radius: 50%;

              .winner-img {
                border-radius: inherit;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .winner-votes {
          @include fonts(
            $sub-header-level-three,
            3.6rem,
            $vampire-black,
            $OpenSans-Bold
          );

          @include screen-sm {
            @include fonts($sub-header-level-x-sm-three, null, null, null);
          }

          .winner-votesTxt {
            padding-left: 0.8rem;
            @include fonts(
              $detail-header-level-one,
              2.7rem,
              $granite-gray-color,
              $OpenSans-Regular
            );

            @include screen-sm {
              @include fonts($detail-header-level-sm-one, null, null, null);
            }
          }
        }

        .winner-name a {
          @include fonts(
            $detail-header-level-one,
            2.7rem,
            $granite-gray-color,
            null
          );

          @include screen-sm {
            @include fonts($detail-header-level-sm-one, null, null, null);
          }

          .winner-arw {
            color: #ff8000;
          }
        }
      }

      &:nth-child(2) {
        .winner-blok-design {
          background-color: #f07e0c26 !important;
        }
      }

      &:nth-child(3) {
        .winner-imgBlk {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .winner-blok-design {
          background-color: #1e58f833 !important;
        }
      }

      &:hover {
        padding: 0rem !important;
        border-radius: 1rem;
        box-shadow: 0px 0px 5px rgba(193, 199, 208, 0.749);
        transform: translateY(9px);
      }
    }
  }
  .winner-div {
    display: grid;
    grid-template-columns: auto auto auto;
    // gap: 1rem;
    margin-bottom: 3rem;

    @include screen-lg {
      grid-template-columns: auto auto;
      gap: 1rem;
    }
    @include screen-md {
      grid-template-columns: auto;
      gap: 1rem;
    }

    .winner-col {
      padding: 1rem !important;

      .winner-card {
        padding: 2rem;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 1rem;
        border: unset;
        flex-direction: row;
        align-items: center;

        .winner-blok-des {
          width: 12rem;
          height: 12rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .winner-blok-design {
            width: 9rem;
            height: 10rem;
            background-color: #0e76a826;
            clip-path: polygon(
              50% 0%,
              100% 25%,
              100% 75%,
              50% 100%,
              0% 75%,
              0% 25%
            );
            display: flex;
            justify-content: center;
            align-items: center;

            .winner-imgBlk {
              width: 6.8rem;
              height: 6.8rem;
              border-radius: 50%;

              .winner-img {
                border-radius: inherit;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .winner-votes {
          @include fonts(
            $sub-header-level-three,
            3.6rem,
            $vampire-black,
            $OpenSans-Bold
          );

          @include screen-sm {
            @include fonts($sub-header-level-x-sm-three, null, null, null);
          }

          .winner-votesTxt {
            padding-left: 0.8rem;
            @include fonts(
              $detail-header-level-one,
              2.7rem,
              $granite-gray-color,
              $OpenSans-Regular
            );

            @include screen-sm {
              @include fonts($detail-header-level-sm-one, null, null, null);
            }
          }
        }

        .winner-name,
        .winner-name a {
          @include fonts(
            $detail-header-level-one,
            2.7rem,
            $granite-gray-color,
            null
          );

          @include screen-sm {
            @include fonts($detail-header-level-sm-one, null, null, null);
          }

          .winner-arw {
            color: #ff8000;
          }
        }
      }

      &:nth-child(2) {
        .winner-blok-design {
          background-color: #f07e0c26 !important;
        }
      }

      &:nth-child(3) {
        .winner-imgBlk {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .winner-blok-design {
          background-color: #1e58f833 !important;
        }
      }

      // &:hover {
      //   padding: 0rem !important;
      //   border-radius: 1rem;
      //   box-shadow: 0px 0px 5px rgba(193, 199, 208, 0.749);
      //   transform: translateY(9px);
      // }
    }
  }

  // Styles for both single and slider cards

  // Slick dots style
  .slick-dots li button:before {
    color: $Carrot-Orange;
  }

  // Other styles
  .header {
    h2 {
      margin-bottom: 2rem;
      @include fonts(
        $sub-header-level-two,
        3.3rem,
        $ocean-blue-color,
        $OpenSans-SemiBold
      );
      @include screen-sm {
        @include fonts(
          $sub-header-level-x-sm-two,
          null,
          null,
          $OpenSans-SemiBold
        );
      }
    }

    .cselectpageSize1 {
      font-size: $detail-header-level-one;
      width: 95%;
      // margin-left: 2rem;

      @include screen-sm {
        font-size: $detail-header-level-sm-one;
      }

      .css-13cymwt-control {
        border-color: $ocean-blue-color;
        max-width: 100%;
        border-radius: 2.7rem;

        .css-1dimb5e-singleValue {
          max-width: 100%;
        }
      }

      .css-t3ipsp-control {
        box-shadow: none;
        border-color: $ocean-blue-color;
        max-width: 100%;
        border-radius: 2.7rem;

        &:hover,
        &:focus,
        &:active,
        &.btn:first-child:active {
          border-color: #707070;
          border-radius: 2.7rem;
        }
      }

      .css-1u9des2-indicatorSeparator {
        width: 0;
      }

      .css-1nmdiq5-menu {
        max-width: 100%;
        display: block;
      }
    }
  }

  .provincial-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sqr-box {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
    }

    .sqr-box0 {
      @include square-box($caribbean-green);
      background-color: #ececec;
    }

    .sqr-box1 {
      @include square-box($ocean-blue-color);
      background-color: #ececec;
    }

    .sqr-box2 {
      @include square-box($bright-turquoise);
      background-color: #ececec;
    }

    .sqr-box3 {
      @include square-box($bittersweet);
      background-color: #ececec;
    }

    .sqr-box4 {
      @include square-box($electric-purple);
      background-color: #ececec;
    }

    .right-border {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid $whisper;
      width: 28rem;
      padding: 1.5rem 0;

      &:last-child {
        border-bottom: unset;
      }

      .registered-e-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .provincial-vote {
          @include fonts(
            $detail-header-level-one,
            2.7rem,
            $vampire-black,
            $OpenSans-SemiBold
          );
        }

        .registered-electors {
          @include fonts(
            $body-text-level-two,
            2.2rem,
            $granite-gray-color,
            null
          );
        }
      }
    }
  }

  .vPercentage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      @include fonts($body-text-level-one, 2.2rem, $vampire-black, null);

      @include screen-sm {
        @include fonts($body-text-level-x-sm-one, null, null, null);
      }
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    a {
      @include fonts($body-text-level-two, 2.2rem, $Carrot-Orange, null);

      @include screen-sm {
        @include fonts($body-text-level-x-sm-two, null, null, null);
      }
      .arrow {
        margin-left: 0.45rem;
        @include fonts($detail-header-level-two, 2.2rem, $Carrot-Orange, null);
        @include screen-sm {
          @include fonts($detail-header-level-sm-two, null, null, null);
        }
      }
    }
  }
  .header-tabs {
    padding: 0 $padding-xl;
    text-align: center;

    @include screen-lg {
      padding: 0 $padding-md;
    }

    @include screen-sm {
      padding: 0 2rem;
    }


    // .slick-prev,
    // .slick-next {
    //   width: 30px;
    //   height: 30px;
    //   color: $vampire-black;
    // }

    .arrow-icon,
    .arrow-icon1 {
      width: 30px;
      height: 30px;
      color: $vampire-black;
      position: absolute;
    }
    .disabled {
      color: $shuttle-gray;
    }
    .arrow-icon {
      left: -3rem;
      bottom: -6rem;
      @include screen-mdx {
        left: -2rem;
      }
      @include screen-md {
        left: -2rem;
        bottom: -5.5rem;
      }
      // @include screen-sm {
      //   left: -1rem;
      //   bottom: -5.5rem;
      // }
    }
    .arrow-icon1 {
      right: -3rem;
      bottom: -6rem;
      @include screen-mdx {
        right: -2rem;
      }
      @include screen-md {
        right: -2rem;
        bottom: -5.5rem;
      }
      // @include screen-sm {
      //   right: -1rem;
      //   bottom: -5.5rem;
      // }
    }
    .slick-track {
      display: flex;
      width: 100% !important;
    }

    @media (max-width: 1024px) {
      .slick-track {
        transform: none !important;
        display: flex !important;
        justify-content: center;
        gap: 1rem;
      }

      .slick-slide {
        float: none !important;
      }
    }

    .tab-item {
      padding: 10px;
      cursor: pointer;
      border-radius: 5rem;
      border: 1px solid transparent;
      transition: all 0.3s ease;
      flex: 1 0 200px;

      h4 {
        margin: 0;
      }
    }

    .tab-button {
      background: transparent;
      border: none;
      padding: 1rem 2rem;
      cursor: pointer;

      h4 {
        width: 15rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include fonts(
          $detail-header-level-one,
          3rem,
          $vampire-black,
          $OpenSans-SemiBold
        );

        @include screen-sm {
          @include fonts($detail-header-level-sm-one, null, null, null);
        }
      }

      &.active {
        background-color: lighten($vampire-black, 85%);
        color: white;
        border: 1px solid $white-smoke;
        border-radius: 5rem;
        padding: 1rem;
      }

      // &:hover {
      //   background-color: lighten($vampire-black, 85%);
      // }
    }

    .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-dots li button:before {
      color: $Carrot-Orange;
    }
  }

  @include screen-sm {
    .my-5 {
      margin: 1.5rem 0;
    }
  }
}
