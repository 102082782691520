.deleteModal {
    .modal-dialog {
        .modal-content {
            padding: 1rem;

            .modal-header {
                margin-top: 2rem;
                border-bottom: none;

                .deleteIcon {
                    margin: auto;
                    font-size: 10rem;
                    color: $red-color ;
                }
            }

            .modal-body {
                margin-bottom: 2rem;

                .ays {
                    text-align: center;
                    font-size: $header-level-three;
                    font-family: $OpenSans-SemiBold;
                    margin-bottom: 1rem;
                }

                .ywtdti {
                    text-align: center;
                    font-size: $detail-header-level-one;
                }
            }

            .modal-footer {
                border-top: none;
                justify-content: space-between;

                .btn-secondary {}
            }
        }
    }
}