.Header-main {
  position: sticky;
  top: 0;
  max-width: 1920px;
  width: 100%;
  z-index: 999;
  padding: 1.5rem $padding-xl;
  background-color: $white-color;
  border-bottom: 1px solid $white-smoke;

  @include screen-lg {
    padding: 1rem $padding-md;
  }

  @include screen-sm {
    padding: 1rem $padding-xs;
  }
  .mainLogo {
    width: 156px;
  }

  .h-search-form {
    position: relative;

    .search-icon {
      position: absolute;
      top: 25%;
      left: 5%;
      font-size: 20px;
      color: $dark-gray;
    }

    .h-searchBar {
      width: 300px;
      height: 40px;
      border: 1px solid $white-smoke;
      border-radius: 19px;
      padding-left: 4rem;
      background-color: $white-gray;
      font-size: $body-text-level-three;
      color: $dark-gray;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        font-size: $body-text-level-three;
        color: $dark-gray;
      }
    }
  }

  .dropdown {
    @include screen-lg {
      margin-top: 0.5rem;
      margin-left: 0rem;
    }

    .dropdown-toggle {
      &::after {
        border-top: unset;
        border-right: unset;
        border-bottom: unset;
        border-left: unset;
      }

      .loginBlk {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;

        .plogo {
          border-radius: inherit;
        }
      }

      .aloginbtn {
        border: 1px solid $ocean-blue-color;
        padding: 0.3rem;
        border-radius: 3.1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .loginbar {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        font-size: 1.77rem;
        color: $ocean-blue-color;
      }
    }
  }

  .AprofileBtn {
    margin-left: 1rem;

    .btn-primary {
      background-color: unset;
      color: $ocean-blue-color;
      border: unset;
      padding: unset;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .dropdown-menu {
      margin-top: 1.5rem;
      left: unset;
      right: 0;
      min-width: unset;
      max-width: fit-content;

      .mUlist {
        font-size: $body-text-level-two;
        color: $vampire-black;
        border-bottom: 1px solid $white-smoke;
        width: 100%;
        padding: 0.75em 5.8rem 0.75rem 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include screen-sm {
          font-size: $body-text-level-x-sm-two;
        }

        &:hover {
          background-color: $white-smoke;
        }

        &:last-child {
          border-bottom: unset;
        }

        .pm {
          color: $ocean-blue-color;
          font-size: $detail-header-level-one;
          margin-right: 1.4rem;

          @include screen-sm {
            font-size: $detail-header-level-sm-one;
          }
        }

        .sf {
          color: $ocean-blue-color;
          font-size: $detail-header-level-one;
          margin-right: 1.4rem;

          @include screen-sm {
            font-size: $detail-header-level-sm-one;
          }
        }

        .lo {
          color: $ocean-blue-color;
          font-size: $detail-header-level-one;
          margin-right: 1.4rem;

          @include screen-sm {
            font-size: $detail-header-level-sm-one;
          }
        }
      }

      .saveWorkBtn {
        padding: 1.3rem 7.3rem;

        border: 1px solid $ocean-blue-color;
        border-radius: 2.4rem;
        margin: 2.4rem auto;

        &:hover {
          background-color: $ocean-blue-color;
          color: $white-color;
        }
      }
    }
  }
}

.home-icon {
  font-size: 22px !important;
  color: $shuttle-gray !important;

  &:hover {
    color: $ocean-blue-color !important;
  }
}

.blue-under {
  color: $ocean-blue-color !important;
  font-size: 28px !important;
}

.h-second-tab {
  width: 60%;
  // .flag-select__btn[aria-expanded="true"]:after,
  // .flag-select__btn::after {
  //   position: absolute;
  //   display: block;
  //   content: "";
  //   width: 8px;
  //   height: 8px;
  //   top: 50%;
  //   right: 12px;
  //   margin-top: -3px;
  //   border-bottom: 1px solid $vampire-black;
  //   border-right: 1px solid $vampire-black;
  //   border-left: none;
  //   border-top: none;
  //   transform: rotate(45deg) translateY(-50%);
  //   transition: all 0.4s ease-in-out;
  //   transform-origin: 50% 0;
  // }
  // .flag-select__options {
  //   position: absolute;
  //   z-index: 999999;
  //   border: 1px solid #bdbbbb;
  //   border-radius: 21px;
  //   background: #ffffff;
  //   margin-top: 8px;
  //   padding: 8px 0;
  //   width: 100%;
  //   max-height: 160px;
  //   overflow: auto;
  // }
}

.dial-box {
  font-size: 40px;
  color: $vampire-black;
  background-color: $white-gray;
  border: 1px solid $white-smoke;
  border-radius: 21px;
  padding: 5px;
}

// .menu-languages {
//   background-color: $white-gray;
//   border: 1px solid $white-smoke;
//   border-radius: 21px;
//   min-width: 100px;
//   height: 39px;
//   .flag-select__option__label {
//     font-size: $body-text-level-three;
//     color: $vampire-black;
//     padding-left: 5px;
//   }
//   .flag-select__btn {
//     padding: 5px 10px 5px 2px;
//     font-size: $body-text-level-three !important;
//   }
// }
// .flag-select__option,
// .flag-select {
//   padding-bottom: 0 !important;
// }
.h-Login {
  font-size: 20px;
  background-color: $white-color;
  color: $ocean-blue-color;
  border: 1px solid $ocean-blue-color;
  border-radius: 21px;

  &:hover,
  &:focus,
  &:active {
    background-color: $white-color !important;
    color: $ocean-blue-color !important;
    border: 1px solid $ocean-blue-color !important;
    box-shadow: none !important;
  }
}
