.orangeProgressBar {
  .css-kykfkt {
    width: 25rem;
    height: 25rem;
  }
  .CircularProgressbar .CircularProgressbar-text {
    fill: $vampire-black !important;
    font-family: $OpenSans-SemiBold;
    font-size: 18px;
    dominant-baseline: no-change;
    text-anchor: middle;
  }
}
