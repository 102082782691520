.aProvinceSeats {
    background-color: $white-smoke;

    .addbock {
        padding: 0rem;
    }

    .asl-success{
        font-size: 1.6rem;
    }
}