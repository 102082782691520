@mixin screen-xlg {
    @media only screen and (min-width: 1399.98px){
        @content;
    }
}
@mixin screen-xl {
    @media only screen and (max-width: 1400px){
        @content;
    }
}
@mixin screen-lg {
    @media only screen and (max-width: 1024px){
        @content;
    }
}
@mixin screen-mdx {
    @media only screen and (max-width: 992px){
        @content;
    }
}
@mixin screen-md {
    @media only screen and (max-width: 768px){
        @content;
    }
}
@mixin screen-sm {
    @media only screen and (max-width: 576px){
        @content;
    }
}
@mixin screen-xs {
    @media only screen and (max-width: 480px){
        @content;
    }
}