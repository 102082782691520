.modal-content {
  border-radius: 2.7rem;
  border: none;
  .css-1n6sfyn-MenuList {
    max-height: 210px;
  }
}

.vyc-header {
  padding: 2rem 0;

  h3 {
    font-size: $body-text-level-one;
    font-family: $OpenSans-SemiBold;
    color: $vampire-black;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 5%;
    font-size: 2rem;
    font-family: $OpenSans-SemiBold;
  }
}

.vyc-body {
  padding-left: 2rem;
  padding-right: 2rem;
}

.vyc-btn1 {
  @include shortButton-styles($white-color, $ocean-blue-color);

  &:hover,
  &:focus,
  &:active,
  &.btn:first-child:active {
    background: $ocean-blue-color;
    border: 1px solid $white-color;
    color: $white-color;
  }
}

.vyc-btn2 {
  @include shortButton-styles($ocean-blue-color, $white-color);

  &:hover,
  &:focus,
  &:active,
  &.btn:first-child:active {
    background: $dark-blue !important;
    border: 1px solid $dark-blue !important;
    color: $white-color;
  }
}
