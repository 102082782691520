.section-landing {
  &:nth-child(7) {
    padding: 4.9rem $padding-xl;

    @include screen-lg {
      padding: 4.9rem $padding-md;
    }

    @include screen-md {
      padding: 2.9rem $padding-md;
    }

    @include screen-sm {
      padding: 2.5rem 1.5rem;
    }

    .ourApprochHead {
      display: flex;
      @include screen-md {
        flex-direction: column;
      }
      .section-aside {
        width: 50%;

        @include screen-md {
          width: 100%;
        }

        &:first-child {
          .aside-lable {
            background-color: #cfe4ee;
            padding: 1.1rem 0.8rem;
            padding-right: 1.5rem;
            border-radius: 0.5rem;
            margin-bottom: 2rem;

            svg {
              width: 3.8rem;
              height: 3.3rem;
              margin-right: 0.8rem;
            }

            @include fonts(
              $detail-header-level-one,
              3.4rem,
              $vampire-black,
              $OpenSans-Bold
            );

            @include screen-sm {
              @include fonts(
                $detail-header-level-sm-one,
                null,
                null,
                $OpenSans-Bold
              );
            }
          }

          .aside-heading {
            @include fonts(
              $header-level-two,
              4.3rem,
              $vampire-black,
              $OpenSans-Bold
            );
            margin-top: 2rem;

            @include screen-lg {
              width: 95%;
            }
            @include screen-md {
              width: 100%;
              margin-bottom: 2rem;
            }

            @include screen-sm {
              @include fonts($header-level-x-sm, null, null, null);
            }
          }

          .float-end {
            @include screen-md {
              display: none;
            }
          }
        }

        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          .aside-para {
            @include fonts(
              $body-text-level-one,
              3rem,
              $granite-gray-color,
              $OpenSans-SemiBold
            );
            margin-bottom: 4rem;

            @include screen-lg {
              width: 95%;
              margin-bottom: 5rem;
            }
            @include screen-md {
              width: 100%;
              margin-bottom: 2rem;
            }

            @include screen-sm {
              @include fonts($body-text-level-x-sm-one, null, null, null);
            }
          }

          .orangesolidBtn {
            padding: 1.5rem 3rem;
            margin: 4rem auto;

            @include screen-mdx {
              margin-top: 1rem;
            }
          }
        }
      }
    }

    // .section-heading {
    //   @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);
    //   text-align: center;
    //   margin-bottom: 5rem;

    //   @include screen-sm {
    //     margin-bottom: 2.5rem;
    //     @include fonts($header-level-x-sm, null, null, null);
    //   }
    // }

    // .section-block {
    // //   display: flex;
    // //   justify-content: space-between;
    // //   align-items: stretch;

    //   @include screen-md {
    //     flex-direction: column;
    //   }

    .section-aside1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      // justify-content: center;
      align-items: center;
      // text-align: center;
      height: 100%;
      padding: 2rem 1rem;

      &:hover {
        transform: translateY(-4px);
        box-shadow: 7px 7px 55px #c1c7d0bf;
        border-radius: 1rem;
      }

      @include screen-md {
        flex-direction: column;
        width: 100%;
        margin-bottom: 1rem;
      }

      .sa-imgblk {
        width: 9rem;
        height: 7rem;
        border-radius: 50%;
        background-color: $Old-Lace;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;

        @include screen-md {
          margin-right: 0rem;
          width: 7rem;
        }

        .numSpan {
          @include fonts(
            $sub-header-level-three,
            0rem,
            $Carrot-Orange,
            $OpenSans-Extra-Bold
          );
        }
      }

      .aside-heading {
        @include fonts(
          $detail-header-level-zero,
          3.3rem,
          $vampire-black,
          $OpenSans-Bold
        );
        width: 23%;
        text-wrap: wrap;
        margin-right: 1rem;
        //   margin-bottom: 1.5rem;

        @include screen-md {
          width: 100%;
          text-wrap: nowrap;
          text-align: center;
          margin: 1.5rem 0;
        }
        @include screen-sm {
          text-wrap: wrap;
          @include fonts($detail-header-level-sm-zero, null, null, null);
        }
      }

      .aside-para {
        @include fonts(
          $body-text-level-one,
          3rem,
          $granite-gray-color,
          $OpenSans-SemiBold
        );
        margin-bottom: 0;
        width: 90%;

        @include screen-md {
          width: 100%;
          text-align: center;
        }

        @include screen-sm {
          @include fonts($body-text-level-x-sm-one, null, null, null);
        }
      }
    }
    // }
  }
}
