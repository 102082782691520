.Hero-Section-parliament {
  padding: 0 $padding-xl;
  margin-top: 3rem;

  @include screen-lg {
    padding: 0 $padding-md;
  }

  @include screen-sm {
    padding: 0 $padding-xs;
    margin-top: 1.5rem;
  }

  // @include screen-sm {
  //   padding: 3rem $padding-xs;
  // }

  .backG {
    background-color: $ocean-blue-color;
    width: 0.85%;
    border-radius: 8px 0 0 8px;
  }

  .hero-disc {
    font-size: $body-text-level-two;
    color: $vampire-black;
    font-family: $OpenSans-Regular;
    background-color: $diamond-blue;
    padding: 1rem;
    border-radius: 0 8px 8px 0;
    width: 100%;

    span {
      font-family: $OpenSans-SemiBold;
    }

    @include screen-sm {
      font-size: $body-text-level-x-sm-two;

      tr {
        display: grid;
        grid-template-columns: auto;
      }
    }
  }
  .first-div {
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screen-md {
      flex-direction: column;
      align-items: flex-start;
    }
    // @include screen-md {
    //   &:last-child {
    //     align-items: center;
    //   }
    // }

    .hero-h5 {
      h5 {
        font-size: $header-level-two;
        font-family: $OpenSans-Bold;
        color: $vampire-black;

        span {
          color: $shuttle-gray;
        }

        @include screen-sm {
          font-size: $header-level-x-sm;
        }
      }

      p {
        font-size: $detail-header-level-zero;
        color: $shuttle-gray;
        display: flex;
        align-items: center;
        margin-top: 1rem;

        @include screen-mdx {
          display: block;
          // text-wrap: pretty;
          white-space: wrap;

          .map-icon {
            margin-bottom: 0.5rem;
          }
        }

        .location-i {
          color: $ocean-blue-color;
          font-size: 2rem;
          cursor: pointer;
        }

        @include screen-sm {
          font-size: $detail-header-level-sm-zero;

          .location-i {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
  .cards-result {
    margin-top: 5rem;
  }

  .hero-btns {
    .updateViewBtn {
      display: flex;
      align-items: center;

      @include screen-mdx {
        flex-direction: column;
        align-items: end;

        aside {
          margin-top: 1rem;
          margin-left: 0 !important;
        }
      }
      @include screen-md {
        flex-direction: row;

        aside {
          margin-top: 0rem;
          margin-left: 1rem !important;
        }
      }
      @include screen-sm {
        flex-direction: column;
        align-items: flex-start;

        aside {
          margin-top: 1rem;
          margin-left: 0 !important;
        }
      }
    }
  }

  .disCards {
    // .island-header {
    //   margin-bottom: 1rem;
    //   @include fonts(
    //     $sub-header-level-three,
    //     3.6rem,
    //     $vampire-black,
    //     $OpenSans-Bold
    //   );

    //   @include screen-sm {
    //     @include fonts($sub-header-level-x-sm-three, null, null, null);
    //   }
    // }

    .results-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }
    .winner-row {
      margin-bottom: 5rem;
      flex: 0 0 35%;
      margin-right: 1rem;
      height: 48rem;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: $Carrot-Orange #f1f1f1;

      @include screen-lg {
        flex: 0 0 45%;
      }
      @include screen-md {
        flex: 0 0 50%;
      }

      /* Custom Scrollbar for WebKit */
      .winner-row::-webkit-scrollbar {
        width: 5px;
      }

      .winner-row::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 6px;
      }

      .winner-row::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 6px;
      }

      .winner-row::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .winner-col {
        padding: 1rem !important;

        @include screen-sm {
          padding: 0.5rem 1rem 1rem 0.5rem !important;
        }

        .winner-card {
          width: 97%;
          padding: 1rem;
          box-shadow: 0px 0px 6px #00000029;
          border-radius: 1rem;
          border: unset;
          flex-direction: row;
          align-items: center;

          @include screen-sm {
            padding: 0.8rem 0;
            flex-direction: column;
          }

          a {
            @include screen-md {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            // .card-body {
            //   @include screen-sm {
            //     text-align: center;
            //   }
            // }
            @include screen-sm {
              display: flex;
              flex-direction: column;
              .card-body {
                text-align: center;
                padding: 0.5rem;
              }
            }
          }

          .winner-blok-des {
            width: 12rem;
            height: 12rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @include screen-md {
              width: 5rem;
              height: 5rem;
            }

            .winner-blok-design {
              width: 9rem;
              height: 10rem;
              background-color: #0e76a826;
              clip-path: polygon(
                50% 0%,
                100% 25%,
                100% 75%,
                50% 100%,
                0% 75%,
                0% 25%
              );
              display: flex;
              justify-content: center;
              align-items: center;

              @include screen-md {
                width: 4rem;
                height: 5rem;
              }

              .winner-imgBlk {
                width: 6.8rem;
                height: 6.8rem;
                border-radius: 50%;
                @include screen-md {
                  width: 2.8rem;
                  height: 2.8rem;
                }

                .winner-img {
                  border-radius: inherit;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .winner-votes {
            @include fonts(
              $sub-header-level-three,
              3.6rem,
              $vampire-black,
              $OpenSans-Bold
            );

            @include screen-sm {
              @include fonts($body-text-level-x-sm-one, null, null, null);

              .winnerVotes {
                display: none;
              }
            }

            .winner-votesTxt {
              padding-left: 0.8rem;
              @include fonts(
                $detail-header-level-one,
                2.7rem,
                $granite-gray-color,
                $OpenSans-Regular
              );

              @include screen-sm {
                @include fonts($body-text-level-x-sm-one, null, null, null);
              }
            }
          }

          .winner-name {
            @include fonts(
              $detail-header-level-one,
              2.7rem,
              $granite-gray-color,
              null
            );

            @include screen-sm {
              @include fonts($body-text-level-x-sm-one, null, null, null);
            }

            .winner-arw {
              color: #ff8000;

              @include screen-sm {
                display: none;
              }
            }
          }
        }

        &:nth-child(2) {
          .winner-blok-design {
            background-color: #f07e0c26 !important;
          }
        }

        &:nth-child(3) {
          .winner-imgBlk {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .winner-blok-design {
            background-color: #1e58f833 !important;
          }
        }

        // &:hover {
        //   padding: 0rem !important;
        //   border-radius: 1rem;
        //   box-shadow: 0px 0px 5px rgba(193, 199, 208, 0.749);
        //   transform: translateY(9px);
        // }
      }
    }
  }
  .candidate-div {
    flex: 0 0 65%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 3rem;
    height: 48rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $Carrot-Orange #f1f1f1;

    @include screen-lg {
      flex: 0 0 55%;
      grid-template-columns: auto;
      gap: 1rem;
    }
    @include screen-md {
      flex: 0 0 50%;
      grid-template-columns: auto;
      gap: 1rem;
    }
    @include screen-sm {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      height: 48rem;
    }

    /* Custom Scrollbar for WebKit */
    .winner-row::-webkit-scrollbar {
      width: 5px;
    }

    .winner-row::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 6px;
    }

    .winner-row::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 6px;
    }

    .winner-row::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .winner-col {
      padding: 1rem !important;

      @include screen-sm {
        padding: 0.5rem 1rem 1rem 0.5rem !important;
      }

      .winner-card {
        width: 100%;
        height: 110px;
        padding: 1rem;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 1rem;
        border: unset;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include screen-sm {
          padding: 0.5rem 0;
          display: flex;
          flex-direction: column;
          height: 100%;

          .card-body {
            padding: 0.5rem;
          }
        }

        .winner-blok-des {
          width: 12rem;
          height: 12rem;
          display: flex;
          justify-content: center;
          align-items: center;

          @include screen-sm {
            width: 6rem;
            height: 6rem;
          }

          .winner-blok-design {
            width: 9rem;
            height: 10rem;
            background-color: #0e76a826;
            clip-path: polygon(
              50% 0%,
              100% 25%,
              100% 75%,
              50% 100%,
              0% 75%,
              0% 25%
            );
            display: flex;
            justify-content: center;
            align-items: center;

            @include screen-sm {
              width: 5.5rem;
              height: 5.5rem;
            }

            .winner-imgBlk {
              width: 6.8rem;
              height: 6.8rem;
              border-radius: 50%;

              @include screen-sm {
                width: 4rem;
                height: 4rem;
              }

              .winner-img {
                border-radius: inherit;
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .winner-votes {
          @include fonts(
            $sub-header-level-three,
            3.6rem,
            $vampire-black,
            $OpenSans-Bold
          );

          @include screen-sm {
            @include fonts($sub-header-level-x-sm-three, null, null, null);
            text-align: center;
          }

          .winner-votesTxt {
            padding-left: 0.8rem;
            @include fonts(
              $detail-header-level-one,
              2.7rem,
              $granite-gray-color,
              $OpenSans-Regular
            );

            @include screen-sm {
              @include fonts($detail-header-level-sm-one, null, null, null);
            }
          }
        }

        .winner-name,
        .winner-name a {
          @include fonts(
            $detail-header-level-one,
            2.7rem,
            $granite-gray-color,
            null
          );

          @include screen-sm {
            @include fonts($detail-header-level-sm-one, null, null, null);
            text-align: center;
          }

          .winner-arw {
            color: #ff8000;
          }
        }
      }

      &:nth-child(2) {
        .winner-blok-design {
          background-color: #f07e0c26 !important;
        }
      }

      &:nth-child(3) {
        .winner-imgBlk {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .winner-blok-design {
          background-color: #1e58f833 !important;
        }
      }
    }
  }

  .winner-card.selected {
    background-color: $white-smoke !important;
  }

  @include screen-sm {
    .mb-5 {
      margin-bottom: 2rem !important;
    }
  }
  .notFoundError {
    width: 100%;
    text-align: center;

    .notFoundErrorTxt {
      font-size: $header-level-two;
      color: $ocean-blue-color;
    }
  }
}
