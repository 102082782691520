.card-six {
  border-radius: 1rem;
  box-shadow: 0px 0px 10px #00000029;
  padding: 1.5rem;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid $ocean-blue-color;
  }

  .card-title {
    @include fonts(
      $detail-header-level-zero,
      3.3rem,
      $ocean-blue-color,
      $OpenSans-SemiBold
    );
    @include screen-sm {
      @include fonts(
        $detail-header-level-sm-zero,
        null,
        null,
        $OpenSans-SemiBold
      );
    }
  }
  .card-p {
    @include fonts(
      $body-text-level-three,
      1.9rem,
      $Carrot-Orange,
      $OpenSans-SemiBold
    );
    @include screen-sm {
      @include fonts(
        $body-text-level-x-sm-three,
        null,
        null,
        $OpenSans-SemiBold
      );
    }

    span {
      margin-left: 0.7rem;
      @include fonts(
        $detail-header-level-one,
        1.9rem,
        $Carrot-Orange,
        $OpenSans-SemiBold
      );
      @include screen-sm {
        @include fonts(
          $detail-header-level-sm-one,
          null,
          null,
          $OpenSans-SemiBold
        );
      }
    }
  }
  .card-p2 {
    text-align: right;
    @include fonts(
      $body-text-level-three,
      1.9rem,
      $granite-gray-color,
      $OpenSans-SemiBold
    );
    @include screen-sm {
      @include fonts(
        $body-text-level-x-sm-three,
        null,
        null,
        $OpenSans-SemiBold
      );
    }

    span {
      margin-left: 0.7rem;
      @include fonts(
        $body-text-level-three,
        1.9rem,
        $vampire-black,
        $OpenSans-SemiBold
      );
      @include screen-sm {
        @include fonts(
          $body-text-level-x-sm-three,
          null,
          null,
          $OpenSans-SemiBold
        );
      }
    }
  }
  .card-table {
    margin-top: 3rem;
    td {
      &:first-child {
        @include fonts($detail-header-level-one, 2.7rem, $vampire-black, null);
        @include screen-sm {
          @include fonts($detail-header-level-sm-one, null, null, null);
        }
      }
      &:last-child {
        @include fonts(
          $detail-header-level-one,
          2.7rem,
          $green-cyan,
          $OpenSans-SemiBold
        );
        @include screen-sm {
          @include fonts($detail-header-level-sm-one, null, null, null);
        }
      }
    }
  }
}
