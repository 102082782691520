.main-auth {
    position: absolute;
    top: 0;
    z-index: 1000;
    max-width: 1920px;
    width: 100%;
    min-height: 100vh;
    background-color: $alabaster-color;
    background-image: url("../../../../images/auth-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen-md {
        background-position: center;
        background-size: cover;
    }
}



.authCenterBlk {
    width: 100%;
    margin-top: 3rem;

}

.authHead {
    @include auth-heading;
}

.authPara {
    @include auth-para;
}

.authLastBlk {
    margin-top: 6.8rem;
    margin-bottom: 6rem;
    width: 100%
}

@import "./loginPerefrance";
@import "./login";
@import "./emailSendOTP";
@import "./otp";
@import "./register";
@import "./resetPassword";