.district-accordian {
    .accordion-item {
        box-shadow: 0px 0px 6px #00000029;
        border: unset;
        border-radius: 1.5rem;
        margin-bottom: 3rem;

        .accordion-header {

            .accordion-button {
                border-radius: 1.5rem;
                padding: 2rem;
                justify-content: space-between;

                &:not(.collapsed) {
                    color: unset;
                    background-color: unset;
                    box-shadow: unset;
                }

                &.collapsed {
                    border-bottom-right-radius: 1.5rem;
                    border-bottom-left-radius: 1.5rem;
                }

                &::after {
                    content: unset
                }

                &:focus {
                    box-shadow: none;
                }

                .acc-header {
                    @include fonts($detail-header-level-two,
                        3rem,
                        $vampire-black,
                        $OpenSans-SemiBold );

                    @include screen-sm {
                        @include fonts($detail-header-level-sm-two, null, null, null);
                    }
                }

                .acc-icons {
                    svg {
                        font-size: 2.4rem;
                        color: $granite-gray-color;
                    }
                }
            }
        }

    }

    .accordion-body {
        padding: 0rem 2rem;
    }
}