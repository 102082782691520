.profile-main {
    .container-profile {
        padding: 0 $padding-xl;

        @include screen-lg {
            padding: 0 $padding-md;
        }

        @include screen-sm {
            padding: 0 $padding-xs;
        }

        .row-profile {
            margin-top: 3rem;
            border-bottom: 1px solid $white-smoke;
            padding-bottom: 3rem;

            &:first-child {
                .col-profile {
                    &:last-child {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .profileimage {
                            width: 11.3rem;
                            height: 11.3rem;
                            border: 1px dashed $white-smoke;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 2.5rem;

                            .profile-img {
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                            }

                            .badge {
                                width: 4rem;
                                height: 4rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: $white-color !important;

                                box-shadow: 0px 0px 6px #00000029;
                                left: 75%;

                                .pencil {
                                    font-size: 2rem;
                                    color: $granite-gray-color;
                                }
                            }

                            .user {
                                color: $ocean-blue-color;
                                font-size: 7rem;
                            }
                        }

                        .sl-badge {
                            background-color: transparent !important;
                            @include fonts($body-text-level-two,
                                2.2rem,
                                $granite-gray-color,
                                null);

                            @include screen-sm {
                                @include fonts($body-text-level-x-sm-two, null, null, null);
                            }
                        }
                    }
                }
            }

            .col-profile {
                .profile-head {
                    @include fonts($detail-header-level-one,
                        2.7rem,
                        $vampire-black,
                        $OpenSans-SemiBold );

                    @include screen-sm {
                        @include fonts($detail-header-level-sm-one, null, null, null);
                    }

                    margin-bottom: 1.5rem;
                }

                .profile-content {
                    @include fonts($body-text-level-two,
                        2.2rem,
                        $granite-gray-color,
                        null);

                    @include screen-sm {
                        @include fonts($body-text-level-x-sm-two, null, null, null);
                    }
                }
            }

            &:nth-child(2) {
                .col-profile {
                    &:last-child {
                        .row-sub-profile {
                            margin-bottom: 3.1rem;

                            &:nth-last-child(2) {


                                .col-sub-profile {
                                    .activeSwitch {
                                        .form-check-input {
                                            max-width: 4.9rem;
                                            width: 100%;
                                            min-height: 2.4rem;

                                            &:focus {
                                                box-shadow: unset !important;
                                                border-color: #0e76a8;
                                            }

                                            &:checked {
                                                background-color: #0e76a8;
                                                border-color: #0e76a8;
                                            }
                                        }

                                        .form-check-label {
                                            margin-left: 1.2rem;
                                            @include fonts($body-text-level-two,
                                                2.7rem,
                                                $vampire-black,
                                                null);

                                            @include screen-sm {
                                                @include fonts($body-text-level-x-sm-two,
                                                    null,
                                                    null,
                                                    null);
                                            }
                                        }
                                    }
                                }
                            }

                            .col-sub-profile {
                                .form-floating {
                                    .verifyEmail {
                                        position: absolute;
                                        top: 30%;
                                        right: 2rem;
                                        font-size: 2rem;
                                        color: $green-cyan;
                                    }
                                }

                                .form-floating>label {
                                    padding: 1rem 1.8rem;
                                    color: $ocean-blue-color;
                                    font-size: $body-text-level-two;

                                    @include screen-sm {
                                        font-size: $body-text-level-x-sm-two;
                                    }
                                }

                                .form-floating {
                                    .form-control {
                                        padding: 1rem 2rem;
                                        padding-top: 0;
                                        padding-bottom: 0;
                                        box-shadow: none;
                                        border-radius: 26px;
                                        height: calc(4.5rem + calc(var(--bs-border-width) * 2));
                                        min-height: calc(5.2rem + calc(var(--bs-border-width) * 2));

                                        display: flex;
                                        align-items: center;
                                        border-color: #0e76a8;
                                        font-size: $body-text-level-two;

                                        &:focus {
                                            border-color: #0e76a8;
                                        }

                                        @include screen-sm {
                                            font-size: $body-text-level-x-sm-two;
                                        }
                                    }

                                    .form-control:not(:placeholder-shown)~label,
                                    >.form-control:focus~label {
                                        transform: scale(0.85) translateY(-1.8rem) translateX(1.4rem) !important;
                                        color: $ocean-blue-color;
                                    }
                                }

                                // .form-control.is-invalid::after {
                                //     content: "Invalid input";
                                //     /* Tooltip text */
                                //     position: absolute;
                                //     top: 100%;
                                //     /* Position the tooltip below the input */
                                //     left: 0;
                                //     padding: 0.5em;
                                //     background-color: #dc3545;
                                //     /* Tooltip background color */
                                //     color: white;
                                //     /* Tooltip text color */
                                //     border-radius: 0.25rem;
                                //     z-index: 1;
                                //     /* Ensure tooltip appears above other elements */
                                //     font-size: 0.875rem;
                                //     /* Adjust font size as needed */
                                //     white-space: nowrap;
                                //     /* Prevent tooltip from wrapping */
                                //     display: none;
                                //     /* Initially hide the tooltip */
                                // }

                                // .form-control.is-invalid:focus::after {
                                //     display: block;
                                //     /* Show the tooltip when input is focused */
                                // }
                            }

                            &:nth-child(3) {
                                .col-sub-profile {
                                    &:first-child {}

                                    &:last-child {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;

                                        .transparentbtn {
                                            color: $ocean-blue-color;
                                            text-decoration: none;

                                            &:hover {
                                                background-color: unset;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }

                            &:nth-child(7),
                            &:nth-child(8) {
                                .col-sub-profile {
                                    .form-floating {


                                        .cselectpageSize1 {
                                            .css-1fdsijx-ValueContainer {
                                                padding: 1rem 2rem;
                                                font-size: 1.6rem;
                                            }
                                        }

                                        .css-13cymwt-control {
                                            &:hover {
                                                border-color: $ocean-blue-color;
                                            }
                                        }

                                        .css-1nmdiq5-menu {
                                            background-color: $white-color !important;
                                            z-index: 3;
                                        }

                                        &>label {
                                            padding: 0rem 0.8rem;
                                            top: -1.5rem;
                                            left: 2.3rem;
                                            height: fit-content;
                                            background-color: $white-color;
                                            font-size: 1.4;
                                            transform: scale(0.85);
                                        }
                                    }

                                }
                            }

                            &:last-child {
                                margin-bottom: 0rem;
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                .col-profile {
                    &:last-child {
                        .form-floating>label {
                            padding: 1rem 1.8rem;
                            color: $ocean-blue-color;
                            font-size: $body-text-level-two;

                            @include screen-sm {
                                font-size: $body-text-level-x-sm-two;
                            }
                        }

                        .form-floating {
                            margin-bottom: 3.1rem;

                            &:last-child {
                                margin-bottom: 0rem;
                            }

                            .form-control {
                                padding: 1rem 2rem;

                                padding-top: 0;
                                padding-bottom: 0;
                                box-shadow: none;
                                border-radius: 26px;
                                height: calc(4.5rem + calc(var(--bs-border-width) * 2));
                                min-height: calc(5.2rem + calc(var(--bs-border-width) * 2));
                                margin-bottom: 1.5rem;
                                display: flex;
                                align-items: center;
                                border-color: #0e76a8;
                                font-size: $body-text-level-two;

                                &:focus {
                                    border-color: #0e76a8;
                                }

                                @include screen-sm {
                                    font-size: $body-text-level-x-sm-two;
                                }
                            }

                            .form-control:not(:placeholder-shown)~label,
                            >.form-control:focus~label {
                                transform: scale(0.85) translateY(-1.8rem) translateX(1.4rem) !important;
                                color: $ocean-blue-color;
                            }
                        }
                    }
                }
            }

            &:last-child {
                .col-profile {
                    &:last-child {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .blueborderbtn {
                            padding: 1.5rem 5.4rem;
                            border-radius: 2.9rem;
                        }
                    }
                }
            }
        }
    }
}