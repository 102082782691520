.solidBlueBtn{
    font-size: $body-text-level-two;
    background-color: $ocean-blue-color;
    border: 1px solid $ocean-blue-color;
    color: $white-color;
    line-height: 2.2rem;
    border-radius: 2.6rem;
    transition: background-color 0.3s ease;
    @include screen-sm{
        font-size: $body-text-level-x-sm-two;
    }
    &:hover{
        border: 1px solid #0c5a7d;
        background-color: #0c5a7d;
    }
    &:active{
        border: 1px solid #0c5a7d !important;
        background-color: #0c5a7d !important;
    }
}