.section-showBlogs {
  background-color: $alabaster-color;
  padding: 0rem $padding-xl;

  @include screen-lg {
    padding: 0rem $padding-md;
  }

  @include screen-sm {
    padding: 0rem 1.5rem;
  }

  .section-container {
    margin-top: 5rem;
    margin-bottom: 5rem;

    .section-row {
      .section-col {
        .col-card {
          border: none;
          border-radius: 2rem;
          .card-img-top {
            max-height: 33rem;
            border-radius: 2rem;
          }

          .date {
            @include fonts(
              $body-text-level-three,
              2.7rem,
              $granite-gray-color,
              $OpenSans-SemiBold
            );

            @include screen-sm {
              @include fonts($body-text-level-x-sm-three, null, null, null);
            }
            background-color: $Old-Lace;
            padding: 0.1rem 1rem;
            border-radius: 0.5rem;
          }

          .card-title {
            @include fonts(
              $detail-header-level-one,
              3.3rem,
              $vampire-black,
              $OpenSans-Bold
            );

            @include screen-sm {
              @include fonts($detail-header-level-sm-one, null, null, null);
            }
          }

          .card-text {
            @include fonts(
              $body-text-level-two,
              2.7rem,
              $granite-gray-color,
              null
            );
            margin-bottom: 2rem;

            @include screen-sm {
              @include fonts($body-text-level-x-sm-two, null, null, null);
            }
          }
          .card-cName {
            @include fonts(
              $body-text-level-two,
              2.7rem,
              $Carrot-Orange,
              $OpenSans-SemiBold
            );

            @include screen-sm {
              @include fonts($body-text-level-x-sm-two, null, null, null);
            }
            background-color: $Old-Lace;
            padding: 0.1rem 1rem;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
}
