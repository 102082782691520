.cselectpageSize1 {
  font-size: $detail-header-level-one;
  width: fit-content;
  // margin-left: 2rem;

  @include screen-sm {
    font-size: $detail-header-level-sm-one;
  }

  .css-13cymwt-control {
    border-color: $ocean-blue-color;
    max-width: 100%;
    border-radius: 2.7rem;

    .css-1dimb5e-singleValue {
      max-width: 97px;
    }
  }

  .css-t3ipsp-control {
    box-shadow: none;
    border-color: $ocean-blue-color;
    max-width: 100%;
    border-radius: 2.7rem;

    &:hover,
    &:focus,
    &:active,
    &.btn:first-child:active {
      border-color: #707070;
      border-radius: 2.7rem;
    }
  }

  .css-1u9des2-indicatorSeparator {
    width: 0;
  }

  .css-1nmdiq5-menu {
    max-width: 100%;
    display: block;
  }
}

.cselectpageSizeLable1 {
  font-size: $detail-header-level-one;
  color: $vampire-black;
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.css-1n6sfyn-MenuList {
  padding: 0 !important;
}