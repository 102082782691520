.tooltip-container {
    position: relative;
  }
  .tooltip {
    visibility: hidden;
    background-color: #f5f5f5;
    color: #0e76a8;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 5;
    bottom: 100%;
    left: 50%;
    transform: translateX(-25%);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    font-size: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-10px) scale(1.1);
  }
  