.custom-select {
  position: relative;
  display: inline-block;

  .custom-select-trigger {
    position: relative;
    display: block;
    min-width: 110px;
    height: 39px;
    padding: 9px 32px 8px 15px;
    font-size: $body-text-level-three;
    color: $vampire-black;
    background: $white-gray;
    border-radius: 21px;
    border: 1px solid $white-smoke;
    cursor: pointer;

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      top: 50%;
      right: 12px;
      margin-top: -3px;
      border-bottom: 1px solid $vampire-black;
      border-right: 1px solid $vampire-black;
      transform: rotate(45deg) translateY(-50%);
      transition: all 0.4s ease-in-out;
      transform-origin: 50% 0;
    }
  }
  .custom-options {
    position: absolute;
    z-index: 1;
    display: block;
    top: 75%;
    left: 0;
    right: 0;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    margin: 15px 0;
    border: 1px solid $white-smoke;
    border-radius: 10px;
    background: $white-gray;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px);

    .custom-option {
      position: relative;
      display: block;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 10px;
      font-size: $body-text-level-three;
      color: $vampire-black;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      width: 100%;
      text-wrap: wrap;

      &:hover,
      &.selection {
        background: $white-smoke;
        border-radius: 0px;

        &:hover:first-child,
        &.selection:first-child {
          border-radius: 10px 10px 0 0;
        }
        &:hover:last-child,
        &.selection:last-child {
          border-radius: 0px 0px 10px 10px;
        }
      }
    }
  }
  &.opened .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0);
  }
}
