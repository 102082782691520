// .nonPartisan-approach {
// display: flex;
// align-items: center;
// justify-content: space-between;
// background: linear-gradient(180deg, #f88d1e 0%, #f8af1e 100%);
// padding-top: 5rem;

// @include screen-lg {
//   padding: 4.9rem 0;
// }

// @include screen-mdx {
//   flex-direction: column;
// }
// @include screen-md {
//   padding: 2.9rem 0;
// }

// @include screen-sm {

//   padding: 2.5rem 0;
//   padding-top: 0rem;
// }

// .section-aside {

//   .eduvoter-block {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding-top: 2rem;
//     padding-bottom: 7rem;

//     @include screen-md {
//       padding-bottom: 5rem;
//     }
//     @include screen-sm {
//       padding-top: 0;
//     }

//     .eduvoterwavyline {
//       margin-top: 2rem;
//       @include screen-md {
//         margin-top: 1.5rem;
//       }
//     }

//     .eduvotertitle {
//       @include fonts($header-level-two, 4.3rem, $white-color, $OpenSans-Bold);
//       text-align: center;
//       margin-top: 5rem;

//       @include screen-md {
//         margin-top: 2.5rem;
//       }
//       @include screen-sm {
//         @include fonts($header-level-x-sm, null, null, null);
//       }
//     }

//     .eduvoterpara {
//       width: 85%;
//       margin-top: 5rem;

//       @include fonts(
//         $detail-header-level-one,
//         2.8rem,
//         $white-color,
//         $OpenSans-SemiBold
//       );
//       margin-bottom: 1rem;

//       @include screen-md {
//         width: 75%;
//         margin-top: 3rem;
//       }
//       @include screen-sm {
//         width: 90%;
//         @include fonts($detail-header-level-sm-one, null, null, null);
//       }

//       text-align: center;
//     }
//   }
// }

.nonPartisan-approach {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  padding: 0 $padding-xl;

  @include screen-lg {
    padding: 0 $padding-md;
  }
  @include screen-md {
    padding: 0 $padding-md;
    grid-template-columns: auto;
  }
  @include screen-sm {
    padding: 0 $padding-xs;
  }

  .section-vmBlk {
    //   width: 50%;
    background-color: $white-smoke;
    padding: 2.3rem 3rem;
    border-radius: 1rem;

    div {
      align-items: center;
      @include screen-mdx {
        align-items: flex-start;
        height: 8.8rem;
      }
      @include screen-md {
        align-items: center;
        height: unset;
      }
    }

    @include screen-md {
      // width: 100%;
      padding: 1.15rem 1.5rem;
    }

    .vmblk-heading {
      position: relative;
      @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);
      //   padding-bottom: 1rem;
      //   margin-bottom: 2rem;

      @include screen-sm {
        @include fonts($header-level-x-sm, null, null, null);
      }

      //   &::after {
      //     content: "";
      //     position: absolute;
      //     left: 1.2rem;
      //     bottom: 0;
      //     width: 4rem;
      //     height: 0.2rem;
      //     background-color: $Safety-Orange;
      //     border: 0.2rem solid $Safety-Orange;
      //   }
    }

    .vmblk-para {
      @include fonts(
        $body-text-level-one,
        3rem,
        $granite-gray-color,
        $OpenSans-SemiBold
      );
      margin-bottom: 0.2rem;

      @include screen-sm {
        @include fonts($body-text-level-x-sm-one, null, null, null);
      }
    }

    &:first-child {
      // margin-right: 2rem;

      @include screen-md {
        margin-right: 0rem;
        margin-bottom: 1rem;
      }
    }

    &:last-child {
      // margin-left: 2rem;

      @include screen-md {
        margin-left: 0rem;
      }
    }

    &:hover {
      background-color: $ocean-blue-color;

      .vmblk-heading {
        color: $white-color;

        &::after {
          background-color: $white-color;
          border: 0.2rem solid $white-color;
        }
      }

      .vmblk-para {
        color: $white-color;
      }
    }
  }
}
// }
