// Assuming variables are defined elsewhere or imported

.total-votesmain {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 30px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @include screen-lg {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .total-votes {
    background: transparent linear-gradient(119deg, #f4f9fd 0%, #fdfbf1 100%) 0%
      0% no-repeat padding-box;
    border-radius: 13px;
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;

    .total-votes-row {
      border-bottom: 1px solid #f1f1f1d9;
      padding-bottom: 3.2rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-side {
        h5 {
          color: $vampire-black;
          font-size: $body-text-level-one;
          font-family: $OpenSans-SemiBold;
        }
        h6 {
          color: $granite-gray-color;
          font-size: $body-text-level-one;
          font-family: $body-text-level-two;
          margin-top: 0.9rem;
        }
      }

      .right-side {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: $ocean-blue-color;
          font-size: $header-level-three;
          font-family: $OpenSans-SemiBold;
        }
      }
    }

    .total-votes-row2 {
      padding-top: 2.5rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-side {
        h5 {
          color: $vampire-black;
          font-size: $body-text-level-one;
          font-family: $OpenSans-SemiBold;
        }
        h6 {
          color: $granite-gray-color;
          font-size: $body-text-level-one;
          font-family: $body-text-level-two;
          margin-top: 0.9rem;
        }
      }
    }
  }

  .leadingCandidates {
    background: transparent linear-gradient(104deg, #f4f9fd 0%, #fdfbf1 100%) 0%
      0% no-repeat padding-box;
    border-radius: 13px;
    overflow-x: auto;
    max-width: 100%;
    white-space: nowrap;

    .leadingCandidates-Table {
      margin-top: 1rem;
      width: 100%;

      &:not(caption) {
        * {
          * {
            border-bottom: none;
            background-color: unset;
            color: unset;
            padding: unset;

            @include screen-sm {
              font-size: $detail-header-level-sm-one;
            }
          }
        }
      }

      thead {
        font-family: $OpenSans-Regular;
        color: $shuttle-gray !important;
        font-size: $body-text-level-two;

        td {
          // padding-top: 1rem;
          // padding-bottom: 1rem;
          padding: 1rem 1rem 1rem 0.5rem;
          text-align: center;

          &:first-child {
            text-align: start;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #f1f1f1d9;

          &:last-child {
            border: none;
          }
        }

        td {
          width: 11%;
          text-align: center;
          padding: 1rem 1rem 1rem 0.5rem;
          @include screen-mdx {
            width: auto;
          }

          &:first-child {
            width: 50%;
            text-align: start;
            padding-top: 1rem;
            padding-bottom: 1.5rem;
            color: $vampire-black;
            font-size: $detail-header-level-one;
            font-family: $OpenSans-SemiBold;
            @include screen-lg {
              width: 36%;
            }
            @include screen-mdx {
              width: auto;
            }
          }

          .second-child {
            @include backgroundVote($green-cyan);
          }
          .third-child {
            @include backgroundVote($ocean-blue-color);
          }
          .fourth-child {
            @include backgroundVote($coral-red);
          }
          .fifth-child {
            @include backgroundVote($electric-purple);
          }
          .sixth-child {
            @include backgroundVote($denim-color);
          }
        }
      }
    }
  }
}
