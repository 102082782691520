.enterOTPMain {
    background-color: $white-color;
    padding: 3rem;
    max-width: 400px;
    border-radius: 30px;
    border: 1px solid $ocean-blue-color;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .w-100 {
        margin-bottom: 3.1rem;
    }

    .lLink {
        margin-top: 2.5rem;
    }
}

.eoForm {
    margin-top: 3.8rem;
}

.eoFieldsetBlk {
    display: flex;
    justify-content: space-evenly;
}

.eoFieldset {
    border: 1px solid $ocean-blue-color;
    border-radius: 50%;
    font-size: $body-text-level-two;
    color: $black-color;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1.2rem 1.2rem;
    ;

    @include screen-lg {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    @include screen-md {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    @include screen-sm {
        font-size: $body-text-level-x-sm-two;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

    }

    &::placeholder {
        color: $black-color;

    }

    &:hover {
        box-shadow: none;
        border: 1px solid $ocean-blue-color;
    }

    &:focus {
        box-shadow: none;
        border: 1px solid $ocean-blue-color;
    }
}

.otptimer {
    margin-top: 3.5rem;
    text-align: center;
    font-size: $body-text-level-three;

    @include screen-sm {
        font-size: $body-text-level-x-sm-three;

    }
}

.resetBtn {
    font-size: 14px;
    border: none;
    color: $ocean-blue-color;
    background-color: transparent;
}

.seoFormBtn {
    margin-top: 3.5rem;
}