.blogs-main {
  .container-candidate {
    .row-candidate {
      background-color: $alabaster-color;

      &:first-child {
        padding: 3rem $padding-xl;

        @include screen-lg {
          padding: 3rem $padding-md;
        }

        @include screen-md {
          // flex-direction: column;
        }

        @include screen-sm {
          padding: 3rem $padding-xs;
        }
        .col-candidate {
          &:first-child {
            .candidate-heading {
              @include fonts(
                $display-font-one,
                7.1rem,
                $vampire-black,
                $OpenSans-Extra-Bold
              );

              @include screen-sm {
                @include fonts(
                  $display-font-x-sm,
                  5.6rem,
                  $vampire-black,
                  $OpenSans-Extra-Bold
                );
                margin-bottom: 3.5rem;
              }
            }
          }

          &:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .searchbtnform {
              .minputSearch {
                max-width: 33.6rem !important;
                padding: 1rem;
                font-size: 1.6rem;
                border: 1px solid $Gray44;
                color: $shuttle-gray;
                margin-right: 1rem;

                &:focus {
                  box-shadow: none;
                }
              }

              .solidsquarebluebtn {
                padding: 1rem 3.5rem;
              }
            }
          }
        }
      }
    }
    .section-row {
      padding: 4.9rem $padding-xl;

      @include screen-lg {
        padding: 4.9rem $padding-md;
      }

      @include screen-md {
        // flex-direction: column;
      }

      @include screen-sm {
        padding: 2.5rem $padding-xs;
      }
      .section-col {
        .col-card {
          border: none;

          .card-img-top {
            max-height: 33rem;
            border-radius: 2rem;
          }

          .date {
            @include fonts(
              $body-text-level-three,
              2.7rem,
              $granite-gray-color,
              $OpenSans-SemiBold
            );

            @include screen-sm {
              @include fonts($body-text-level-x-sm-three, null, null, null);
            }
            background-color: $Old-Lace;
            padding: 0.1rem 1rem;
            border-radius: 0.5rem;
          }

          .card-title {
            @include fonts(
              $detail-header-level-one,
              3.3rem,
              $vampire-black,
              $OpenSans-Bold
            );

            @include screen-sm {
              @include fonts($detail-header-level-sm-one, null, null, null);
            }
          }

          .card-text {
            @include fonts(
              $body-text-level-two,
              2.7rem,
              $granite-gray-color,
              null
            );
            margin-bottom: 2rem;

            @include screen-sm {
              @include fonts($body-text-level-x-sm-two, null, null, null);
            }
          }
          .card-cName {
            @include fonts(
              $body-text-level-two,
              2.7rem,
              $Carrot-Orange,
              $OpenSans-SemiBold
            );

            @include screen-sm {
              @include fonts($body-text-level-x-sm-two, null, null, null);
            }
            background-color: $Old-Lace;
            padding: 0.1rem 1rem;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
}
