.section-landing {
  &:first-child {
    // min-height: 100vh;
    display: flex;
    align-items: center;

    @include screen-mdx {
      flex-direction: column-reverse;
      // min-height: 100vh;
    }

    .section-aside {
      width: 50%;
      height: 400px;
      background-color: $white-color;
      display: flex;
      justify-content: center;

      @include screen-mdx {
        flex-direction: column;
        width: 100%;
      }

      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 3rem;

        @include screen-lg {
          padding: 2rem;
        }

        @include screen-sm {
          padding: 1.5rem;
          margin-top: 1rem;
        }

        .section-heading {
          @include fonts(5.6rem, 5.1rem, $Carrot-Orange, $OpenSans-Bold);
          margin-bottom: 1rem;

          @include screen-mdx {
            @include fonts(4.6rem, 2.1rem, $Carrot-Orange, $OpenSans-Bold);
          }
          @include screen-sm {
            @include fonts(3.5rem, 2.1rem, $Carrot-Orange, $OpenSans-Bold);
            margin-bottom: 2rem;
            text-wrap: wrap;
          }
          @include screen-xs {
            @include fonts(3rem, 2.1rem, $Carrot-Orange, $OpenSans-Bold);
            margin-bottom: 1rem;
            text-wrap: wrap;
          }

          &:nth-child(2) {
            @include fonts(
              $header-level-two,
              4.3rem,
              $vampire-black,
              $OpenSans-Bold
            );
            height: 40px;
            margin-bottom: 4rem;

            @include screen-mdx {
              @include fonts($header-level-x-sm, 3.3rem, null, null);
              margin-bottom: 2.5rem;
            }
            @include screen-sm {
              @include fonts($sub-header-level-x-sm-one, null, null, null);
              margin-bottom: 1rem;
              height: 100px;
            }
            @include screen-xs {
              @include fonts($sub-header-level-x-sm-one, null, null, null);
              margin-bottom: 1rem;
              height: 75px;
            }
          }
        }

        .section-btn-grp {
          @include screen-sm {
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          .orangesolidBtn {
            @include screen-sm {
              width: 100%;
            }

            &:last-child {
              margin-left: 1.5rem;
              // padding: 1.5rem 2.5rem;

              @include screen-lg {
                margin-left: unset;
                margin-top: 1rem;
              }
              @include screen-mdx {
                margin-left: 1rem;
                margin-top: unset;
              }

              @include screen-sm {
                margin-left: unset;
                margin-top: 1rem;
              }
            }
          }
        }
        .section-btn-grp2 {
          @include screen-sm {
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          .orangesolidBtn {
            @include screen-sm {
              width: 100%;
            }

            &:last-child {
              margin-left: 1.5rem;
              // padding: 1.5rem 2.5rem;

              // @include screen-lg {
              //   margin-left: unset;
              //   margin-top: 1rem;
              // }

              @include screen-sm {
                margin-left: unset;
                margin-top: 1rem;
              }
            }
          }
        }
      }

      &:last-child {
        text-align: end;
        .carousel-indicators {
          bottom: -5px;

          @include screen-mdx {
            bottom: -45px;
          }
        }
        .carousel-indicators [data-bs-target] {
          background-color: $Carrot-Orange;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;
        }
        .carousel-item {
          padding-bottom: 3.5rem;
          max-height: 400px;
          height: 370px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
  // .numberBlock {
  //   position: relative;
  //   bottom: -100px;
  // }
  // .userBlock {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   min-height: 100%;
  //   align-content: stretch;

  //   .userAside {
  //     &:first-child {
  //       display: flex;
  //       justify-content: space-between;
  //       padding: 1.9rem 2rem;
  //       background-color: $Safety-Orange;
  //       border-radius: 1rem;

  //       .userTxtBlk {
  //         .userNum {
  //           @include fonts(
  //             $header-level-two,
  //             4.3rem,
  //             $white-color,
  //             $OpenSans-Bold
  //           );
  //           margin-bottom: 0.2rem;

  //           @include screen-sm {
  //             @include fonts($header-level-x-sm, null, null, null);
  //           }
  //         }

  //         .userTxt {
  //           @include fonts($body-text-level-two, 2.2rem, $white-color, null);

  //           @include screen-sm {
  //             @include fonts($body-text-level-x-sm-two, null, null, null);
  //           }
  //         }
  //       }
  //     }

  //     &:last-child {
  //       margin-top: 3rem;
  //       position: relative;

  //       @include screen-lg {
  //         margin-top: 2rem;
  //       }

  //       @include screen-sm {
  //         margin-top: 1rem;
  //       }

  //       .userside-img {
  //         width: 100%;
  //         object-fit: scale-down;
  //       }
  //     }
  //   }
  // }
}
