.modal-content {
  border-radius: 2.7rem;
  border: none;
  .lForm {
    width: 100%;
    margin-top: 1.8rem;

    .form-floating > label {
      padding: 1rem 1.8rem;
      color: $ocean-blue-color;
      font-size: $body-text-level-two;

      @include screen-sm {
        font-size: $body-text-level-x-sm-two;
      }
    }

    .form-control {
      padding: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: none;
      border-radius: 26px;
      height: calc(4.5rem + calc(var(--bs-border-width) * 2));
      min-height: calc(4.5rem + calc(var(--bs-border-width) * 2));
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      border-color: #0e76a8;
      font-size: $body-text-level-two;

      &:focus {
        border-color: #0e76a8;
      }

      @include screen-sm {
        font-size: $body-text-level-x-sm-two;
      }
    }

    .form-control:not(:placeholder-shown) ~ label,
    .form-control:focus ~ label {
      transform: scale(0.85) translateY(-1.8rem) translateX(1.4rem) !important;
      color: $ocean-blue-color !important;
    }
  }
}
.vyc-header {
  padding: 2rem 0;
  h3 {
    font-size: $body-text-level-one;
    font-family: $OpenSans-SemiBold;
    color: $vampire-black;
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 5%;
    font-size: 2rem;
    font-family: $OpenSans-SemiBold;
  }
}
.vyc-body {
  padding-left: 2rem;
  padding-right: 2rem;
}
.vyc-btn1 {
  @include shortButton-styles($white-color, $ocean-blue-color);
  &:hover,
  &:focus,
  &:active,
  &.btn:first-child:active {
    background: $ocean-blue-color;
    border: 1px solid $white-color;
    color: $white-color;
  }
}
.vyc-btn2 {
  @include shortButton-styles($ocean-blue-color, $white-color);
  &:hover,
  &:focus,
  &:active,
  &.btn:first-child:active {
    background: $dark-blue !important;
    border: 1px solid $dark-blue !important;
    color: $white-color;
  }
}
