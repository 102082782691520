.PostBlock-six {
    padding: 2rem 3rem;
    background-color: $white-color;
    border: 1px solid $granite-gray-color;
    border-radius: 5px;

    .PostBlockChild {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $white-smoke;
        padding-bottom: 2rem;
        

        .PostBlockTitle {
            font-family: $OpenSans-Bold;
            font-size: $header-level-two;

            @include screen-sm {
                font-size: $header-level-x-sm;
            }
        }
    }
}