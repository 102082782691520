.electionPollingResults {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 25px;
  margin-bottom: 10rem;
  margin-top: 4.5rem;

  @include screen-lg {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  @include screen-sm {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .notFoundError {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // .notFoundErrorNum {
    //   font-size: 5.2rem;
    //   color: $ocean-blue-color;
    // }

    .notFoundErrorTxt {
      font-size: $header-level-two;
      color: $ocean-blue-color;
    }
  }

  .layOut-one-container {
    .layOut-one-row {
      border-radius: 1.5rem;

      &:first-child {
        padding: 0;

        // @include screen-lg {
        //   padding: 3rem $padding-md;
        // }

        // @include screen-md {
        //   // flex-direction: column;
        // }

        // @include screen-sm {
        //   padding: 3rem $padding-xs;
        // }

        .layOut-one-col {
          .layOut-one-heading {
            @include fonts(
              $body-text-level-one,
              auto,
              $granite-gray-color,
              $OpenSans-SemiBold
            );

            @include screen-sm {
              @include fonts(
                $body-text-level-x-sm-one,
                auto,
                $granite-gray-color,
                $OpenSans-SemiBold
              );
              // margin-bottom: 3.5rem;
            }
          }
        }
      }

      &:last-child {
        margin: 0rem;
        background-color: $white-smoke-2;
        padding: 2rem 2rem;
        max-height: 505px;
        overflow-y: auto;
        scrollbar-color: $Carrot-Orange $white-color;
        scrollbar-width: thin;
        white-space: nowrap;

        @include screen-lg {
          margin: 0;
        }

        @include screen-md {
          // flex-direction: column;
        }

        @include screen-sm {
          margin: 0;
        }

        .layOut-one-col {
          padding: 1rem;
        }
      }
    }

    &:last-child {
      margin-top: 0rem;
      margin-bottom: 0rem;
      text-align: start;
    }
  }

  p {
    color: $shuttle-gray;
    font-size: $body-text-level-one;
    font-family: $OpenSans-SemiBold;
  }
  .pollingMaps {
    max-height: 505px;
    text-align: center;
    img {
      cursor: pointer;
    }
  }
  .districtMaps-row {
    // padding: 1rem;
    margin-top: 1.5rem;
    max-height: 505px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: $Carrot-Orange $white-color;

    @include screen-lg {
      margin-top: 0rem;
      max-height: 460px;
    }
  }
  .districtMaps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: $white-smoke;
    max-height: 197px;
    max-width: 192px;
    height: 100%;
    width: 100%;

    &:nth-child(odd) {
      // margin-right: 3rem;
      @include screen-xl {
        margin-right: 1rem;
      }
      @include screen-lg {
        margin-right: 3rem;
      }
    }
    @include screen-lg {
      margin-right: 3rem;
      margin-bottom: 3rem !important;
    }
    .image-container {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
    }
    h4 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: $body-text-level-one;
      font-family: $OpenSans-SemiBold;
      color: $vampire-black;
    }
  }
  .pollingResults {
    background: $white-color;
    border: 1px solid $white-smoke;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 15px;
    overflow-x: auto;
    scrollbar-color: $Carrot-Orange $white-color;
    scrollbar-width: thin;
    white-space: nowrap;
    max-width: 100%;
    width: 100%;
    max-height: 505px;

    .leadingCandidates-Table {
      margin-top: 1rem;
      width: 100%;

      &:not(caption) {
        * {
          * {
            border-bottom: none;
            background-color: unset;
            color: unset;
            padding: unset;

            @include screen-sm {
              font-size: $detail-header-level-sm-one;
            }
          }
        }
      }

      thead {
        font-family: $OpenSans-SemiBold;
        color: $shuttle-gray !important;
        font-size: $body-text-level-two;

        td {
          padding: 1rem 0.7rem;
          text-align: start;

          &:first-child {
            // text-align: center;
            // width: 5%;
            padding-left: 0.8rem !important;
            width: auto;
            @include screen-mdx {
              width: auto;
            }
          }
        }
      }

      tbody {
        td {
          text-align: center;
          font-size: $body-text-level-three;
          font-family: $OpenSans-SemiBold;
          padding: 1rem 0.7rem;

          &:first-child {
            // width: 6%;
            text-align: start;
            // color: $shuttle-gray;
            // font-family: $OpenSans-Regular;
            padding-left: 1rem;
            @include screen-mdx {
              width: auto;
            }
          }
          &:nth-child(2) {
            color: $vampire-black;
            text-align: start;
            // width: 12%;
            @include screen-lg {
              width: 10%;
            }
            @include screen-mdx {
              width: auto;
            }
            @include screen-sm {
              padding-right: 3rem;
            }
          }
          &:nth-child(3) {
            color: $shuttle-gray;
            // width: 12%;
            @include screen-lg {
              width: 10%;
            }
            @include screen-mdx {
              width: auto;
            }
          }
          &:nth-child(4) {
            color: $caribbean-green;
            // width: 12%;
            @include screen-lg {
              width: 10%;
            }
            @include screen-mdx {
              width: auto;
            }
          }
          &:nth-child(5) {
            color: $ocean-blue-color;
            // width: 12%;
            @include screen-lg {
              width: 10%;
            }
            @include screen-mdx {
              width: auto;
            }
          }
          &:nth-child(6) {
            color: $bittersweet;
            // width: 12%;
            @include screen-lg {
              width: 10%;
            }
            @include screen-mdx {
              width: auto;
            }
          }
        }
      }
    }
  }
}
