.asidefour {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .asidefour-blk {
        &:first-child {
            .asidefour-head {
                @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);

                @include screen-sm {
                    @include fonts($header-level-x-sm, null, null, null);
                }
            }

            .asidefour-location {
                @include fonts($sub-header-level-two, 3.3rem, $granite-gray-color, null);

                @include screen-sm {
                    @include fonts($sub-header-level-x-sm-two, null, null, null);
                }
            }
        }



        &:last-child {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .asidefour-selectblk {
                margin-right: 1.5rem;

                .custom-select-trigger {
                    height: unset;
                    padding: 1.5rem 2rem;
                    min-width: 25rem;
                }

                &:last-child {
                    margin-right: 0rem;
                }

            }



        }
    }
}