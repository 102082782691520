.linkRedBorderbtn {
    font-size: $body-text-level-one;
    color: $red-color;
    border: 1px solid $red-color;
    background-color: $white-color;
    line-height: 2.2rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
    text-decoration: none;
  
    @include screen-sm {
      font-size: $body-text-level-x-sm-one;
    }
  
    &:hover {
      border: 1px solid $red-color;
      background-color: $red-color;
      color: $white-color;
    }
  
    &:active {
      border: 1px solid $red-color !important;
      background-color: $red-color !important;
      color: $white-color;
    }
  }
  