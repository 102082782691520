.voteWrapper {
  position: absolute;
  top: 84px;
  // left: 60%;
  right: 15%;
  z-index: 1099;
  border-radius: 0px 0px 20px 20px;
  background-color: $white-color;
  border: 1px solid red;
  padding: 16px 16px 12px 16px;

  // @include screen-xlg {
  //   left: 54%;
  // }
  @include screen-xl {
    right: 20%;
  }

  @include screen-lg {
    right: 27%;
  }

  @include screen-mdx {
    right: 20%;
  }

  @include screen-md {
    right: 18.5%;
  }

  @include screen-sm {
    left: 1%;
    padding: 12px 12px 10px 12px;
    right: 0;
  }

  .col {
    padding-left: 0;
    padding-right: 0;
  }

  .voteSimulator-row::after {
    content: "";
    position: absolute;
    top: 4%;
    left: 50%;
    height: 70%;
    width: 1px;
    background-color: $white-smoke;

    @include screen-sm {
      display: none !important;
    }
  }

  .electionType,
  .electionType-2 {
    background-color: $light-sky-blue;
    padding: 10px;
    border-radius: 24px;

    .vote-icon,
    .vote-icon-2,
    .vote-icon-3 {
      background-color: $white-color;
      color: $ocean-blue-color;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 3px;
    }

    .h-electionType {
      font-size: $detail-header-level-one;
      font-family: $OpenSans-Regular;
      color: $vampire-black;

      @include screen-sm {
        font-size: $detail-header-level-sm-one;
      }
    }
  }

  .electionType-2 {
    background-color: $Apricot-color;

    .vote-icon-2 {
      color: $Carrot-Orange;
    }
  }

  .h-votesimulator-body {
    font-size: $body-text-level-two;
    color: $granite-gray-color !important;
    display: flex;
    align-items: center;
    margin-left: 0.8rem;
    margin-bottom: 1rem;
    align-items: baseline;

    @include screen-sm {
      font-size: $body-text-level-x-sm-two;
    }
  }

  .voteSimu-btnblk {
    border: none;
    position: relative;

    .dropdown-toggle {
      width: 100%;
      background: transparent linear-gradient(90deg, $Carrot-Orange 0%, $Dark-Tangerine 100%) 0% 0% no-repeat padding-box;
      color: $white-color;
      border-radius: 2.9rem;
      border: none;
      opacity: 1;
      font-size: $detail-header-level-one;
      padding: 10px 24px 12px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        content: unset;
      }

      &:hover {
        .swipe-arrow {
          transform: translateX(50%);
          transition: all 0.4s ease-in-out;
        }
      }

      .vote-icon-3,
      .swipe-arrow {
        font-size: 25px;

        @include screen-sm {
          font-size: 23px;
        }
      }


    }

    .dropdown-menu {
      border: 1px solid red;
      width: fit-content;
      top: 50%;
      left: 100%;
      // right: 0;

      .mUlist1 {
        font-size: $body-text-level-x-sm-three;
        color: $vampire-black;
        border-bottom: 1px solid $white-smoke;
        width: 100%;
        padding: 0.5em 8rem 0.65rem 0.8rem;
        //   display: flex;
        //   justify-content: flex-start;
        //   align-items: center;

        @include screen-sm {
          font-size: $body-text-level-x-sm-three;
        }

        &:hover {
          background-color: $white-smoke;
        }

        &:last-child {
          border-bottom: unset;
        }
      }
    }


  }

  .vote-simulator-btn {
    color: $white-color;
    background: transparent linear-gradient(90deg, $Carrot-Orange 0%, $Dark-Tangerine 100%) 0% 0% no-repeat padding-box;
    border-radius: 29px;
    border: none;
    opacity: 1;
    font-size: $detail-header-level-one;
    padding: 10px 24px 12px 25px;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }

    .vote-icon-3,
    .swipe-arrow {
      font-size: 25px;

      @include screen-sm {
        font-size: 23px;
      }
    }

    .swipe-arrow:hover {
      transform: translateX(50%);
      transition: all 0.4s ease-in-out;
    }
  }
}