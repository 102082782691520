.parliamentElection-page {
  margin-bottom: 3rem;
  .mpLogo {
    display: inline-block;
    margin-right: 1rem;
    width: 3.6rem;
    height: 3rem;

    .mpLogoImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .manageTable {
    margin-top: 2rem;
    overflow-x: auto;
    overflow-y: auto;
    vertical-align: middle;

    @include screen-md {
      width: max-content;
    }
    @include screen-sm {
      margin-top: 0.5rem;
    }

    .notFoundError {
      width: 100%;
      text-align: center;

      .notFoundErrorTxt {
        font-size: $header-level-two;
        color: $ocean-blue-color;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;
      white-space: nowrap;
    }

    &:not(caption) {
      * {
        * {
          border-bottom: none;
          text-align: left;
          font-size: $detail-header-level-one;
          background-color: unset;
          color: unset;
          padding: unset;

          @include screen-sm {
            font-size: $detail-header-level-sm-one;
          }
        }
      }
    }

    thead {
      background-color: $white-smoke !important;
      font-family: $OpenSans-Regular;
      color: $shuttle-gray !important;

      th,
      td {
        padding: 1rem 1rem;
        text-align: left;
        white-space: nowrap;
      }
    }

    tbody {
      td {
        padding: 2rem 1rem;
        text-align: left;
        white-space: nowrap;
      }
    }
  }
  .cselectpageSize {
    font-size: $detail-header-level-one;
    width: fit-content;
    margin-left: 2rem;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }

    .css-13cymwt-control {
      border-color: #707070;
      max-width: 150px;
      border-radius: 5px;

      .css-1dimb5e-singleValue {
        max-width: 150px;
      }
    }

    .css-t3ipsp-control {
      box-shadow: none;
      border-color: #707070;
      max-width: 150px;

      &:hover {
        border-color: #707070;
      }
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }

    .css-1nmdiq5-menu {
      max-width: 150px;
      display: block;
    }
  }
  .cselectpageSize1 {
    font-size: $detail-header-level-one;
    margin-left: 2rem;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }

    .css-13cymwt-control {
      border-color: #707070;
      max-width: 180px;
      border-radius: 5px;

      .css-1dimb5e-singleValue {
        max-width: 180px;
      }
    }

    .css-t3ipsp-control {
      box-shadow: none;
      border-color: #707070;
      max-width: 180px;

      &:hover {
        border-color: #707070;
      }
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }

    .css-1nmdiq5-menu {
      max-width: 180px;
      display: block;
    }
  }
  .cselectpageSize2 {
    font-size: $detail-header-level-one;
    margin-left: 2rem;

    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }

    .css-13cymwt-control {
      border-color: #707070;
      max-width: 180px;
      border-radius: 5px;

      .css-1dimb5e-singleValue {
        max-width: 180px;
      }
    }

    .css-t3ipsp-control {
      box-shadow: none;
      border-color: #707070;
      max-width: 180px;

      &:hover {
        border-color: #707070;
      }
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }

    .css-1nmdiq5-menu {
      max-width: 180px;
      display: block;
    }
  }
}
