.electionReviewCad {
  background-color: $white-color;
  box-shadow: 0.2px 0.2px 15px 0px $light-gray;
  border: none;
  border-radius: 1rem;
  .cardTitle {
    background-color: $white-smoke;
    .cardTitleLink {
      font-size: $sub-header-level-three;
      font-family: $OpenSans-SemiBold;
      color: $vampire-black;

      @include screen-sm {
        font-size: $sub-header-level-x-sm-three;
      }
    }
  }
  .mpLogoImg {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid $Carrot-Orange;
    @include screen-sm {
      width: 150px;
      height: 150px;
    }
  }
  .candidateTitle {
    background-color: $ocean-blue-color;
    .candidateTitleLink {
      font-size: $sub-header-level-three;
      font-family: $OpenSans-SemiBold;
      color: $white-color;
      @include screen-sm {
        font-size: $sub-header-level-x-sm-three;
      }
    }
  }
  .pLogoImg {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  .pName {
    font-size: $detail-header-level-one;
    font-family: $OpenSans-SemiBold;
    color: $vampire-black;
    padding-left: 1.5rem;
    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }
  }
  .pText {
    font-size: $body-text-level-one;
    font-family: $OpenSans-Regular;
    color: $vampire-black;
    @include screen-sm {
      font-size: $body-text-level-x-sm-one;
    }
  }
}
