.card-seven {
  border: 1.5px solid $Carrot-Orange;
  border-radius: 1.5rem;
  padding: 1.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  background-color: $white-color;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, border 0.3s ease;

  .card-img-top {
    width: auto;
    max-width: 100%;
    height: 12.8rem;
  }

  .card-body {
    padding: 0;

    .card-title {
      @include fonts(
        $body-text-level-one,
        2.4rem,
        $vampire-black,
        $OpenSans-SemiBold
      );
      @include screen-sm {
        @include fonts($body-text-level-x-sm-one, null, null, null);
      }
      margin-top: 1.5rem;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .checkSign {
    position: absolute;
    top: -1.8rem;
    right: -1.8rem;
    color: $lush-green;
    background-color: $white-color;
    border-radius: 50%;
    padding: 0.4rem;

    z-index: 999;
    display: none;
    @include fonts($header-level-one, 0rem, $lush-green, $OpenSans-SemiBold);
    @include screen-sm {
      @include fonts($header-level-x-sm, null, null, null);
    }
  }
}

.card-seven.active-card {
  background-color: $white-color;
  border: 1.5px solid $lush-green;
  z-index: 1 !important;
  .checkSign {
    display: block;
    z-index: 999 !important;
  }
}
