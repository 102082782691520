.main-layOut-one {
  .layOut-one-container {
    .layOut-one-row {
      border-radius: 1.5rem;

      &:first-child {
        padding: 3rem $padding-xl;

        @include screen-lg {
          padding: 3rem $padding-md;
        }

        @include screen-md {
          // flex-direction: column;
        }

        @include screen-sm {
          padding: 3rem $padding-xs;
        }

        .layOut-one-col {
          .layOut-one-heading {
            @include fonts(
              $display-font-one,
              7.1rem,
              $vampire-black,
              $OpenSans-Extra-Bold
            );

            @include screen-sm {
              @include fonts(
                $display-font-x-sm,
                5.6rem,
                $vampire-black,
                $OpenSans-Extra-Bold
              );
              margin-bottom: 3.5rem;
            }
          }
        }
      }

      &:last-child {
        margin: 0rem $padding-xl;
        background-color: $white-smoke-2;
        padding: 2rem 2rem;

        @include screen-lg {
          margin: 2rem $padding-md;
        }

        @include screen-md {
          // flex-direction: column;
        }

        @include screen-sm {
          margin: 2rem $padding-xs;
        }

        .layOut-one-col {
          padding: 1rem;
        }
      }
    }

    &:last-child {
      margin-top: 5rem;
      margin-bottom: 10rem;
      text-align: center;

      .orangesolidBtn {
        padding: 1.5rem 3rem;
      }
    }
  }
}
