.pageNotFound-main {
    position: absolute;
    max-width: 1920px;
    top: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-color;
    z-index: 1000;

    .pageNotFound-section {
        text-align: center;

        .pnf-code {
            @include fonts(20rem, 22rem, $vampire-black, $OpenSans-Extra-Bold);

            @include screen-sm {
                @include fonts(null, null, null, null);
            }
        }

        .pnf-text {
            @include fonts($display-font-one, normal, $vampire-black, $OpenSans-SemiBold);

            @include screen-sm {
                @include fonts($display-font-x-sm, null, null, null);
            }
        }

        .orangesolidBtn {
            margin-top: 4rem;
        }
    }
}