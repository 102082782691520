.off-canvas {
    width: 75% !important;
    padding: 0rem 3rem;

    .offcanvas-title {
        flex: 1;
    }

    .btn-close {
        background: transparent var(--bs-btn-close-bg) center / 3em auto no-repeat;
    }

    .mpLogo {
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 5.6rem;
        height: 5.6rem;

        @include screen-sm {
            margin: 0;
        }

        .mpLogoImg {
            width: inherit;
            height: inherit;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .off-canvas-header {
        display: flex;
        // justify-content: ;
        align-items: center;

        .off-canvas-ptitle {
            @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);

            @include screen-sm {
                @include fonts($header-level-x-sm, null, null, null);
            }

            margin-left: 1.5rem;
        }

        .right-border {
            border-right: 1px solid $whisper;
            max-width: 22rem;
            width: 100%;

            margin-left: 1.5rem;

            &:last-child {
                border-right: unset
            }

        }

        .sqr-box0 {
            @include square-box($caribbean-green);
            background-color: #ececec;
            margin-top: unset;
        }

        .sqr-box1 {
            @include square-box($ocean-blue-color);
            background-color: #ececec;
            margin-top: unset;
        }

        .sqr-box2 {
            @include square-box($bright-turquoise);
            background-color: #ececec;
            margin-top: unset;
        }

        .provincial-vote {
            margin-right: 1.5rem;
            @include fonts($detail-header-level-one,
                2.7rem,
                $vampire-black,
                $OpenSans-SemiBold );

        }

        .registered-e-div {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .registered-electors {
            @include fonts($body-text-level-two, 2.2rem, $granite-gray-color, null);
        }

        .mpLogo1 {
            width: 5.6rem;
            height: 5.6rem;

            @include screen-sm {
                margin: 0;
            }

            .mpLogoImg {
                width: inherit;
                height: inherit;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }

    .table-responsive {
        border: 1px solid $ocean-blue-color;
        border-radius: 1rem;
        width: 100%;
        overflow-x: auto;

        .manageTable {
            margin-top: 0rem;
            vertical-align: middle;

            &:not(caption) {
                * {
                    * {
                        @include screen-sm {}
                    }
                }
            }

            thead {
                background-color: $diamond-blue !important;
                color: $vampire-black !important;
            }

            .mpLogo {
                margin-left: auto;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                width: 5.6rem;
                height: 5.6rem;

                .mpLogoImg {
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }
}