.loginPre {
    background-color: $white-color;
    padding: 3rem;
    max-width: 435px;
    border-radius: 30px;
    border: 1px solid $ocean-blue-color;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .w-100 {
        margin-bottom: 3.1rem;
    }

    .lLink {
        margin-top: 2.5rem;
    }
}

.lp-linkBlk{
    margin-top: 11rem;
}
.lp-link {
    font-size: $body-text-level-two;
    color: $shuttle-gray;
    line-height: 22px;

    @include screen-sm {
        font-size: $body-text-level-x-sm-two;
    }
}
