.section-landing {
  // .aboutHead {
  //   @include fonts($header-level-one, 4.3rem, $vampire-black, $OpenSans-Bold);
  // }
  .aboutStatement {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1.5rem;
    padding: 4.9rem $padding-xl;

    @include screen-lg {
      padding: 4.9rem $padding-md;
    }

    @include screen-md {
      display: flex;
      flex-direction: column;
      padding: 2.9rem $padding-md;
    }

    @include screen-sm {
      padding: 2.5rem 1.5rem;
    }

    .section-vmBlk {
      //   width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $white-color;
      padding: 2.3rem 3rem;
      border-radius: 1rem;

      @include screen-md {
        // width: 100%;
        padding: 1.15rem 1.5rem;
      }

      .ba-imgblk {
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 50%;
        background-color: $Old-Lace;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        .ba-icon {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      .vmblk-heading {
        position: relative;
        @include fonts(
          $header-level-two,
          4.3rem,
          $vampire-black,
          $OpenSans-Bold
        );
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        text-align: center;
       
        @include screen-mdx {
          height: 8rem;
        }
        @include screen-md {
          height: unset;
        }
        @include screen-sm {
          @include fonts($header-level-x-sm, null, null, null);
        }

        // &::after {
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   bottom: 0;
        //   width: 4rem;
        //   height: 0.2rem;
        //   background-color: $Safety-Orange;
        //   border: 0.2rem solid $Safety-Orange;
        // }
      }

      .vmblk-para {
        @include fonts(
          $body-text-level-one,
          3rem,
          $granite-gray-color,
          $OpenSans-SemiBold
        );
        text-align: center;
        margin-bottom: 0.2rem;

        @include screen-sm {
          @include fonts($body-text-level-x-sm-one, null, null, null);
        }
      }

      &:first-child {
        // margin-right: 2rem;

        @include screen-md {
          margin-right: 0rem;
          margin-bottom: 1rem;
        }
      }

      &:last-child {
        // margin-left: 2rem;

        @include screen-md {
          margin-left: 0rem;
        }
      }

      &:hover {
        // background-color: $white-color;
        transform: translateY(-4px);
        box-shadow: 7px 7px 55px #c1c7d0bf;
        border-radius: 1rem;
        // .vmblk-heading {
        //   color: $white-color;

        //   &::after {
        //     background-color: $white-color;
        //     border: 0.2rem solid $white-color;
        //   }
        // }

        // .vmblk-para {
        //   color: $white-color;
        // }
      }
    }
  }
}
.ourApproch {
  padding: 4.9rem $padding-xl;
  margin-top: 5rem;

  @include screen-lg {
    padding: 4.9rem $padding-md;
  }

  @include screen-md {
    padding: 2.9rem $padding-md;
  }

  @include screen-sm {
    padding: 2.5rem 1.5rem;
    margin-top: 2rem;
  }

  // .section-heading {
  //   @include fonts($header-level-two, 4.3rem, $vampire-black, $OpenSans-Bold);
  //   text-align: center;

  //   @include screen-sm {
  //     @include fonts($header-level-x-sm, null, null, null);
  //   }
  // }
  .ourApprochHead {
    display: flex;

    @include screen-md {
      flex-direction: column;
    }

    .section-aside {
      width: 50%;

      @include screen-md {
        width: 100%;
      }

      &:first-child {
        .aside-lable {
          background-color: #cfe4ee;
          padding: 1.1rem 0.8rem;
          padding-right: 1.5rem;
          border-radius: 0.5rem;
          margin-bottom: 2rem;

          svg {
            width: 3.8rem;
            height: 3.3rem;
            margin-right: 0.8rem;
          }

          @include fonts(
            $detail-header-level-one,
            3.4rem,
            $vampire-black,
            $OpenSans-Bold
          );

          @include screen-sm {
            @include fonts(
              $detail-header-level-sm-one,
              null,
              null,
              $OpenSans-Bold
            );
          }
        }

        .aside-heading {
          @include fonts(
            $header-level-two,
            4.3rem,
            $vampire-black,
            $OpenSans-Bold
          );
          margin-top: 2rem;

          @include screen-lg {
            width: 95%;
          }
          @include screen-md {
            width: 100%;
          }

          @include screen-sm {
            @include fonts($header-level-x-sm, null, null, null);
          }
        }

        .float-end {
          @include screen-md {
            display: none;
          }
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .aside-para {
          @include fonts(
            $body-text-level-one,
            3rem,
            $granite-gray-color,
            $OpenSans-SemiBold
          );
          margin-bottom: 4rem;

          @include screen-lg {
            width: 95%;
            margin-bottom: 5rem;
          }
          @include screen-md {
            width: 100%;
            margin-top: 2rem;
            margin-bottom: 2.5rem;
          }

          @include screen-sm {
            @include fonts($body-text-level-x-sm-one, null, null, null);
          }
        }

        .orangesolidBtn {
          padding: 1.5rem 3rem;
          margin: 4rem auto;
          // margin-top: 4rem;

          @include screen-mdx {
            margin-top: 2rem;
          }
        }
      }
    }
  }

  .section-para {
    width: 65%;
    @include fonts($detail-header-level-one, 2.8rem, $granite-gray-color, null);
    margin: 2rem auto;
    text-align: center;

    @include screen-sm {
      @include fonts($detail-header-level-sm-one, null, null, null);
      width: 100%;
    }
  }

  .sectionblk {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto auto auto;
    // justify-content: space-between;

    @include screen-md {
      display: grid;
      grid-template-columns: auto;
    }

    .blk-aside {
      // width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2.5rem 0.5rem;
      &:hover {
        transform: translateY(-4px);
        box-shadow: 7px 7px 55px #c1c7d0bf;
        border-radius: 1rem;
      }

      @include screen-sm {
        // flex-direction: column;
        width: 100%;
      }

      .ba-imgblk {
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 50%;
        background-color: $Old-Lace;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        .ba-icon {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      .ba-heading {
        min-height: 70px;
        max-width: 100%;
        @include fonts(
          $header-level-two,
          4.3rem,
          $vampire-black,
          $OpenSans-Bold
        );
        text-align: center;
        // margin-top: 5rem;

        @include screen-md {
          min-height: unset;
        }
        @include screen-sm {
          // margin-top: 2.5rem;
          @include fonts($header-level-x-sm, null, null, null);
        }
      }

      .ba-para {
        width: 92%;
        @include fonts(
          $body-text-level-one,
          3rem,
          $granite-gray-color,
          $OpenSans-SemiBold
        );
        margin-top: 2rem;
        text-align: center;

        @include screen-sm {
          @include fonts($body-text-level-x-sm-one, null, null, null);
          width: 100%;
        }
      }
    }
  }
}
.break-point {
  padding: 5rem $padding-xl;
  margin: 3rem 0;
  background: linear-gradient(180deg, $Carrot-Orange 0%, $Dark-Tangerine 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // text-align: center;

  @include screen-lg {
    padding: 5rem $padding-md;
  }

  @include screen-md {
    flex-direction: column;
  }

  @include screen-sm {
    padding: 3.5rem $padding-xs;
  }
  p {
    @include fonts($header-level-two, 4.3rem, $white-color, $OpenSans-Bold);
    @include screen-lg {
      width: 85% !important;
    }
    @include screen-md {
      width: 100% !important;
    }
    @include screen-sm {
      text-align: center;
      @include fonts($header-level-x-sm, null, null, null);
    }
  }
  @include screen-md {
    .whiteBorderBtn {
      width: 25% !important;
      margin-top: 2rem;
      @include screen-sm {
        width: 50% !important;
      }
    }
  }
}
