.manageUserList {
    background-color: $white-smoke;

    .manageUserListaside {
        padding: 0rem;

        @include screen-sm {
            padding: 1rem;
        }

        .manageBlock {

            .mangeHead {
                .manageHeadTitle {
                    flex: 1;
                }
            }



            .table-responsive {
                padding: 0rem 4rem 2rem 4rem;

                .manageTable {
                    tbody {
                        td {
                            padding-top: 1.7rem !important;
                            padding-bottom: 1.7rem !important;

                            &:nth-child(2),
                            &:first-child {
                                // text-align: left;
                                font-family: $OpenSans-SemiBold;
                            }
                        }
                    }
                }
            }

            .notFoundError {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .notFoundErrorNum {
                    font-size: 5.2rem;
                    color: $ocean-blue-color;
                }

                .notFoundErrorTxt {
                    font-size: $header-level-two;
                    color: $ocean-blue-color;
                }
            }
        }
    }

    .minputSearch {
        max-width: 33.6rem;
        padding: 1rem;
        font-size: 1.6rem;
        border: 1px solid $Gray44;
        color: $shuttle-gray;
        margin-right: 1rem;

        &:focus {
            box-shadow: none;
        }
    }

    .userListAction {

        .dropdown-toggle {
            background-color: $white-color;
            color: $vampire-black;
            border: 1px solid $vampire-black;
            padding: 1.3rem 3rem;


            &::after {
                content: unset;
            }
        }

        .dropdown-menu {
            background-color: $white-color;
            border: 1px solid $vampire-black;
            border-radius: 0.5rem;

            left: unset !important;
            right: 0 !important;

            .dropdown-item {
                width: 100%;
                text-align: start;
                padding: 0.75rem 1.5rem;
                border-radius: inherit;

                &:hover {
                    background-color: $white-smoke;
                }
            }
        }
    }

    .manageTable {
        margin-top: 2rem;
        vertical-align: middle;

        &:not(caption) {
            * {
                * {
                    // width: fit-content;

                    @include screen-sm {}
                }
            }
        }

        .manageTable {
            tbody {
                td {
                    padding-top: 1.7rem;
                    padding-bottom: 1.7rem;


                }
            }
        }

    }

}