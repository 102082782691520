.tandcPage {
  padding: 0 $padding-xl;
  margin-top: 5rem;
  margin-bottom: 5rem;
  @include screen-mdx {
    margin-top: 3rem;
  }
  @include screen-md {
    padding: 0 $padding-md;
  }
  @include screen-sm {
    padding: 0 $padding-xs;
  }

  .header {
    font-size: $sub-header-level-one;
    font-family: $OpenSans-Bold;
    color: $vampire-black;
    margin-bottom: 2rem;
    @include screen-sm {
      font-size: $sub-header-level-x-sm-one;
    }
  }
  hr {
    border-color: $vampire-black;
    opacity: 0.5;
  }
  h5 {
    font-size: $sub-header-level-two;
    font-family: $OpenSans-Bold;
    color: $vampire-black;
    margin-top: 2rem;
    @include screen-sm {
      font-size: $sub-header-level-x-sm-two;
    }
  }
  p,
  a {
    font-size: $detail-header-level-one;
    font-family: $OpenSans-Regular;
    color: $vampire-black;
    @include screen-sm {
      font-size: $detail-header-level-sm-one;
    }
  }
  ul {
    margin-left: 5rem;
    @include screen-sm {
      margin-left: 3rem;
    }
  }
}
