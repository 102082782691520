.main-layout-two {
    .container-layout-two {
        .row-layout-two {
            padding: 3rem $padding-xl;
            display: flex;

            @include screen-lg {
                padding: 3rem $padding-md;
            }

            @include screen-md {
                // flex-direction: column;
            }

            @include screen-sm {
                padding: 3rem $padding-xs;
            }

            .main-layout-aside {
                &:first-child {
                    width: 20%;

                    .sticky-top {
                        top: 10rem;
                        background-color: $alice-blue;
                        border-radius: 1.5rem;
                        z-index: 1;

                        .main-layout-heading {
                            text-align: center;
                            padding: 1.5rem;
                            @include fonts($detail-header-level-one,
                                3.2rem,
                                $black-color,
                                null);

                            @include screen-sm {
                                @include fonts($detail-header-level-sm-one, null, null, null);
                            }
                        }

                        .main-layout-ul {
                            .nav-item {
                                padding: 0 1rem;

                                .nav-link {
                                    @include fonts($detail-header-level-one,
                                        3.2rem,
                                        $spanish-gray,
                                        null);

                                    @include screen-sm {
                                        @include fonts($detail-header-level-sm-one,
                                            null,
                                            null,
                                            null);
                                    }
                                }
                            }
                        }
                    }

                    // display: inline-block;
                }

                &:last-child {
                    width: 80%;
                    // display: inline-block;
                }
            }
        }
    }
}