.macbid {
    background-color: $white-smoke;

    .mangeHead {
        .linkborderbtn {
            display: none;
        }
    }

    .mpLogo {
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 5.6rem;
        height: 5.6rem;

        .mpLogoImg {

            // height: auto;
            width: inherit;
            height: inherit;
            object-fit: cover;
        }
    }

    .macbidParty {
        padding: 0rem;

        @include screen-sm {
            padding: 1rem;
        }
    }

    .minputSearch {
        max-width: 33.6rem;
        padding: 1rem;
        font-size: 1.6rem;
        border: 1px solid $Gray44;
        color: $shuttle-gray;
        margin-right: 1rem;

        &:focus {
            box-shadow: none;
        }
    }

    .managPartyAction {
        .dropdown-toggle {
            background-color: $white-color;
            color: $vampire-black;
            border: 1px solid $vampire-black;
            padding: 1.3rem 3rem;


            &::after {
                content: unset;
            }
        }

        .dropdown-menu {
            background-color: $white-color;
            border: 1px solid $vampire-black;
            border-radius: 0.5rem;

            left: 0 !important;
            // right: 0 !important;

            .dropdown-item {
                width: 100%;
                text-align: start;
                padding: 0.75rem 1.5rem;
                border-radius: inherit;

                &:hover {
                    background-color: $white-smoke;
                }
            }
        }
    }

    .table-responsive {
        padding: 0rem 4rem 2rem 4rem;
        width: 100%;
        overflow-x: auto;

        .manageTable {
            margin-top: 2rem;
            vertical-align: middle;

            &:not(caption) {
                * {
                    * {
                        @include screen-sm {}
                    }
                }
            }
        }
    }

}