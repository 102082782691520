.manageBlock-two {
    padding: 2rem 3rem;
    background-color: $white-color;

    @include screen-sm {
        padding: 1rem 1rem;
    }

    .mangeHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid $white-smoke;

        @include screen-sm {
            padding-bottom: 1rem;
        }

        .manageHeadTitle {
            font-family: $OpenSans-Bold;
            font-size: $header-level-two;
            flex: 1;

            @include screen-sm {
                font-size: $header-level-x-sm;
            }
        }

        .linkborderbtn {
            margin-left: 1.5rem;
        }
    }

    .mangeHeadss {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include screen-sm {
            margin-top: 1rem;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .mangeSl {
            font-size: $detail-header-level-one;
            color: $shuttle-gray;

            @include screen-sm {
                font-size: $detail-header-level-sm-one;
            }
        }

        .cselectpageSize {
            font-size: $detail-header-level-one;
            width: fit-content;
            margin-left: 2rem;

            @include screen-sm {
                font-size: $detail-header-level-sm-one;
            }

            .css-13cymwt-control {
                border-color: #707070;
                max-width: 97px;
                border-radius: 5px;

                .css-1dimb5e-singleValue {
                    max-width: 97px;
                }
            }

            .css-t3ipsp-control {
                box-shadow: none;
                border-color: #707070;
                max-width: 97px;

                &:hover {
                    border-color: #707070;
                }
            }

            .css-1u9des2-indicatorSeparator {
                width: 0;
            }

            .css-1nmdiq5-menu {
                max-width: 97px;
                display: block;

            }
        }
    }


}