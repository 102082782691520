//  ========Fonts Styles Start =======
@font-face {
  font-family: OpenSans-Regular;
  src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: OpenSans-SemiBold;
  src: url("../fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: OpenSans-Bold;
  src: url("../fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: OpenSans-Extra-Bold;
  src: url("../fonts/OpenSans-ExtraBold.ttf");
}

// ========Fonts Styles End =======
//  ========Fonts Styles variables Start =======
$OpenSans-Regular: OpenSans-Regular, sans-serif;
//  Regular : 400
$OpenSans-SemiBold: OpenSans-SemiBold, sans-serif;
//  Medium : 500
$OpenSans-Bold: OpenSans-Bold, sans-serif;
//  Medium : 700
$OpenSans-Extra-Bold: OpenSans-Extra-Bold, sans-serif;
//  Medium : 900
//  ========Fonts Styles variables End =======
// Font & Line-height Size variables Start
// Font Size Start
// display font Start
// H-1 start
$display-font-one: 5.2rem;
$display-font-two: 4.2rem;
// @media only screen and (max-width: 576px)(Mobile Screens) start
$display-font-x-sm: 3.4rem;
// @media only screen and (max-width: 576px)(Mobile Screens) end
// H-1 end
// display font End

// Level 1 (Header) Start
// H-2 start
$header-level-one: 38px;
$header-level-two: 3.2rem;
$header-level-three: 3rem;
// @media only screen and (max-width: 576px)(Mobile Screens) start
$header-level-x-sm: 2.8rem;
// @media only screen and (max-width: 576px)(Mobile Screens) end
// H-2 end
// Level 1 (Header) End

// Level 2 (Sub Header) Start
// H-3 start
$sub-header-level-one: 2.8rem;
$sub-header-level-two: 2.4rem;
$sub-header-level-three: 2.6rem;
// @media only screen and (max-width: 576px)(Mobile Screens) start
$sub-header-level-x-sm-one: 2.4rem;
$sub-header-level-x-sm-two: 2.2rem;
$sub-header-level-x-sm-three: 2rem;
// @media only screen and (max-width: 576px)(Mobile Screens) end
// H-3 start
// Level 2 (Sub Header) end

// Level 4 (Detail Header) Start
// H-4 start

$detail-header-level-one: 2rem;
$detail-header-level-zero: 2.4rem;
$detail-header-level-two: 2.2rem;
// @media only screen and (max-width: 576px)(Mobile Screens) start
$detail-header-level-sm-zero: 2.2rem;
$detail-header-level-sm-one: 1.8rem;
$detail-header-level-sm-two: 2rem;
// H-4 end
// Level 4 (Detail Header) End

// Body text (details) Start
// H-5 start
$body-text-level-one: 1.8rem;
$body-text-level-two: 1.6rem;
$body-text-level-three: 1.4rem;
// @media only screen and (max-width: 576px)(Mobile Screens) start
$body-text-level-x-sm-one: 1.6rem;
$body-text-level-x-sm-two: 1.4rem;
$body-text-level-x-sm-three: 1.2rem;
// @media only screen and (max-width: 576px)(Mobile Screens) End
// H-5 end
// Body text (details) End

// Small body text Start

// H-6 start
// H-6 end
// Small body text end
// Font Size end
// Font & Line-height Size variables End