.aside-two {
    .aside-two-heading {
        @include fonts($header-level-two,
            4.3rem,
            $vampire-black,
            $OpenSans-SemiBold );

        @include screen-sm {
            @include fonts($header-level-x-sm, null, null, null);
        }
    }

    .aside-two-para {
        margin-top: 1.6rem;
        @include fonts($body-text-level-two,
            2.2rem,
            $vampire-black,
            null);

        @include screen-sm {
            @include fonts($body-text-level-x-sm-two, null, null, null);
        }
    }

    .aside-article {
        margin-top: 3rem;

        .article-subheading {
            @include fonts($body-text-level-one,
                2.4rem,
                $vampire-black,
                $OpenSans-SemiBold);

            @include screen-sm {
                @include fonts($body-text-level-x-sm-one, null, null, null);
            }
        }

        .article-subpara {
            margin-top: 1rem;
            @include fonts($body-text-level-two,
                2.2rem,
                $vampire-black,
                null);

            @include screen-sm {
                @include fonts($body-text-level-x-sm-two, null, null, null);
            }
        }
    }
}