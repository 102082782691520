.esoMain {
    background-color: $white-color;
    padding: 3rem;
    max-width: 435px;
    border-radius: 30px;
    border: 1px solid $ocean-blue-color;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.emailSendOtp {
    width: 100%;
    padding-top: 5rem;

    .form-floating>label {
        padding: 1rem 1.8rem;
        color: $ocean-blue-color;
        font-size: $body-text-level-two;
           
        @include screen-sm {
            font-size: $body-text-level-x-sm-two;
        }
    }

    .form-floating {
        .form-control {
            padding: 1rem;
            padding-top: 0;
            padding-bottom: 0;
            box-shadow: none;
            border-radius: 26px;
            height: calc(4.5rem + calc(var(--bs-border-width)* 2));
            min-height: calc(4.5rem + calc(var(--bs-border-width)* 2));
            margin-bottom: 1.5rem;
            display: flex;
            align-items: center;
            border-color: #0e76a8;
            font-size: $body-text-level-two;

            &:focus {
                border-color: #0e76a8;
            }

            @include screen-sm {
                font-size: $body-text-level-x-sm-two;
            }

        }

        .form-control:not(:placeholder-shown)~label,
        >.form-control:focus~label {
            transform: scale(.85) translateY(-1.8rem) translateX(1.4rem) !important;
            color: $ocean-blue-color;
        }
    }

    .form-control.is-invalid::after {
        content: "Invalid input";
        /* Tooltip text */
        position: absolute;
        top: 100%;
        /* Position the tooltip below the input */
        left: 0;
        padding: 0.5em;
        background-color: #dc3545;
        /* Tooltip background color */
        color: white;
        /* Tooltip text color */
        border-radius: 0.25rem;
        z-index: 1;
        /* Ensure tooltip appears above other elements */
        font-size: 0.875rem;
        /* Adjust font size as needed */
        white-space: nowrap;
        /* Prevent tooltip from wrapping */
        display: none;
        /* Initially hide the tooltip */
    }

    .form-control.is-invalid:focus::after {
        display: block;
        /* Show the tooltip when input is focused */
    }
}
.seoForm {
    margin-top: 3.8rem;
}

.seoFieldset {
    border: 1px solid $ocean-blue-color;
    border-radius: 2.6rem;
    padding-left: 2rem;
    padding-right: 2rem;

    legend {
        float: unset;
        width: fit-content;
        margin-bottom: 0;
        color: $ocean-blue-color;
        font-size: 1.4rem;

        @include screen-sm {
            font-size: 1.2rem;
        }

        .form-label {
            margin-bottom: 0;
        }
    }

    .form-control {
        padding: 0rem 0.75rem 1rem 0.75rem;
        border: none;
        font-size: $body-text-level-two;
        color: $black-color;

        @include screen-sm {
            font-size: $body-text-level-x-sm-two;
        }

        &::placeholder {
            color: $black-color;
        }

        &:hover {
            box-shadow: none;
        }

        &:focus {
            box-shadow: none;
        }
    }

}

.seoFormBtn {
    margin-top: 7.1rem;
}