.aheadermain {
    padding: 1.5rem $padding-xl;
    background-color: $white-color;
    border-bottom: 1px solid $white-smoke;

    @include screen-lg {
        padding: 1rem $padding-md;
    }

    @include screen-sm {
        padding: 1rem $padding-xs;
    }

    .navbar {
        padding: 0;

        .container-fluid {
            .apsettingBtnblk {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .AprofileBtn {
                    margin-right: 1rem;

                    .btn-primary {
                        background-color: unset;
                        color: $ocean-blue-color;
                        border: unset;
                        padding: unset;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .aloginbtn {
                            border: 1px solid #0e76a8;
                            padding: 0.3rem;
                            border-radius: 3.1rem;
                            display: flex;
                            align-items: center;

                            .loginBlk {
                                width: 3.2rem;
                                height: 3.2rem;
                                border-radius: 50%;

                                .plogo {
                                    border-radius: inherit;
                                }

                                // .img-fluid {
                                //     margin-right: 0.5rem;
                                //     width: 1.6rem;
                                // }
                            }
                        }
                    }

                    .dropdown-toggle {
                        text-align: start;

                        &::after {
                            content: unset;
                        }
                    }

                    .dropdown-menu {
                        margin-top: 1.5rem;
                        left: unset;
                        right: 0;
                        min-width: unset;
                        max-width: 30.9rem;

                        .mUlist {
                            font-size: $body-text-level-two;
                            color: $vampire-black;
                            border-bottom: 1px solid $white-smoke;
                            width: 100%;
                            padding: 0.75em 14.8rem 0.75rem 1.9rem;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            @include screen-sm {
                                font-size: $body-text-level-x-sm-two;
                            }

                            &:hover {
                                background-color: $white-smoke;
                            }

                            &:last-child {
                                border-bottom: unset;
                            }

                            .pm {
                                color: $ocean-blue-color;
                                font-size: $detail-header-level-one;
                                margin-right: 1.4rem;

                                @include screen-sm {
                                    font-size: $detail-header-level-sm-one;
                                }
                            }

                            .sf {
                                color: $ocean-blue-color;
                                font-size: $detail-header-level-one;
                                margin-right: 1.4rem;

                                @include screen-sm {
                                    font-size: $detail-header-level-sm-one;
                                }
                            }

                            .lo {
                                color: $ocean-blue-color;
                                font-size: $detail-header-level-one;
                                margin-right: 1.4rem;

                                @include screen-sm {
                                    font-size: $detail-header-level-sm-one;
                                }
                            }
                        }

                        .saveWorkBtn {
                            padding: 1.3rem 7.3rem;

                            border: 1px solid $ocean-blue-color;
                            border-radius: 2.4rem;
                            margin: 2.4rem auto;

                            &:hover {
                                background-color: $ocean-blue-color;
                                color: $white-color;
                            }
                        }
                    }
                }

                .ApsettingBtn {
                    @include screen-sm {
                        width: fit-content;
                        margin-left: 0rem;
                    }

                    .dropdown-toggle {
                        margin-right: 1rem;

                        &::after {
                            content: unset;
                        }
                    }

                    .btn-primary {
                        background-color: unset;
                        color: $ocean-blue-color;
                        padding: unset;
                        font-size: $detail-header-level-one;
                        border: unset;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }

                    .dropdown-menu {
                        margin-top: 2.5rem;
                        left: unset;
                        right: 0;
                        width: fit-content;

                        @include screen-md {
                            margin-top: 2.1rem;
                        }

                        .mUlist {
                            padding-top: 0.75rem;
                            padding-bottom: 0.75rem;
                            font-size: $body-text-level-two;
                            color: $vampire-black;
                            border-bottom: 1px solid $white-smoke;
                            width: 100%;

                            @include screen-lg {
                                font-size: $body-text-level-x-sm-two;
                            }

                            &:hover {
                                background-color: $white-smoke;
                            }

                            &:last-child {
                                border-bottom: unset;
                            }
                        }
                    }
                }

            }
        }
    }

    .logoBlk {
        width: 156px;
    }

    .navbar-collapse {
        flex-grow: unset;

        .aNav {
            .nav-item {
                .dropdown-menu[data-bs-popper] {
                    left: unset;
                    right: 0;
                }

                .mUlist {
                    font-size: $body-text-level-two;
                    color: $vampire-black;
                    border-bottom: 1px solid $white-smoke;
                    padding: 0.5rem 1.5rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    @include screen-sm {
                        font-size: $body-text-level-x-sm-two;
                    }

                    &:hover {
                        background-color: $white-smoke;
                    }

                    &:last-child {
                        border-bottom: unset;
                    }

                    .pm {
                        color: $ocean-blue-color;
                        font-size: $detail-header-level-one;
                        margin-right: 1.4rem;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }

                    .sf {
                        color: $ocean-blue-color;
                        font-size: $detail-header-level-one;
                        margin-right: 1.4rem;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }

                    .lo {
                        color: $ocean-blue-color;
                        font-size: $detail-header-level-one;
                        margin-right: 1.4rem;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }
                }
            }
        }
    }

    .navbar-nav {
        align-items: center;

        @include screen-lg {
            align-items: start;
        }

        .nav-link {
            font-size: $body-text-level-two;

            color: $vampire-black;
            display: unset;

            @include screen-lg {
                padding-left: 1rem;
            }

            @include screen-sm {
                font-size: $body-text-level-x-sm-two;
            }
        }

        .meDrpd {
            margin-left: 1.5rem;

            @include screen-lg {
                margin-left: 0rem;
                width: 100%;
            }

            @include screen-sm {
                width: 100%;
            }

            .dropdown-menu {
                margin-top: 2.3rem;
                width: fit-content;
                left: unset;
                right: 0;

                @include screen-sm {
                    width: 100%;
                    margin-top: 1.3rem;
                }

                .mUlist {
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    font-size: $body-text-level-two;
                    color: $vampire-black;
                    border-bottom: 1px solid $white-smoke;
                    width: 100%;

                    @include screen-lg {
                        font-size: $body-text-level-x-sm-two;
                    }

                    &:hover {
                        background-color: $white-smoke;
                    }

                    &:last-child {
                        border-bottom: unset;
                    }
                }
            }
        }

        .dropdown {
            @include screen-lg {
                margin-top: 0.5rem;
                margin-left: 0rem;
            }

            .dropdown-toggle {
                &::after {
                    border-top: unset;
                    border-right: unset;
                    border-bottom: unset;
                    border-left: unset;
                }

                .loginBlk {
                    width: 3.2rem;
                    height: 3.2rem;
                    border-radius: 50%;

                    .plogo {
                        border-radius: inherit;
                    }
                }

                .aloginbtn {
                    border: 1px solid $ocean-blue-color;
                    padding: 0.3rem;
                    border-radius: 3.1rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .loginbar {
                    margin-left: 0.7rem;
                    margin-right: 0.7rem;
                    font-size: 1.77rem;
                    color: $ocean-blue-color;
                }
            }
        }

        .ApsettingBtn {
            @include screen-sm {
                width: 100%;
                margin-left: 0rem;
                // margin-right: 1rem;
            }

            .dropdown-toggle {
                margin-left: 1rem;
            }

            .btn-primary {
                background-color: unset;
                color: $ocean-blue-color;
                padding: unset;
                font-size: $detail-header-level-one;
                border: unset;
                display: flex;
                justify-content: center;
                align-items: center;

                @include screen-sm {
                    font-size: $detail-header-level-sm-one;
                }
            }

            .dropdown-menu {
                margin-top: 2.5rem;
                left: unset;
                right: 0;
                width: fit-content;

                @include screen-sm {
                    width: 100%;
                }

                .mUlist {
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    font-size: $body-text-level-two;
                    color: $vampire-black;
                    border-bottom: 1px solid $white-smoke;
                    width: 100%;

                    @include screen-lg {
                        font-size: $body-text-level-x-sm-two;
                    }

                    &:hover {
                        background-color: $white-smoke;
                    }

                    &:last-child {
                        border-bottom: unset;
                    }
                }
            }
        }

        .AprofileBtn {
            margin-left: 1rem;

            .btn-primary {
                background-color: unset;
                color: $ocean-blue-color;
                border: unset;
                padding: unset;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .dropdown-menu {
                margin-top: 1.5rem;
                left: unset;
                right: 0;
                min-width: unset;
                max-width: 30.9rem;

                .mUlist {
                    font-size: $body-text-level-two;
                    color: $vampire-black;
                    border-bottom: 1px solid $white-smoke;
                    width: 100%;
                    padding: 0.75em 14.8rem 0.75rem 1.9rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    @include screen-sm {
                        font-size: $body-text-level-x-sm-two;
                    }

                    &:hover {
                        background-color: $white-smoke;
                    }

                    &:last-child {
                        border-bottom: unset;
                    }

                    .pm {
                        color: $ocean-blue-color;
                        font-size: $detail-header-level-one;
                        margin-right: 1.4rem;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }

                    .sf {
                        color: $ocean-blue-color;
                        font-size: $detail-header-level-one;
                        margin-right: 1.4rem;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }

                    .lo {
                        color: $ocean-blue-color;
                        font-size: $detail-header-level-one;
                        margin-right: 1.4rem;

                        @include screen-sm {
                            font-size: $detail-header-level-sm-one;
                        }
                    }
                }

                .saveWorkBtn {
                    padding: 1.3rem 7.3rem;

                    border: 1px solid $ocean-blue-color;
                    border-radius: 2.4rem;
                    margin: 2.4rem auto;

                    &:hover {
                        background-color: $ocean-blue-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}