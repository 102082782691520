.svsrp-main {
  .svsrp-section {
    &:nth-child(1) {
      padding: 3rem calc($padding-xl + 0.5rem);

      @include screen-lg {
        padding: 3rem $padding-md;
      }

      @include screen-md {}

      @include screen-sm {
        padding: 3rem $padding-xs;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      .svsrp-heading {
        @include fonts($header-level-two,
          4.3rem,
          $vampire-black,
          $OpenSans-Bold );

        @include screen-sm {
          @include fonts($header-level-x-sm, null, null, null);
        }

        .svsrp-heading-lbl {
          color: $granite-gray-color;
        }
      }

      .svsrp-location {
        @include fonts($sub-header-level-two,
          3.3rem,
          $granite-gray-color,
          null);

        @include screen-sm {
          @include fonts($sub-header-level-x-sm-two, null, null, null);
        }

        .location-i {
          color: $ocean-blue-color;
          cursor: pointer;
        }
      }

      .svsrp-wcv-Blk {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .svsrp-wcv-subBlk {
          display: flex;
          align-items: center;

          &:last-child {
            margin-left: 4rem;

            .mpLogo {
              margin-left: auto;
              margin-right: auto;
              margin-top: auto;
              margin-bottom: auto;
              width: 5.6rem;
              height: 5.6rem;

              .mpLogoImg {
                width: inherit;
                height: inherit;
                object-fit: cover;
                border-radius: 50%;
              }
            }
          }

          .svsrp-trophy {
            width: 5.9rem;
            height: 5.9rem;
            border-radius: 50%;
            background-color: #1e58f833;
            display: flex;
            justify-content: center;
            align-items: center;

            .trophy {
              font-size: 3.319rem;
              color: #1e58f8;
            }
          }

          .svsrp-details {
            margin-left: 1.5rem;

            .svsrp-deta-head {
              @include fonts($detail-header-level-one,
                2.7rem,
                $vampire-black,
                $OpenSans-SemiBold );

              @include screen-sm {
                @include fonts($detail-header-level-sm-one, null, null, null);
              }

              .svsrp-arw {
                color: #ff8000;
                margin-left: 0.8rem;
              }
            }

            .svsrp-deta-votes {
              @include fonts($body-text-level-two,
                2.7rem,
                $granite-gray-color,
                null);

              @include screen-sm {
                @include fonts($body-text-level-x-sm-two, null, null, null);
              }
            }
          }
        }
      }
    }

    &:nth-child(2) {
      background-color: $light-gray1;
      padding: 1.5rem calc($padding-xl + 0.5rem);

      @include screen-lg {
        padding: 1.5rem $padding-md;
      }

      @include screen-md {}

      @include screen-sm {
        padding: 1.5rem $padding-xs;
      }

      .svsrp-voting-heading {
        @include fonts($body-text-level-two,
          4.3rem,
          $granite-gray-color,
          $OpenSans-SemiBold );

        @include screen-sm {
          @include fonts($body-text-level-x-sm-two, null, null, null);
        }
      }

      .svsrp-voting-blk {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        gap: 20px;
        width: 100%;

        @include screen-mdx {
          grid-template-columns: auto auto auto;
        }

        @include screen-sm {
          grid-template-columns: auto auto;
        }

        .right-border {
          border-right: 1px solid $whisper;

          &:last-child {
            border-right: none;
          }

          @include screen-mdx {
            &:nth-child(3) {
              border-right: none;
            }
          }

          @include screen-sm {
            &:nth-child(2) {
              border-right: none;
            }

            &:nth-child(3) {
              border-right: 1px solid $whisper;
            }

            &:nth-child(4) {
              border-right: none;
            }
          }
        }

        .sqr-box0 {
          @include square-box($caribbean-green);
          background-color: #ececec;
        }

        .sqr-box1 {
          @include square-box($ocean-blue-color);
          background-color: #ececec;
        }

        .sqr-box2 {
          @include square-box($bright-turquoise);
          background-color: #ececec;
        }

        .sqr-box3 {
          @include square-box($bittersweet);
          background-color: #ececec;
        }

        .sqr-box4 {
          @include square-box($electric-purple);
          background-color: #ececec;
        }



        .registered-e-div {
          .provincial-vote {
            font-size: $detail-header-level-one;
            font-family: $OpenSans-Bold;
            color: $vampire-black;

            @include screen-sm {
              font-size: $body-text-level-x-sm-two;
            }
          }

          .registered-electors {
            font-size: $body-text-level-two;
            color: $shuttle-gray;

            @include screen-sm {
              font-size: $body-text-level-x-sm-two;
            }
          }
        }
      }
    }

    &:last-child {
      padding: 1rem 4rem 2rem 4rem;
      text-align: right;
      @include fonts($detail-header-level-one, 2.7rem, $shuttle-gray, null);

      @include screen-sm {
        @include fonts($detail-header-level-sm-one, null, null, null);
      }

      .solidBlueBtn {
        padding: 1.5rem 3.6rem;
        margin-left: 1.5rem;
      }
    }
  }

  .svsrp-section-chart {
    padding: 0rem calc($padding-xl + 0.5rem);
    margin-bottom: 1.5rem;


    @include screen-lg {
      padding: 1.5rem $padding-md;
    }

    @include screen-md {}

    @include screen-sm {
      padding: 1.5rem $padding-xs;
    }

    .ppe2-chart-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;


      .ppe2-chartsubBlk {
        border: 1px solid #efefef;
        border-radius: 1.5rem;

        &:first-child {
          width: 50%;
          margin-right: 1.5rem;
        }

        &:last-child {
          width: 50%;
          margin-left: 1.5rem;
        }
      }

      .highChart-img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.5rem;
      }
    }
  }

  .svsrp-section-table {
    padding: 0rem 4rem 0rem 4rem;
    margin-bottom: 1.5rem;

    .table-responsive {
      border: 1px solid $ocean-blue-color;
      border-radius: 1rem;
      width: 100%;
      overflow-x: auto;

      .manageTable {
        margin-top: 0rem;
        vertical-align: middle;

        &:not(caption) {
          * {
            * {
              @include screen-sm {}
            }
          }
        }

        thead {
          background-color: $diamond-blue !important;
          color: $vampire-black !important;
        }

        .mpLogo {
          margin-left: auto;
          margin-right: auto;
          margin-top: auto;
          margin-bottom: auto;
          width: 5.6rem;
          height: 5.6rem;

          .mpLogoImg {
            width: inherit;
            height: inherit;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .activeSwitch {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 4rem;

    .form-check-input {
      max-width: 4.9rem;
      width: 100%;
      min-height: 2.4rem;

      &:focus {
        box-shadow: unset !important;
        border-color: #0e76a8;
      }

      &:checked {
        background-color: #0e76a8;
        border-color: #0e76a8;
      }
    }

    .form-check-label {
      margin-left: 1.2rem;
      @include fonts($body-text-level-two, 2.7rem, $vampire-black, null);

      @include screen-sm {
        @include fonts($body-text-level-x-sm-two, null, null, null);
      }
    }
  }
}