.textForm {
  .textformlabel {
    font-size: $detail-header-level-one;
    color: $vampire-black;
  }

  .textformtext {
    font-size: $body-text-level-one;
    color: $vampire-black;
    padding: 1.4rem 1rem;
    border: 1px solid $granite-gray-color;
    border-radius: 5px;
    box-shadow: none;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $dark-gray;
    }
  }
}
