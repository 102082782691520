.postBlock-five {
  padding: 2rem 3rem;
  background-color: $white-color;
  border: 1px solid $granite-gray-color;
  border-radius: 5px;

  .PostBlockChild {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $white-smoke;
    padding-bottom: 2rem;

    .PostBlockTitle {
      font-family: $OpenSans-Bold;
      font-size: $header-level-two;

      @include screen-sm {
        font-size: $header-level-x-sm;
      }
    }
  }
  .col-sub-profile {
    .activeSwitch {
      .form-check-input {
        max-width: 4.9rem;
        width: 100%;
        min-height: 2.4rem;

        &:focus {
          box-shadow: unset !important;
          border-color: #0e76a8;
        }

        &:checked {
          background-color: #0e76a8;
          border-color: #0e76a8;
        }
      }

      .form-check-label {
        margin-left: 1.2rem;
        @include fonts($body-text-level-two, 2.7rem, $vampire-black, null);

        @include screen-sm {
          @include fonts($body-text-level-x-sm-two, null, null, null);
        }
      }
    }
  }
}
