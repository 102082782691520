.uploadimageRow {
  .textformlabel {
    font-size: $detail-header-level-one;
    color: $vampire-black;
  }
}

.upload-button {
  background-color: $black-color;
  color: white;
  border: none;
  padding: 12px 35px;
  font-size: $body-text-level-one;
  cursor: pointer;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
}

.upload-button.dragging {
  color: red;
}

.upload__image-wrapper {
  .image-list {
    display: flex;
    flex-wrap: wrap;
  }

  .image-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    .image-container {
      width: 100px;
      height: 100px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $white-smoke;
      border-radius: 12px;
      background: $white-smoke;

      img.uploaded-image {
        width: 80%;
        height: 80%;
        object-fit: cover;
      }
    }

    .remove-icon {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      background-color: rgba(128, 128, 128, 0.9);
      border-radius: 50%;
      padding: 3px;
      color: $white-color;
    }

    &:hover .remove-icon {
      display: block;
    }
  }

  .error-messages {
    color: red;
    margin-top: 10px;
  }
}
