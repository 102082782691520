.asideone {
    background-color: $diamond-blue;
    position: sticky;
    top: 11rem;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .nav {
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;

        .nav-item {
            .nav-link {
                @include fonts($body-text-level-two,
                    2.2rem,
                    $vampire-black,
                    $OpenSans-SemiBold);

                @include screen-sm {
                    @include fonts($body-text-level-x-sm-two, null, null, null);
                }
                cursor: pointer;
                display: flex;
                align-items: baseline;

                padding: .9rem 1rem;

                .arw-history {
                    transform: rotateZ(270deg);
                }
            }
        }
    }
}