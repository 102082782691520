.votersubmited {
    .modal-dialog {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 90vh;
      overflow-y: hidden;
  
      .modal-content {
        border-radius: 2.7rem;
        padding: 3rem;
        max-width: 45.9rem;
        margin: 0 auto;
  
        .modal-header {
          border-bottom: unset;
  
          .checkblk {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1rem solid rgba(41, 168, 14, 0.29);
            border-radius: 50%;
            margin: 0 auto;
  
            .modalcheck {
              width: 8.7rem;
              height: 8.7rem;
              color: #ffffff;
              background-color: $lush-green;
              border-radius: 50%;
            }
          }
        }
  
        .modal-body {
          .ays {
            text-align: center;
  
            @include fonts(
              $sub-header-level-two,
              3.3rem,
              $black-color,
              null
            );
  
            @include screen-sm {
              @include fonts($sub-header-level-x-sm-two, null, null, null);
            }
  
            .high {
              font-family: $OpenSans-SemiBold;
            }
          }
        }
  
        .modal-footer {
          margin-top: 5rem;
          border-top: unset;
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .transparentbtn {
            margin-top: 5rem;
  
            @include fonts(
              $detail-header-level-one,
              3.3rem,
              $ocean-blue-color,
              null
            );
  
            @include screen-sm {
              @include fonts($detail-header-level-sm-one, null, null, null);
            }
  
            &:hover {
              background-color: unset;
            }
          }
        }
      }
    }
  }
  