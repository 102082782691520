.searchbtnform {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;

    @include screen-sm {
        width: 100%;
        margin-top: 1.5rem;
    }
}