.tabs {
    .nav-tabs {
        background-color: $diamond-blue;
        border-radius: 1rem;

        .nav-link {
            padding: 2rem;
            position: relative;
            @include fonts($detail-header-level-two, 3rem, $vampire-black, null);

            @include screen-sm {
                @include fonts($detail-header-level-sm-two, null, null, null);
            }

            .nav-tb-blk {
                .nav-tb-img {
                    margin-right: 1rem;
                }
            }

            &.active {
                background-color: unset;
                border: unset;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: calc(100% - 4rem);
                    height: 2.5px;
                    border-radius: 1.25px;
                    background-color: $ocean-blue-color;
                }
            }
        }
    }

    .tab-content {
        margin-top: 4rem;

        .tabs-content {
            .contentTabs-total {

                position: relative;

                .contentTabs-total-head {
                    @include fonts($sub-header-level-two,
                        3.3rem,
                        $vampire-black,
                        $OpenSans-Bold );

                    @include screen-sm {
                        @include fonts($sub-header-level-x-sm-two, null, null, null);
                    }
                }

                .provincial-details {
                    display: flex;

                    .sqr-box0 {
                        @include square-box($caribbean-green);
                        background-color: #ececec;
                    }

                    .sqr-box1 {
                        @include square-box($ocean-blue-color);
                        background-color: #ececec;
                    }

                    .sqr-box2 {
                        @include square-box($bright-turquoise);
                        background-color: #ececec;
                    }

                    .sqr-box3 {
                        @include square-box($bittersweet);
                        background-color: #ececec;
                    }

                    .sqr-box4 {
                        @include square-box($electric-purple);
                        background-color: #ececec;
                    }

                    .sqr-box5 {
                        @include square-box($bittersweet);
                        background-color: #ececec;
                    }

                    .sqr-box6 {
                        @include square-box($electric-purple);
                        background-color: #ececec;
                    }

                    .right-border {
                        padding: 0 3rem;
                        border-right: 1px solid $whisper;

                        &:first-child {
                            padding-left: unset;
                        }

                        &:last-child {
                            border-right: unset;
                        }

                        .registered-e-div {
                            .provincial-vote {
                                @include fonts($detail-header-level-one,
                                    2.7rem,
                                    $vampire-black,
                                    $OpenSans-SemiBold );

                                @include screen-sm {
                                    @include fonts($detail-header-level-sm-one, null, null, null);
                                }
                            }

                            .registered-electors {
                                @include fonts($body-text-level-two,
                                    2.2rem,
                                    $granite-gray-color,
                                    null);

                                @include screen-sm {
                                    @include fonts($body-text-level-x-sm-two, null, null, null);
                                }
                            }
                        }
                    }
                }

                .btnblk {
                    position: sticky;
                    top: 15rem;
                    z-index: 5;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-left: auto;
                    background-color: white;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                    margin-bottom: 1.5rem;

                    .blueborderbtn {
                        padding: 1.8rem 4rem;
                        border-radius: 3.2rem;
                    }

                    .solidBlueBtn {
                        padding: 1.8rem 4rem;
                        border-radius: 3.2rem;

                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}