.whiteBorderBtn {
  font-size: $body-text-level-two;
  font-family: $OpenSans-SemiBold;
  letter-spacing: 1px;
  background-color: $white-color;
  border: 1px solid $Carrot-Orange;
  color: $Carrot-Orange;
  line-height: 2.2rem;
  border-radius: 2.6rem;
  transition: background-color 0.3s ease;
  @include screen-sm {
    font-size: $body-text-level-x-sm-two;
  }
  &:hover {
    background-color: $Carrot-Orange;
    color: $white-color;
    border: 1px solid $Old-Lace;
  }
  &:active {
    border: 1px solid $white-color !important;
    background-color: $Carrot-Orange !important;
    color: $white-color !important;
  }
}
