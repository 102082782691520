.simulatorPage {
    position: relative;

    .provincial-details {
        position: sticky;
        top: 9rem;
        z-index: 5;
        background-color: $white-color;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;


    }

    .table-responsive {
        .si-table {
            background-color: $snow-color;
            border-color: $white-smoke;

            &> :not(caption) {
                &>* {
                    &>* {
                        padding: 2.5rem 2.5rem;
                        vertical-align: middle;
                        @include fonts($detail-header-level-two,
                            2.7rem,
                            $vampire-black,
                            null);

                        @include screen-sm {
                            @include fonts($detail-header-level-sm-two, null, null, null);
                        }

                        border-right: 1px solid $white-smoke;

                        &:nth-last-child(2) {
                            vertical-align: bottom;

                            .rangeSlider {
                                position: relative;
                                width: 100%;

                                .slider {
                                    width: 100%;

                                    border-radius: 0.3rem;

                                    .track-0 {
                                        background-color: $ocean-blue-color;
                                        height: 0.3rem;
                                    }

                                    .track-1 {
                                        background-color: $white-smoke;
                                        height: 0.3rem;
                                    }
                                }

                                .thumb {
                                    height: 1.1rem;
                                    width: 1.1rem;
                                    background-color: #0e76a8;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                    font-weight: bold;
                                    position: absolute;
                                    top: -0.3rem;

                                    &:active {
                                        box-shadow: none;
                                    }
                                }

                                .label {
                                    margin-left: auto;
                                    margin-right: auto;
                                    position: absolute;
                                    max-width: 10rem;
                                    top: -35px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    background: #0e76a8;
                                    color: white;
                                    padding: 2px 8px;
                                    border-radius: 4px;
                                    white-space: nowrap;
                                    border: none;

                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            border-right: unset;

                            .label {
                                max-width: 11rem;
                                background: #0e76a8;
                                // color: white;
                                border: none;
                                @include fonts($detail-header-level-two, 2.7rem, white, null);

                                @include screen-sm {
                                    @include fonts($detail-header-level-sm-two, null, null, null);
                                }

                                &:active {
                                    outline: none;
                                }

                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }
                        }
                    }
                }
            }

            thead {
                tr {
                    th {
                        @include fonts($detail-header-level-one,
                            2.7rem,
                            $vampire-black,
                            null);

                        @include screen-sm {
                            @include fonts($detail-header-level-sm-one, null, null, null);
                        }

                        font-weight: normal;
                        border-right: 1px solid #efefef;

                        &:last-child {
                            border-right: unset;
                        }
                    }
                }
            }
        }

        .table> :not(caption)>*>* {
            background-color: unset;

            .mcLogo {
                margin-left: auto;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                width: 5.6rem;
                height: 5.6rem;
                border: 1px solid transparent;

                .img-fluid {
                    display: block;
                    border-radius: 50%;
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                }
            }

            .mcLogo1 {
                margin-right: 1.5rem;
                width: 5.6rem;
                height: 5.6rem;
                border: 1px solid transparent;

                .img-fluid {
                    display: block;
                    border-radius: 50%;
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                }
            }
        }

        .si-table1 {
            // background-color: $snow-color;
            border-color: $white-smoke;

            &> :not(caption) {
                &>* {
                    &>* {
                        padding: 2.5rem 2.5rem;
                        vertical-align: middle;
                        @include fonts($detail-header-level-two,
                            2.7rem,
                            $vampire-black,
                            null);

                        @include screen-sm {
                            @include fonts($detail-header-level-sm-two, null, null, null);
                        }

                        // border-right: 1px solid $white-smoke;

                        &:nth-last-child(2) {
                            .rangeSlider {
                                position: relative;
                                width: 100%;

                                .slider {
                                    width: 100%;

                                    border-radius: 0.3rem;

                                    .track-0 {
                                        background-color: $ocean-blue-color;
                                        height: 0.3rem;
                                    }

                                    .track-1 {
                                        background-color: $white-smoke;
                                        height: 0.3rem;
                                    }
                                }

                                .thumb {
                                    height: 1.1rem;
                                    width: 1.1rem;
                                    background-color: #0e76a8;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                    font-weight: bold;
                                    position: absolute;
                                    top: -0.3rem;

                                    &:active {
                                        box-shadow: none;
                                    }
                                }

                                .label {
                                    margin-left: auto;
                                    margin-right: auto;
                                    position: absolute;
                                    max-width: 10rem;
                                    top: -35px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    background: #0e76a8;
                                    color: white;
                                    padding: 2px 8px;
                                    border-radius: 4px;
                                    white-space: nowrap;
                                    border: none;

                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            border-right: unset;

                            .label {
                                max-width: 11rem;
                                background: #0e76a8;
                                // color: white;
                                border: none;
                                @include fonts($detail-header-level-two, 2.7rem, white, null);

                                @include screen-sm {
                                    @include fonts($detail-header-level-sm-two, null, null, null);
                                }

                                &:active {
                                    outline: none;
                                }

                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }

                            .transparentbtn {

                                // background-color: unset;
                                svg {
                                    font-size: 2.4rem;
                                }

                                &:hover,
                                &:active {
                                    background-color: transparent !important;
                                    color: $vampire-black !important;
                                }
                            }
                        }
                    }
                }
            }

            thead {
                tr {
                    th {
                        @include fonts($detail-header-level-one,
                            2.7rem,
                            $vampire-black,
                            null);

                        @include screen-sm {
                            @include fonts($detail-header-level-sm-one, null, null, null);
                        }

                        text-align: center;
                        font-weight: normal;
                        border-top: 1px solid #efefef;
                        width: 25%;
                    }
                }
            }

            .si-table1-party-row {
                background-color: #fcfcfc;

                .rangeSlider {
                    position: relative;
                    width: 100%;

                    .slider {
                        width: 100%;

                        border-radius: 0.3rem;

                        .track-0 {
                            background-color: $ocean-blue-color;
                            height: 0.3rem;
                        }

                        .track-1 {
                            background-color: $white-smoke;
                            height: 0.3rem;
                        }
                    }

                    .thumb {
                        height: 1.1rem;
                        width: 1.1rem;
                        background-color: #0e76a8;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        font-weight: bold;
                        position: absolute;
                        top: -0.3rem;

                        &:active {
                            box-shadow: none;
                        }
                    }

                    .label {
                        margin-left: auto;
                        margin-right: auto;
                        position: absolute;
                        max-width: 10rem;
                        top: -35px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #0e76a8;
                        color: white;
                        padding: 2px 8px;
                        border-radius: 4px;
                        white-space: nowrap;
                        border: none;

                        &:focus {
                            outline: none;
                        }
                    }
                }

                .label1 {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 10rem;
                    background: #0e76a8;
                    color: white;
                    padding: 2px 8px;
                    border-radius: 4px;
                    white-space: nowrap;
                    border: none;
                    font-size: 2.2rem;

                    &:focus {
                        outline: none !important;
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .voteCalculations {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include screen-md {
            width: 100%;
            display: grid;
            gap: 20px;
            grid-template-columns: auto;
        }

        .vote-calc-p {
            font-size: $detail-header-level-one;
            color: $vampire-black;
            margin-right: 1.5rem;

            @include screen-mdx {
                margin-right: 0.5rem;
            }
        }

        .right-border {
            border-right: 1px solid $whisper;
            margin-right: 1.5rem;

            &:last-child {
                border-right: none;
            }

            @include screen-md {
                border-right: none;
            }
        }

        .sqr-box0 {
            @include square-box($caribbean-green);
            background-color: #ececec;
        }

        .sqr-box2 {
            @include square-box($bright-turquoise);
        }

        .sqr-box3 {
            @include square-box($bittersweet);
        }

        .sqr-box4 {
            @include square-box($electric-purple);
        }

        .registered-e-div {
            margin-right: 1.5rem;
            display: flex;
            align-items: center;

            @include screen-mdx {
                margin-right: 0.5rem;
            }

            .provincial-vote {
                font-size: $detail-header-level-one;
                font-family: $OpenSans-Bold;
                color: $vampire-black;
            }

            .registered-electors {
                font-size: $body-text-level-two;
                color: $shuttle-gray;
                margin-left: 1.5rem;
            }
        }
    }

    .acc-header-blk {
        width: 100%;
    }
}