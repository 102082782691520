.search-input {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  border-radius: 29px;
  background-color: $white-smoke;
  width: 380px;
  @include screen-sm {
    width: 100%;
  }

  .search-icon {
    margin-left: 0.5rem;
    margin-right: 1rem;
    color: $granite-gray-color;
    cursor: pointer;
    font-size: 2.5rem;
  }

  input {
    flex: 1;
    border: none;
    outline: none;
    background-color: $white-smoke;
    font-size: $body-text-level-two;
    color: $granite-gray-color;
  }
}
